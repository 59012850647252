export const supportedCoins = [
  {
    currency_id: 'sygna:0x8000003c.0x0d2BB9D68dD4451A09ec94C05E20Bd395022bd8e',
    currency_name: 'CRYPTOBUCKS',
    currency_symbol: 'CBUCKS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd780ae2bf04cd96e577d3d014762f831d97129d0',
    currency_name: 'Envion',
    currency_symbol: 'EVN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0fE156436F203B114C6c562Cb1a2A81aa2801090',
    currency_name: 'SKINCHAIN',
    currency_symbol: 'SKC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbfc1502ebc37475b940ced8f036b91018a73c8f6',
    currency_name: 'Bidesk',
    currency_symbol: 'BDK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.0x8069080a922834460c3a092fb2c1510224dc066b',
    currency_name: 'SpaceChain',
    currency_symbol: 'SPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2e65e12b5f0fd1d58738c6f38da7d57f5f183d1c',
    currency_name: 'Tepleton',
    currency_symbol: 'TEP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f0f1be08591ab7d990faf910b38ed5d60e4d5bf',
    currency_name: 'Maincoin',
    currency_symbol: 'MNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5dc60c4d5e75d22588fa17ffeb90a63e535efce0',
    currency_name: 'dKargo',
    currency_symbol: 'DKA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc2b58812c24020ea924c3d7c241c441605f12e75',
    currency_name: 'Entherfound',
    currency_symbol: 'ETF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1864ce27e9f7517047933caae530674e8c70b8a7',
    currency_name: 'PIBBLE',
    currency_symbol: 'PIB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8aa688AB789d1848d131C65D98CEAA8875D97eF1',
    currency_name: 'MultiVAC',
    currency_symbol: 'MTV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BKRW-AB7',
    currency_name: 'Binance KRW',
    currency_symbol: 'BKRW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000091',
    currency_name: 'Bitcoin Cash',
    currency_symbol: 'BCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd7cddd45629934c2f6ed3b63217bd8085d7c14a8',
    currency_name: 'Animation Vision Cash',
    currency_symbol: 'AVH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x400b1d8a7dd8c471026b2c8cbe1062b27d120538',
    currency_name: 'Limestone Network',
    currency_symbol: 'LIMEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f195617fa8fbad9540c5d113a99a0a0172aaedc',
    currency_name: 'Niobium Coin',
    currency_symbol: 'NBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaa19961b6b858d9f18a115f25aa1d98abc1fdba8',
    currency_name: 'LocalCoinSwap',
    currency_symbol: 'LCS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3506424f91fd33084466f402d5d97f05f8e3b4af',
    currency_name: 'Chiliz',
    currency_symbol: 'CHZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd938137e6d96c72e4a6085412ada2dad78ff89c4',
    currency_name: 'Abulaba',
    currency_symbol: 'AAA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000080',
    currency_name: 'Monero',
    currency_symbol: 'XMR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xea097a2b1db00627b2fa17460ad260c016016977',
    currency_name: 'Upfiring',
    currency_symbol: 'UFR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.c9c0fc5a2b66a29d6b14601e752e6e1a445e088d',
    currency_name: 'nOS',
    currency_symbol: 'NOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x525794473f7ab5715c81d06d10f52d11cc052804',
    currency_name: '12Ships',
    currency_symbol: 'TSHP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x88a3e4f35d64aad41a6d4030ac9afe4356cb84fa',
    currency_name: 'Presearch',
    currency_symbol: 'PRE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000002',
    currency_name: 'Litecoin',
    currency_symbol: 'LTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x193408ca0576b73156ed42a2ea7d6fd3f6507162',
    currency_name: 'Infinity Esaham',
    currency_symbol: 'INFS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6781a0f84c7e9e846dcb84a9a5bd49333067b104',
    currency_name: 'Zap',
    currency_symbol: 'ZAP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdc5864ede28bd4405aa04d93e05a0531797d9d59',
    currency_name: 'Falcon Project',
    currency_symbol: 'FNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1k9fv2hz0w3l9v9z4g9samg3gtc7nc2xgyqw5u0',
    currency_name: 'Honest',
    currency_symbol: 'HNST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x431ad2ff6a9c365805ebad47ee021148d6f7dbe0',
    currency_name: 'dForce Token',
    currency_symbol: 'DF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0142c3b2fc51819b5af5dfc4aa52df9722790851',
    currency_name: 'PAYCENT',
    currency_symbol: 'PYN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x843131b15f2ec5bea850ac5164d2e4a3749ad87f',
    currency_name: 'WowSecret',
    currency_symbol: 'WOW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x78c292d1445e6b9558bf42e8bc369271ded062ea',
    currency_name: 'CyberMusic',
    currency_symbol: 'CYMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x83eea00d838f92dec4d1475697b9f4d3537b56e3',
    currency_name: 'Voise',
    currency_symbol: 'VOISE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe96f2c381e267a96c29bbb8ab05ab7d3527b45ab',
    currency_name: 'SatoExchange Token',
    currency_symbol: 'SATX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf39f19565b8d937ec30f1db5bd42f558d1e312a6',
    currency_name: 'Kappi Network',
    currency_symbol: 'KAPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000ac',
    currency_name: 'HTMLCOIN',
    currency_symbol: 'HTML',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332.0x9b68bfae21df5a510931a262cecf63f41338f264',
    currency_name: 'DecentBet',
    currency_symbol: 'DBET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbf8fb919a8bbf28e590852aef2d284494ebc0657',
    currency_name: 'ABCC Token',
    currency_symbol: 'AT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08130635368AA28b217a4dfb68E1bF8dC525621C',
    currency_name: 'AfroDex',
    currency_symbol: 'AfroX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x66fd97a78d8854fec445cd1c80a07896b0b4851f',
    currency_name: 'LunchMoney',
    currency_symbol: 'LMY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000020a',
    currency_name: 'StakeCubeCoin',
    currency_symbol: 'SCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4f22310c27ef39feaa4a756027896dc382f0b5e2',
    currency_name: 'SPIN Protocol',
    currency_symbol: 'SPIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd6bd97a26232ba02172ff86b055d5d7be789335b',
    currency_name: 'Ormeus Cash',
    currency_symbol: 'OMC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfdf574766ba1a96a553e175aeffa85ad78063f0b',
    currency_name: 'Vanta Network',
    currency_symbol: 'VANTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x365542df3c8c9d096c5f0de24a0d8cf33c19c8fd',
    currency_name: 'Hyper Speed Network',
    currency_symbol: 'HSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000067',
    currency_name: 'Zayedcoin',
    currency_symbol: 'ZYD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2C5dcd12141c56FBEa08e95f54f12c8B22d492Eb',
    currency_name: 'USDCoin',
    currency_symbol: 'USC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcfac2916ec118a0252a7766c513ee7c71b384b5e',
    currency_name: 'HoryouToken',
    currency_symbol: 'HYT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.78fd589f7894bf9642b4a573ec0e6957dfd84c48',
    currency_name: 'Bridge Protocol',
    currency_symbol: 'BRDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x94eea9a484f0bae03d19623cfe389e2cba56b72f',
    currency_name: 'Enzo',
    currency_symbol: 'NZO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001f5',
    currency_name: 'Solana',
    currency_symbol: 'SOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a2277c83930b7a64c3e3d5544eaa8c4f946b1b7',
    currency_name: 'e-Chat',
    currency_symbol: 'ECHT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x43afc9058a3DeBF37eaDf99138e449cE8a480A8a',
    currency_name: 'SafePost',
    currency_symbol: 'STAMP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4dc3643dbc642b72c158e7f3d2ff232df61cb6ce',
    currency_name: 'Ambrosus',
    currency_symbol: 'AMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x19c9872640ec38c2cf36c0f04d1365ef067869b3',
    currency_name: 'Oath Protocol',
    currency_symbol: 'OATH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x247551f2eb3362e222c742e9c788b8957d9bc87e',
    currency_name: 'GNY',
    currency_symbol: 'GNY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.fe59cbc1704e89a698571413a81f0de9d8f00c69',
    currency_name: 'Ink',
    currency_symbol: 'INK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000179',
    currency_name: 'ZENZO',
    currency_symbol: 'ZNZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0aef06dcccc531e581f0440059e6ffcc206039ee',
    currency_name: 'Intelligent Trading Foundation',
    currency_symbol: 'ITT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0d438f3b5175bebc262bf23753c1e53d03432bde',
    currency_name: 'Wrapped NXM',
    currency_symbol: 'WNXM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000231',
    currency_name: 'Nexty',
    currency_symbol: 'NTY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a7a8bd9106f2b8d977e08582dc7d24c723ab0db',
    currency_name: 'AppCoins',
    currency_symbol: 'APPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1e3a2446c729d34373b87fd2c9cbb39a93198658',
    currency_name: 'DeFi Nation Signals DAO',
    currency_symbol: 'DSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3c4a3ffd813a107febd57b2f01bc344264d90fde',
    currency_name: 'EnergiToken',
    currency_symbol: 'ETK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007d0',
    currency_name: 'GINcoin',
    currency_symbol: 'GIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff56cc6b1e6ded347aa0b7676c85ab0b3d08b0fa',
    currency_name: 'Orbs',
    currency_symbol: 'ORBS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x12759512d326303b45f1cec8f7b6fd96f387778e',
    currency_name: 'TrakInvest',
    currency_symbol: 'TRAK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba50933c268f567bdc86e1ac131be072c6b0b71a',
    currency_name: 'ARPA Chain',
    currency_symbol: 'ARPA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaf30d2a7e90d7dc361c8c4585e9bb7d2f6f15bc7',
    currency_name: 'FirstBlood',
    currency_symbol: '1ST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x749f35ff65932e68267dd82f6cd85eea735d700e',
    currency_name: 'CYCLEAN',
    currency_symbol: 'CCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000400',
    currency_name: 'Ontology',
    currency_symbol: 'ONT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000011',
    currency_name: 'Groestlcoin',
    currency_symbol: 'GRS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000173',
    currency_name: 'MediBloc',
    currency_symbol: 'MED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa9d2927d3a04309e008b6af6e2e282ae2952e7fd',
    currency_name: 'Zipper',
    currency_symbol: 'ZIP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa249de6948022783765fee4850d7b85e43118fcc',
    currency_name: 'Jarvis+',
    currency_symbol: 'JAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9d5686eadea7327f5a0c4820dca90457a0e88763',
    currency_name: 'ALLY',
    currency_symbol: 'ALY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x56e0b2c7694e6e10391e870774daa45cf6583486',
    currency_name: 'DUO Network Token',
    currency_symbol: 'DUO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08d32b0da63e2c3bcf8019c9c5d849d7a9d791e6',
    currency_name: 'Dentacoin',
    currency_symbol: 'DCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x151202c9c18e495656f372281f493eb7698961d5',
    currency_name: 'Debitum',
    currency_symbol: 'DEB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x24b80fba4b2ecaf0c4df522354408f0a59a775c8',
    currency_name: 'AdsByWiFi',
    currency_symbol: 'WADS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1fcdce58959f536621d76f5b7ffb955baa5a672f',
    currency_name: 'The Force Protocol',
    currency_symbol: 'FOR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd4c435f5b09f855c3317c8524cb1f586e42795fa',
    currency_name: 'Cindicator',
    currency_symbol: 'CND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4bffc9b4d4dcf730820a2edcad48ff5d7e0ae807',
    currency_name: 'Influence Chain',
    currency_symbol: 'INC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x47b28f365bf4cb38db4b6356864bde7bc4b35129',
    currency_name: 'FNB Protocol',
    currency_symbol: 'FNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0e69d0a2bbb30abcb7e5cfea0e4fde19c00a8d47',
    currency_name: 'IOV BlockChain',
    currency_symbol: 'IOV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc9859fccc876e6b4b3c749c5d29ea04f48acb74f',
    currency_name: 'INO COIN',
    currency_symbol: 'INO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x17e6616c45d267bc20a9892b58a01621c592b72d',
    currency_name: 'Ethereum Message Search',
    currency_symbol: 'EMS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000e6',
    currency_name: 'BitCash',
    currency_symbol: 'BITC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaaaf91d9b90df800df4f55c205fd6989c977e73a',
    currency_name: 'Monolith',
    currency_symbol: 'TKN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x41ab1b6fcbb2fa9dced81acbdec13ea6315f2bf2',
    currency_name: 'XinFin Network',
    currency_symbol: 'XDCE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007e1',
    currency_name: 'Kin',
    currency_symbol: 'KIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xea26c4ac16d4a5a106820bc8aee85fd0b7b2b664',
    currency_name: 'QuarkChain',
    currency_symbol: 'QKC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000020c',
    currency_name: 'AmsterdamCoin',
    currency_symbol: 'AMS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000077f',
    currency_name: 'Vipstar Coin',
    currency_symbol: 'VIPS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0e8d6b471e332f140e7d9dbb99e5e3822f728da6',
    currency_name: 'Abyss Token',
    currency_symbol: 'ABYSS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008ff.LUCY',
    currency_name: 'LUCY',
    currency_symbol: 'LUCY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x009e864923b49263c7f10d19b7f8ab7a9a5aad33',
    currency_name: 'FortKnoxster',
    currency_symbol: 'FKX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9a794dc1939f1d78fa48613b89b8f9d0a20da00e',
    currency_name: 'Arbidex',
    currency_symbol: 'ABX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x054c64741dbafdc19784505494029823d89c3b13',
    currency_name: 'ETERNAL TOKEN',
    currency_symbol: 'XET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe2f2a5c287993345a840db3b0845fbc70f5935a5',
    currency_name: 'mStable USD',
    currency_symbol: 'MUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8ab7404063ec4dbcfd4598215992dc3f8ec853d7',
    currency_name: 'Akropolis ',
    currency_symbol: 'AKRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.8k7zgWXJYUA5RfpSZJ75V5uq6mfjJVjWqYZbT6LkehEE',
    currency_name: 'Crex Token',
    currency_symbol: 'CREX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x56D1aE30c97288DA4B58BC39F026091778e4E316',
    currency_name: 'Digital Asset Guarantee Token',
    currency_symbol: 'DAGT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdd6c68bb32462e01705011a4e2ad1a60740f217f',
    currency_name: 'Hubii Network',
    currency_symbol: 'HBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf5dce57282a584d2746faf1593d3121fcac444dc',
    currency_name: 'Compound SAI',
    currency_symbol: 'CSAI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc2058F5D9736E8df8Ba03ca3582B7CD6aC613658',
    currency_name: 'ESAX Token',
    currency_symbol: 'ESAX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4d8fc1453a0f359e99c9675954e656d80d996fbf',
    currency_name: 'Bee Token',
    currency_symbol: 'BEE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf4faea455575354d2699bc209b0a65ca99f69982',
    currency_name: 'No BS Crypto',
    currency_symbol: 'NOBS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x89c6c856a6db3e46107163d0cda7a7ff211bd655',
    currency_name: 'Darico Ecosystem Coin',
    currency_symbol: 'DEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x14409b0fc5c7f87b5dad20754fe22d29a3de8217',
    currency_name: 'PYRO Network',
    currency_symbol: 'PYRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9d9223436ddd466fc247e9dbbd20207e640fef58',
    currency_name: 'Olive',
    currency_symbol: 'OLE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x60571e95e12c78cba5223042692908f0649435a5',
    currency_name: 'PLAAS FARMERS TOKEN',
    currency_symbol: 'PLAAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x89303500a7abfb178b274fd89f2469c264951e1f',
    currency_name: 'RefToken',
    currency_symbol: 'REF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x95e40e065afb3059dcabe4aaf404c1f92756603a',
    currency_name: 'King DAG',
    currency_symbol: 'KDAG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1beef31946fbbb40b877a72e4ae04a8d1a5cee06',
    currency_name: 'Parachute',
    currency_symbol: 'PAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7025bab2ec90410de37f488d1298204cd4d6b29d',
    currency_name: 'Xriba',
    currency_symbol: 'XRA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x59af0356cdebd1fa23ae5dadff9170bbfc31278c',
    currency_name: 'Two Prime FF1 Token',
    currency_symbol: 'FF1',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c949199cff14aeaf1b33d64db01f48fb57f592f',
    currency_name: 'Coni',
    currency_symbol: 'CONI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdd94842c15abfe4c9bafe4222ade02896beb064c',
    currency_name: 'W Green Pay',
    currency_symbol: 'WGP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4af328c52921706dcb739f25786210499169afe6',
    currency_name: 'Sakura Bloom',
    currency_symbol: 'SKB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x499a6b77bc25c26bcf8265e2102b1b3dd1617024',
    currency_name: 'Bitether',
    currency_symbol: 'BTR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7051620d11042c4335069AaA4f10Cd3B4290C681',
    currency_name: 'TCASH',
    currency_symbol: 'TCASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5acd19b9c91e596b1f062f18e3d02da7ed8d1e50',
    currency_name: 'BTC Lite',
    currency_symbol: 'BTCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x97cb5cc1b2e10cc56dc16ab9179f06dfedbe41a2',
    currency_name: 'MobilinkToken',
    currency_symbol: 'MOLK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x122A86b5DFF2D085AfB49600b4cd7375D0d94A5f',
    currency_name: 'Italian Lira',
    currency_symbol: 'ITL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0db8d8b76bc361bacbb72e2c491e06085a97ab31',
    currency_name: 'IQeon',
    currency_symbol: 'IQN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa974c709cfb4566686553a20790685a47aceaa33',
    currency_name: 'Mixin',
    currency_symbol: 'XIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe2492f8d2a2618d8709ca99b1d8d75713bd84089',
    currency_name: 'HeartBout',
    currency_symbol: 'HB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13339fd07934cd674269726edf3b5ccee9dd93de',
    currency_name: 'Curio',
    currency_symbol: 'CUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xebbdf302c940c6bfd49c6b165f457fdb324649bc',
    currency_name: 'Hydro',
    currency_symbol: 'HYDRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800003e5',
    currency_name: 'Sumcoin',
    currency_symbol: 'SUM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9388f54fa978aa9e24395a8b69033304eccea4df',
    currency_name: 'Bitscoin',
    currency_symbol: 'BTCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6c3be406174349cfa4501654313d97e6a31072e1',
    currency_name: 'CNNS',
    currency_symbol: 'CNNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x83eB94cB563146a42Fe0a8b3D051F2387A7FB81f',
    currency_name: 'Cipher Core Token',
    currency_symbol: 'CIPHC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x12b306fa98f4cbb8d4457fdff3a0a0a56f07ccdf',
    currency_name: 'Spectre.ai Dividend Token',
    currency_symbol: 'SXDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8f0921f30555624143d427b340b1156914882c10',
    currency_name: 'FlypMe',
    currency_symbol: 'FYP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000400.e5a49d7fd57e7178e189d3965d1ee64368a1036d ',
    currency_name: 'MovieBloc',
    currency_symbol: 'MBL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000098',
    currency_name: 'Diamond',
    currency_symbol: 'DMD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000e4',
    currency_name: 'Actinium',
    currency_symbol: 'ACM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0cf0ee63788a0849fe5297f3407f701e122cc023',
    currency_name: 'Streamr',
    currency_symbol: 'DATA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x61f33da40594cec1e3dc900faf99f861d01e2e7d',
    currency_name: 'Cybereits',
    currency_symbol: 'CRE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x744d70fdbe2ba4cf95131626614a1763df805b9e',
    currency_name: 'Status',
    currency_symbol: 'SNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd8002cd05d5b2a85557e1caaa179cc2408d5ad42',
    currency_name: 'BiNGO.Fun',
    currency_symbol: '777',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2dca19e944453e46d9130950ca135461b3bc0c30',
    currency_name: 'EYES Protocol',
    currency_symbol: 'EYES',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa00425d3e2d3e9ff74f3e112b4d3a7978d7d88c2',
    currency_name: 'GoPower',
    currency_symbol: 'GPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x973e52691176d36453868D9d86572788d27041A9',
    currency_name: 'DxChain Token',
    currency_symbol: 'DX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa806b3fed6891136940cf81c4085661500aa2709',
    currency_name: 'Sport and Leisure',
    currency_symbol: 'SNL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000056',
    currency_name: 'Ixcoin',
    currency_symbol: 'IXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xea54c81fe0f72de8e86b6dc78a9271aa3925e3b5',
    currency_name: 'Bgogo Token',
    currency_symbol: 'BGG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1148661869D30e095FF4AA48Aa8b5EadedC75f2A',
    currency_name: 'Amun Bitcoin 3x Daily Short',
    currency_symbol: 'BTC3S',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d3a536e4d6dbd6114cc1ead35777bab948e3643',
    currency_name: 'Compound Dai',
    currency_symbol: 'CDAI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8ae4bf2c33a8e667de34b54938b0ccd03eb8cc06',
    currency_name: 'Patientory',
    currency_symbol: 'PTOY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xD6F0Bb2A45110f819e908a915237D652Ac7c5AA8',
    currency_name: 'DFOhub',
    currency_symbol: 'BUIDL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004a.cx429731644462ebcfd22185df38727273f16f9b87',
    currency_name: 'SOMESING',
    currency_symbol: 'SSX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x834625F5D8B006D70a6CaAEeF73C29442F156dAF',
    currency_name: 'Instantily',
    currency_symbol: 'TILY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000017c',
    currency_name: 'Nyzo',
    currency_symbol: 'NYZO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf6bf74a97d78f2242376769ef1e79885cf1f0c1c',
    currency_name: 'KAASO',
    currency_symbol: 'KAASO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5e3845a1d78db544613edbe43dc1ea497266d3b8',
    currency_name: 'LNX Protocol',
    currency_symbol: 'LNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcabec58a571979f9fe825885fcb8f7a93892eab0',
    currency_name: 'United Bull Traders',
    currency_symbol: 'UNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x45af324f53a8d7da1752dad74adc1748126d7978',
    currency_name: 'MyTVchain',
    currency_symbol: 'MYTV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2344871f523cbb28a4f60045531184cf1f03ad24',
    currency_name: 'RoBET',
    currency_symbol: 'ROBET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1umdp5z4hugur26tcgf48fhr0548fv0q0fga84u',
    currency_name: 'BAWnetwork',
    currency_symbol: 'BAW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1da015ea4ad2d3e5586e54b9fb0682ca3ca8a17a',
    currency_name: 'Dragon Token',
    currency_symbol: 'DT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa96f31f1c187c28980176c3a27ba7069f48abde4',
    currency_name: 'Ethereum Gold Project',
    currency_symbol: 'ETGP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc324a2f6b05880503444451b8b27e6f9e63287cb',
    currency_name: 'Exchange Union',
    currency_symbol: 'XUC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xabbbb6447b68ffd6141da77c18c7b5876ed6c5ab',
    currency_name: 'DATx',
    currency_symbol: 'DATX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x423b5f62b328d0d6d44870f4eee316befa0b2df5',
    currency_name: 'GoNetwork',
    currency_symbol: 'GOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf8b358b3397a8ea5464f8cc753645d42e14b79ea',
    currency_name: 'Airbloc',
    currency_symbol: 'ABL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.NPXSXEM-89C',
    currency_name: 'Pundi X NEM',
    currency_symbol: 'NPXSXEM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa13f0743951b4f6e3e3aa039f682e17279f52bc3',
    currency_name: 'Sentinel Chain',
    currency_symbol: 'SENC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf6923F7d96fc22c4b8010a865e41cF7edfB6379C',
    currency_name: 'IOOX System',
    currency_symbol: 'IOOX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x20b1A8a9cA1c7302b7f774266C491C7b11622779',
    currency_name: 'ARCS',
    currency_symbol: 'ARX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4cd988afbad37289baaf53c13e98e2bd46aaea8c',
    currency_name: 'KEY',
    currency_symbol: 'KEY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x97aeb5066e1a590e868b511457beb6fe99d329f5',
    currency_name: 'Atonomi',
    currency_symbol: 'ATMI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x18f5b4908e8861e3114ba9a0a9a4e84c5f180cc0',
    currency_name: 'Esports Token',
    currency_symbol: 'EST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4bbbc57af270138ef2ff2c50dbfad684e9e0e604',
    currency_name: 'WABnetwork',
    currency_symbol: 'WAB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3166c570935a7d8554c8f4ea792ff965d2efe1f2',
    currency_name: 'Q DAO Governance token v1.0',
    currency_symbol: 'QDAO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8c15ef5b4b21951d50e53e4fbda8298ffad25057',
    currency_name: 'Function X',
    currency_symbol: 'FX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x01ff50f8b7f74e4f00580d9596cd3d0d6d6e326f',
    currency_name: 'BnkToTheFuture',
    currency_symbol: 'BFT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x90d46a9636b973f18186541d1b04ed3621a49cb0',
    currency_name: 'Natmin Pure Escrow',
    currency_symbol: 'NAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x139d9397274bb9e2c29a9aa8aa0b5874d30d62e3',
    currency_name: 'BoutsPro',
    currency_symbol: 'BOUTS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8515cD0f00aD81996d24b9A9C35121a3b759D6Cd',
    currency_name: 'Blockburn',
    currency_symbol: 'BURN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaaaaaaaba2ea3daab0a6c05f1b962c78c9836d99',
    currency_name: 'Azbit',
    currency_symbol: 'AZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ECO-083',
    currency_name: 'Ormeus Ecosystem',
    currency_symbol: 'ECO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7e5F9F248e84EF0B1f63586323e92a0d91B15568',
    currency_name: 'Amun Bitcoin 3x Daily Long',
    currency_symbol: 'BTC3L',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf091cf09c51811819db705710e9634b8bf18f164',
    currency_name: 'Couchain',
    currency_symbol: 'COU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x539efe69bcdd21a83efd9122571a64cc25e0282b',
    currency_name: 'Blue Protocol',
    currency_symbol: 'BLUE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbb97e381f1d1e94ffa2a5844f6875e6146981009',
    currency_name: 'WiBX',
    currency_symbol: 'WBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x8000003c.0x594207c791afd06a8d087d84d99d1da53ccbd45f#tokenTrade',
    currency_name: 'Buzzshow',
    currency_symbol: 'GLDY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc8058d59e208399b76e66da1ec669dd6b1bee2ea',
    currency_name: 'Gold Bits Coin',
    currency_symbol: 'GBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CHZ-ECD',
    currency_name: 'Chiliz',
    currency_symbol: 'CHZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6400b5522f8d448c0803e6245436dd1c81df09ce',
    currency_name: 'Content Value Network',
    currency_symbol: 'CVNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6368e1E18c4C419DDFC608A0BEd1ccb87b9250fc',
    currency_name: 'Tap',
    currency_symbol: 'XTP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6e5a43db10b04701385a34afb670e404bc7ea597',
    currency_name: 'Rakon',
    currency_symbol: 'RKN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2467aa6b5a2351416fd4c3def8462d841feeecec',
    currency_name: 'qiibee',
    currency_symbol: 'QBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x851017523ae205adc9195e7f97d029f4cfe7794c',
    currency_name: 'Social Lending Token',
    currency_symbol: 'SLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000eb',
    currency_name: 'FIO Protocol',
    currency_symbol: 'FIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6059f55751603ead7dc6d280ad83a7b33d837c90',
    currency_name: 'Hybrid Block',
    currency_symbol: 'HYB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff2b3353c3015e9f1fbf95b9bda23f58aa7ce007',
    currency_name: 'BitScreener Token',
    currency_symbol: 'BITX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb705268213d593b8fd88d3fdeff93aff5cbdcfae',
    currency_name: 'IDEX',
    currency_symbol: 'IDEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000f2',
    currency_name: 'Nimiq',
    currency_symbol: 'NIM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x36151737b45017234e9570cf9a1cac97138953c2',
    currency_name: 'NOIZ',
    currency_symbol: 'NOIZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.LOKI-6A9',
    currency_name: 'Loki',
    currency_symbol: 'LOKI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000a9e.n1zUNqeBPvsyrw5zxp9mKcDdLTjuaEL7s39',
    currency_name: 'Atlas Protocol',
    currency_symbol: 'ATP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x94cb815f4b601b00b363b3177b4d8ed8e0eb7cf2',
    currency_name: 'Coin Controller Cash',
    currency_symbol: 'CCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4161725d019690a3e0de50f6be67b07a86a9fae1',
    currency_name: 'TokenPocket',
    currency_symbol: 'TPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5b11aacb6bddb9ffab908fdce739bf4aed554327',
    currency_name: 'TrueDeck',
    currency_symbol: 'TDP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd1ef9a7310d0806855c672288ef5a1bab62cef33',
    currency_name: 'BELIEVER',
    currency_symbol: 'BLVR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3505f494c3f0fed0b594e01fa41dd3967645ca39',
    currency_name: 'Swarm',
    currency_symbol: 'SWM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.ed07cffad18f1308db51920d99a2af60ac66a7b3',
    currency_name: 'Phantasma',
    currency_symbol: 'SOUL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26946ada5ecb57f3a1f91605050ce45c482c9eb1',
    currency_name: 'BitcoinSoV',
    currency_symbol: 'BSOV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x25200235ca7113c2541e70de737c41f5e9acd1f6',
    currency_name: 'PATHHIVE ',
    currency_symbol: 'PHV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6b175474e89094c44da98b954eedeac495271d0f',
    currency_name: 'Multi-collateral DAI',
    currency_symbol: 'DAI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x81705082ef9f0d660f07be80093d46d826d48b25',
    currency_name: 'Globalvillage Ecosystem',
    currency_symbol: 'GVE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x226bb599a12c826476e3a771454697ea52e9e220',
    currency_name: 'Propy',
    currency_symbol: 'PRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa83af809975619477af73b179e05e04a1ccea953',
    currency_name: 'LEOcoin',
    currency_symbol: 'LC4',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8F8e787989BC652eeA01A6C88a19f0f379BDF4FD',
    currency_name: 'Helex',
    currency_symbol: 'HLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c543e7ae0a1104f78406c340e9c64fd9fce5170',
    currency_name: 'vSlice',
    currency_symbol: 'VSL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc5bbae50781be1669306b9e001eff57a2957b09d',
    currency_name: 'Gifto',
    currency_symbol: 'GTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7c84e62859d0715eb77d1b1c4154ecd6abb21bec',
    currency_name: 'SHPING',
    currency_symbol: 'SHPING',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x271220fbefd584a6b0a6ad457721c076321646a1',
    currency_name: 'FEX Token',
    currency_symbol: 'FEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001e5',
    currency_name: 'Freecash',
    currency_symbol: 'FCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4df47b4969b2911c966506e3592c41389493953b',
    currency_name: 'FundRequest',
    currency_symbol: 'FND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.dappservices',
    currency_name: 'LiquidApps',
    currency_symbol: 'DAPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb5b8f5616fe42d5ceca3e87f3fddbdd8f496d760',
    currency_name: 'ZPER',
    currency_symbol: 'ZPR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x94FC5934cF5970E944a67de806eEB5a4b493c6E6',
    currency_name: '3x Short XRP Token',
    currency_symbol: 'XRPBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000089',
    currency_name: 'RSK Smart Bitcoin',
    currency_symbol: 'RBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.AVA-645',
    currency_name: 'Travala.com',
    currency_symbol: 'AVA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbfe03707adb75b478add9a01978057803f480e44',
    currency_name: 'Imsmart',
    currency_symbol: 'IMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x419d0d8bdd9af5e606ae2232ed285aff190e711b',
    currency_name: 'FunFair',
    currency_symbol: 'FUN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80002304.0xc0134b5b924c2fca106efb33c45446c466fbe03e',
    currency_name: 'Aleph.im',
    currency_symbol: 'ALEPH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4689a4e169eb39cc9078c0940e21ff1aa8a39b9c',
    currency_name: 'Proton Token',
    currency_symbol: 'PTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000575',
    currency_name: 'HYCON',
    currency_symbol: 'HYC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbf5496122cf1bb778e0cbe5eab936f2be5fc0940',
    currency_name: 'FundToken',
    currency_symbol: 'FUNDZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1fB6bccc7Da51aa32e96118B8A33226d2Ae16517',
    currency_name: 'Global Rental Token',
    currency_symbol: 'GRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe0b7927c4af23765cb51314a0e0521a9645f0e2a',
    currency_name: 'DigixDAO',
    currency_symbol: 'DGD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x07e3c70653548b04f0a75970c1f81b4cbbfb606f',
    currency_name: 'Agrello',
    currency_symbol: 'DLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.eosdtsttoken',
    currency_name: 'EOSDT',
    currency_symbol: 'EOSDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5caf454ba92e6f2c929df14667ee360ed9fd5b26',
    currency_name: 'Dev Protocol',
    currency_symbol: 'DEV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4edd66235349e353eb8cb8e40596599644bfe91c',
    currency_name: 'Vanywhere',
    currency_symbol: 'VANY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x93190dbce9b9bd4aa546270a8d1d65905b5fdd28',
    currency_name: 'DAPS Coin',
    currency_symbol: 'DAPS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000158',
    currency_name: 'Auxilium',
    currency_symbol: 'AUX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26fb86579e371c7aedc461b2ddef0a8628c93d3b',
    currency_name: 'BORA',
    currency_symbol: 'BORA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa31b1767e09f842ecfd4bc471fe44f830e3891aa',
    currency_name: 'ROOBEE',
    currency_symbol: 'ROOBEE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1d287cc25dad7ccaf76a26bc660c5f7c8e2a05bd',
    currency_name: 'Fetch.ai',
    currency_symbol: 'FET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.FTT-F11',
    currency_name: 'FTX Token',
    currency_symbol: 'FTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x44e2ca91cea1147f1b503e669f06cd11fb0c5490',
    currency_name: 'CoinMetro Token',
    currency_symbol: 'XCM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x85089389c14bd9c77fc2b8f0c3d1dc3363bf06ef',
    currency_name: 'SportyCo',
    currency_symbol: 'SPF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000020b',
    currency_name: 'Edgeware',
    currency_symbol: 'EDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x77c07555af5ffdc946fb47ce15ea68620e4e7170',
    currency_name: 'Breezecoin',
    currency_symbol: 'BRZE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbe6c8f2810ef39420d2dc2901b8414c8c45fee6d',
    currency_name: 'Micromines',
    currency_symbol: 'MICRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000054',
    currency_name: 'GridCoin',
    currency_symbol: 'GRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x995de3d961b40ec6cdee0009059d48768ccbdd48',
    currency_name: 'Union Fair Coin',
    currency_symbol: 'UFC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc54083e77f913a4f99e1232ae80c318ff03c9d17',
    currency_name: 'CariNet',
    currency_symbol: 'CIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7f121d4EC6c2C07eB6BC7989d91d2d4fF654c068',
    currency_name: 'CoinMeet',
    currency_symbol: 'MEET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa957045a12d270e2ee0dca9a3340c340e05d4670',
    currency_name: 'AIDUS TOKEN',
    currency_symbol: 'AIDUS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x814f67fa286f7572b041d041b1d99b432c9155ee',
    currency_name: 'Dragon Coins',
    currency_symbol: 'DRG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x68eb95dc9934e19b86687a10df8e364423240e94',
    currency_name: '3X Long Bitcoin Token',
    currency_symbol: 'BULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x12fd19dac0fab61bed5e0f09091b470c452d4d61',
    currency_name: 'Echoin',
    currency_symbol: 'EC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x58002a6b6e659a16de9f02f529b10536e307b0d9',
    currency_name: 'Crypto Holding Frank Token',
    currency_symbol: 'CHFT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1961b3331969ed52770751fc718ef530838b6dee',
    currency_name: 'BitDegree',
    currency_symbol: 'BDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x686c650dbcfeaa75d09b883621ad810f5952bd5d',
    currency_name: 'AAX Token',
    currency_symbol: 'AAB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000167d',
    currency_name: 'Blockstack',
    currency_symbol: 'STX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeb9a4b185816c354db92db09cc3b50be60b901b6',
    currency_name: 'Origin Sport',
    currency_symbol: 'ORS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000013b',
    currency_name: 'Jingtum Tech',
    currency_symbol: 'SWTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd5f788ca0de8f17cbde1d1e35aa8f005a87fa00b',
    currency_name: 'Shivers',
    currency_symbol: 'SHVR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000092',
    currency_name: 'Neblio',
    currency_symbol: 'NEBL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8db54ca569d3019a2ba126d03c37c44b5ef81ef6',
    currency_name: 'Datawallet',
    currency_symbol: 'DXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf88951d7b676798705fd3a362ba5b1dbca2b233b',
    currency_name: 'PIXEL',
    currency_symbol: 'PXL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x24dcc881e7dd730546834452f21872d5cb4b5293',
    currency_name: 'Data Transaction Token',
    currency_symbol: 'XD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xae73b38d1c9a8b274127ec30160a4927c4d71824',
    currency_name: 'STK',
    currency_symbol: 'STK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x27f610bf36eca0939093343ac28b1534a721dbb4',
    currency_name: 'WandX',
    currency_symbol: 'WAND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x588047365df5ba589f923604aac23d673555c623',
    currency_name: 'Naviaddress',
    currency_symbol: 'NAVI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3f06b5d78406cd97bdf10f5c420b241d32759c80',
    currency_name: 'CyberFM',
    currency_symbol: 'CYFM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x49184e6dae8c8ecd89d8bdc1b950c597b8167c90',
    currency_name: 'Libertas Token',
    currency_symbol: 'LIBERTAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b1',
    currency_name: 'BitcoinZ',
    currency_symbol: 'BTCZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc20464e0c373486d2b3335576e83a218b1618a5e',
    currency_name: 'Datarius Credit',
    currency_symbol: 'DTRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7458fd786B2fe8CD801C0381f88b61C5071A006F',
    currency_name: 'LOA Protocol',
    currency_symbol: 'LOA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x082e13494f12ebb7206fbf67e22a6e1975a1a669',
    currency_name: 'Artis Turba',
    currency_symbol: 'ARTIS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd',
    currency_name: 'Qtum',
    currency_symbol: 'QTUM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001a1',
    currency_name: 'Wownero',
    currency_symbol: 'WOW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x026f8d523d49f36db657e012c96488465d8d88f9',
    currency_name: 'Odyssey',
    currency_symbol: 'ODY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x31f3d9d1bece0c033ff78fa6da60a6048f3e13c5',
    currency_name: 'EBCoin',
    currency_symbol: 'EBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.XXA-GC4HS4CQCZULIOTGLLPGRAAMSBDLFRR6Y7HCUQG66LNQDISXKIXXADIM',
    currency_name: 'Ixinium',
    currency_symbol: 'XXA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x68909e586eeac8f47315e84b4c9788dd54ef65bb',
    currency_name: 'EvenCoin',
    currency_symbol: 'EVN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0db03b6cde0b2d427c64a04feafd825938368f1f',
    currency_name: 'PDATA',
    currency_symbol: 'PDATA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c83501478f1320977047008496dacbd60bb15ef',
    currency_name: 'Digitex Futures',
    currency_symbol: 'DGTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c0',
    currency_name: 'Litecoin Cash',
    currency_symbol: 'LCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6ec8a24cabdc339a06a172f8223ea557055adaa5',
    currency_name: 'Genaro Network',
    currency_symbol: 'GNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe5dada80aa6477e85d09747f2842f7993d0df71c',
    currency_name: 'Dock',
    currency_symbol: 'DOCK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9d86b1b2554ec410eccffbf111a6994910111340',
    currency_name: 'Open Platform',
    currency_symbol: 'OPEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x86fadb80d8d2cff3c3680819e4da99c10232ba0f',
    currency_name: 'EURBASE',
    currency_symbol: 'EBASE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8971f9fd7196e5cee2c1032b50f656855af7dd26',
    currency_name: 'Lambda',
    currency_symbol: 'LAMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9214ec02cb71cba0ada6896b8da260736a67ab10',
    currency_name: 'REAL',
    currency_symbol: 'REAL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0000000000085d4780B73119b644AE5ecd22b376',
    currency_name: 'TrueUSD',
    currency_symbol: 'TUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000d8',
    currency_name: 'FLO',
    currency_symbol: 'FLO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xea38eaa3c86c8f9b751533ba2e562deb9acded40',
    currency_name: 'Etherparty',
    currency_symbol: 'FUEL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8fc9b6354E839AB1c8B31F4afa53607092B8C2e5',
    currency_name: 'ECOSC',
    currency_symbol: 'ECU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000011e',
    currency_name: 'HEAT',
    currency_symbol: 'HEAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.eosblackteam',
    currency_name: 'eosBLACK',
    currency_symbol: 'BLACK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GBIL5TOVTZFNV3XS3E2LWTKU5SYOJ3UBCVBSKAMP4EE4MV2VSZQK7NRZ',
    currency_name: 'WOLLO',
    currency_symbol: 'WLO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x327682779bab2bf4d1337e8974ab9de8275a7ca8',
    currency_name: 'Blockport',
    currency_symbol: 'BPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000032',
    currency_name: 'Novacoin',
    currency_symbol: 'NVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca. bnb1vdjhrkgvt4y76ykyvrvh68pzqg3lvv0y5yfxyf',
    currency_name: 'Raven Protocol',
    currency_symbol: 'RAVEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000048',
    currency_name: 'Crown',
    currency_symbol: 'CRW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x076c97e1c869072ee22f8c91978c99b4bcb02591',
    currency_name: 'CommerceBlock',
    currency_symbol: 'CBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4de2573e27e648607b50e1cfff921a33e4a34405',
    currency_name: 'Lendroid Support Token',
    currency_symbol: 'LST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CBIX-3C9',
    currency_name: 'Cubiex',
    currency_symbol: 'CBIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcb17cd357c7acd594717d899ecb9df540f633f27',
    currency_name: 'CoinDeal Token',
    currency_symbol: 'CDL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa823e6722006afe99e91c30ff5295052fe6b8e32',
    currency_name: 'Neumark',
    currency_symbol: 'NEU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c289a12a8552b314d0d153d6991fd27a54aa640',
    currency_name: 'BitMax Token',
    currency_symbol: 'BTMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x653430560be843c4a3d143d0110e896c2ab8ac0d',
    currency_name: 'Molecular Future',
    currency_symbol: 'MOF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3c7b464376db7c9927930cf50eefdea2eff3a66a',
    currency_name: 'USDA',
    currency_symbol: 'USDA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x170b275ced089fffaebfe927f445a350ed9160dc',
    currency_name: 'OWNDATA',
    currency_symbol: 'OWN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3543638ed4a9006e4840b105944271bcea15605d',
    currency_name: 'U Network',
    currency_symbol: 'UUU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GDZJD363YP7P3TNYDK3ZD6GLXFMAI3GLVIH7CGFLNZWIZBQUCVE6PTU7',
    currency_name: 'Newscrypto',
    currency_symbol: 'NWC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1fff4dd33105054e853955c6d0dba82859c01cff',
    currency_name: 'Unochain',
    currency_symbol: 'UNOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5732046a883704404f284ce41ffadd5b007fd668',
    currency_name: 'Bluzelle',
    currency_symbol: 'BLZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x75231f58b43240c9718dd58b4967c5114342a86c',
    currency_name: 'OKB',
    currency_symbol: 'OKB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000076',
    currency_name: 'Cosmos',
    currency_symbol: 'ATOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1829aa045e21e0d59580024a951db48096e01782',
    currency_name: 'FuzeX',
    currency_symbol: 'FXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8AF17a6396c8f315f6b6DBC6AA686C85f9b3E554',
    currency_name: '3x Long Tezos Token',
    currency_symbol: 'XTZBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7f969c4d388ca0ae39a4fddb1a6f89878ca2fbf8',
    currency_name: 'Global Game Coin',
    currency_symbol: 'GGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000077',
    currency_name: 'PIVX',
    currency_symbol: 'PIVX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x61D71973A6FfD07d5F1095AED53b06E5673E64BC',
    currency_name: 'KYC.Crypto',
    currency_symbol: 'MIMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70d2b7c19352bb76e4409858ff5746e500f2b67c',
    currency_name: 'Pawtocol',
    currency_symbol: 'UPI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001cc',
    currency_name: 'Gleec',
    currency_symbol: 'GLEEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x103c3a209da59d3e7c4a89307e66521e081cfdf0',
    currency_name: 'Genesis Vision',
    currency_symbol: 'GVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x647f274b3a7248d6cf51b35f08e7e7fd6edfb271',
    currency_name: 'Maggie',
    currency_symbol: 'MAG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000698',
    currency_name: 'BitcoinX',
    currency_symbol: 'BCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x49bd2da75b1f7af1e4dfd6b1125fecde59dbec58',
    currency_name: 'Linkey',
    currency_symbol: 'LKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0417912b3a7af768051765040a55bb0925d4ddcf',
    currency_name: 'Liquidity Dividends Protocol',
    currency_symbol: 'LID',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x01cc4151fe5f00efb8df2f90ff833725d3a482a3',
    currency_name: 'Spectrum',
    currency_symbol: 'SPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa6a840e50bcaa50da017b91a0d86b8b2d41156ee',
    currency_name: 'EchoLink',
    currency_symbol: 'EKO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa1d65e8fb6e87b60feccbc582f7f97804b725521',
    currency_name: 'DXdao',
    currency_symbol: 'DXD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x846c66cf71c43f80403b51fe3906b3599d63336f',
    currency_name: 'PumaPay',
    currency_symbol: 'PMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2aec18c5500f21359ce1bea5dc1777344df4c0dc',
    currency_name: 'FarmaTrust',
    currency_symbol: 'FTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xCe49c3c92b33a1653F34811a9d7e34502bF12B89',
    currency_name: '3x Short Bitcoin SV Token',
    currency_symbol: 'BSVBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcd8544defedec7c6b60b5a4232320365b1b21fcc',
    currency_name: 'GAPS',
    currency_symbol: 'GAP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.COTI-CBB',
    currency_name: 'COTI',
    currency_symbol: 'COTI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x87026f792d09960232ca406e80c89bd35bafe566',
    currency_name: 'Commerce Data Connection',
    currency_symbol: 'CDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000334',
    currency_name: 'Callisto Network',
    currency_symbol: 'CLO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7c2e5b7ec572199d3841f6a38f7d4868bd0798f1',
    currency_name: 'Havy',
    currency_symbol: 'HAVY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7869c4a1a3f6f8684fbcc422a21ad7abe3167834',
    currency_name: 'Pivot Token',
    currency_symbol: 'PVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001ac',
    currency_name: 'ZCore',
    currency_symbol: 'ZCR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000007a',
    currency_name: 'PutinCoin',
    currency_symbol: 'PUT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdb13025b219db5e4529f48b65ff009a26b6ae733',
    currency_name: 'Ubricoin',
    currency_symbol: 'UBN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2b6ff53fc2493ccd5202d80a6c439741414c5ff2',
    currency_name: 'Tweebaa',
    currency_symbol: 'TWEE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xce5114d7fa8361f0c088ee26fa3a5446c4a1f50b',
    currency_name: 'Blue Whale EXchange',
    currency_symbol: 'BWX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa15c7ebe1f07caf6bff097d8a589fb8ac49ae5b3',
    currency_name: 'Pundi X',
    currency_symbol: 'NPXS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xde7d85157d9714eadf595045cc12ca4a5f3e2adb',
    currency_name: 'Standard Tokenization Protocol',
    currency_symbol: 'STPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1b073382e63411e3bcffe90ac1b9a43fefa1ec6f',
    currency_name: 'Bitpanda Ecosystem Token',
    currency_symbol: 'BEST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.3a4acd3647086e7c44398aac0349802e6a171129',
    currency_name: 'Nash Exchange',
    currency_symbol: 'NEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x86149c67e57c749d0a12e6d6c2bf1b616619bb29',
    currency_name: 'Bancacy',
    currency_symbol: 'BNY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdb7eab9ba6be88b869f738f6deeba96d49fe13fd',
    currency_name: 'BOOM',
    currency_symbol: 'BOOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800006c1',
    currency_name: 'Tezos',
    currency_symbol: 'XTZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000083',
    currency_name: 'Factom',
    currency_symbol: 'FCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4e3bddd468abfc6c88bc25daa5d894380ced5bc8',
    currency_name: 'NSS Coin',
    currency_symbol: 'NSS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f4ca92660efad97a9a70cb0fe969c755439772c',
    currency_name: 'Leverj',
    currency_symbol: 'LEV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbba1da8aff34ebef5ce4d68312b33ef629548b06',
    currency_name: 'FOCV',
    currency_symbol: 'FOCV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc4199fB6FFDb30A829614becA030f9042f1c3992',
    currency_name: 'snglsDAO',
    currency_symbol: 'SGT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7a41e0517a5eca4fdbc7fbeba4d4c47b9ff6dc63',
    currency_name: 'Zeusshield',
    currency_symbol: 'ZSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x310c93dfc1c5e34cdf51678103f63c41762089cd',
    currency_name: '1irstcoin',
    currency_symbol: 'FST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x658d79c02a4829b5efa49254f627287e92458ecd',
    currency_name: 'M2O',
    currency_symbol: 'M2O',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe6d2c3cb986db66818c14c7032db05d1d2a6ee74',
    currency_name: 'FinexboxToken',
    currency_symbol: 'FNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x910dfc18d6ea3d6a7124a6f8b5458f281060fa4c',
    currency_name: 'X8X Token',
    currency_symbol: 'X8X',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbdc5bac39dbe132b1e030e898ae3830017d7d969',
    currency_name: 'Snovian.Space',
    currency_symbol: 'SNOV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.ab38352559b8b203bde5fddfa0b07d8b2525e132',
    currency_name: 'Switcheo',
    currency_symbol: 'SWTH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13f25cd52b21650caa8225c9942337d914c9b030',
    currency_name: 'RealChain',
    currency_symbol: 'RCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaf1250fa68d7decd34fd75de8742bc03b29bd58e',
    currency_name: 'Invictus Hyperion Fund',
    currency_symbol: 'IHF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80776773',
    currency_name: 'Observer',
    currency_symbol: 'OBSR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000028',
    currency_name: 'Expanse',
    currency_symbol: 'EXP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2d71983e810b9e95258966b9c164c4d61a829ba9',
    currency_name: 'ICOCalendar.Today',
    currency_symbol: 'ICT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000075',
    currency_name: 'Pinkcoin',
    currency_symbol: 'PINK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf05a9382a4c3f29e2784502754293d88b835109c',
    currency_name: 'imbrex',
    currency_symbol: 'REX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7de2d123042994737105802d2abd0a10a7bde276',
    currency_name: 'MEXC Token',
    currency_symbol: 'MEXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000120',
    currency_name: 'Fusion',
    currency_symbol: 'FSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd13c7342e1ef687c5ad21b27c2b65d772cab5c8c',
    currency_name: 'Ultra',
    currency_symbol: 'UOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaDA62f7CCd6af6cAcff04ACCBC4f56f3D4FFd4Ef',
    currency_name: 'PlayFuel',
    currency_symbol: 'PLF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x790bfacae71576107c068f494c8a6302aea640cb',
    currency_name: 'CryptoBossCoin',
    currency_symbol: 'CBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbdbC2a5B32F3a5141ACd18C39883066E4daB9774',
    currency_name: 'Emirex Token',
    currency_symbol: 'EMRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001a9',
    currency_name: 'Aion',
    currency_symbol: 'AION',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xDaab5E695bb0E8Ce8384ee56BA38fA8290618e52',
    currency_name: 'CRDT',
    currency_symbol: 'CRDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbae235823d7255d9d48635ced4735227244cd583',
    currency_name: 'Staker',
    currency_symbol: 'STR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9c197c4b58527faaab67cb35e3145166b23d242e',
    currency_name: 'HashNet BitEco',
    currency_symbol: 'HNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb63b606ac810a52cca15e44bb630fd42d8d1d83d',
    currency_name: 'MCO',
    currency_symbol: 'MCO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7b0c06043468469967dba22d1af33d77d44056c8',
    currency_name: 'Morpheus.Network',
    currency_symbol: 'MRPH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f284e1337a815fe77d2ff4ae46544645b20c5ff',
    currency_name: 'Darwinia Commitment Token',
    currency_symbol: 'KTON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x283669123bd83da2536bb534e20512101c18e5d8',
    currency_name: 'Bitpayer Token',
    currency_symbol: 'BPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.SPNDB-916',
    currency_name: 'Spendcoin',
    currency_symbol: 'SPND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000087',
    currency_name: 'Steem',
    currency_symbol: 'STEEM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5e3346444010135322268a4630d2ed5f8d09446c',
    currency_name: 'LockTrip',
    currency_symbol: 'LOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000010d',
    currency_name: 'High Performance Blockchain',
    currency_symbol: 'HPB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc59cb23295e2DEEB66bd090ACB6B02BE8d30A11F',
    currency_name: 'Kublaicoin',
    currency_symbol: 'KUB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x37f74e99794853777a10ea1dc08a64c86958f06a',
    currency_name: 'D Community',
    currency_symbol: 'DILI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xca2796f9f61dc7b238aab043971e49c6164df375',
    currency_name: 'YGGDRASH',
    currency_symbol: 'YEED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd9d01d4cb824219a8f482a0fad479cb971fd0628',
    currency_name: 'EnterCoin',
    currency_symbol: 'ENTRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe65ee7c03bbb3c950cfd4895c24989afa233ef01',
    currency_name: 'Zynecoin',
    currency_symbol: 'ZYN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000082',
    currency_name: 'NavCoin',
    currency_symbol: 'NAV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc962ad021a69D457564e985738C719aE3f79B707',
    currency_name: 'IFX24',
    currency_symbol: 'IFX24',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TYM9eM22SCynRc5YaMxE2PX1kwv7H2rXAu',
    currency_name: 'Kingdom Game 4.0',
    currency_symbol: 'KDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000005c',
    currency_name: 'Unobtanium',
    currency_symbol: 'UNO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x63d958d765f5bd88efdbd8afd32445393b24907f',
    currency_name: 'Acash Coin',
    currency_symbol: 'ACA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8073656d',
    currency_name: 'Semux',
    currency_symbol: 'SEM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002b',
    currency_name: 'NEM',
    currency_symbol: 'XEM',
    is_active: true,
    addr_extra_info: ['message'],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3893b9422cd5d70a81edeffe3d5a1c6a978310bb',
    currency_name: 'Mithril',
    currency_symbol: 'MITH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x80fb784b7ed66730e8b1dbd9820afd29931aab03',
    currency_name: 'Aave',
    currency_symbol: 'LEND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d48f293baed247a2d0189058ba37aa238bd4725',
    currency_name: 'NeuroChain',
    currency_symbol: 'NCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3a92bd396aef82af98ebc0aa9030d25a23b11c6b',
    currency_name: 'Tokenbox',
    currency_symbol: 'TBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbc41d05287498DEc58129560De6bd1b8d4E3aC1d',
    currency_name: '3x Short Tezos Token',
    currency_symbol: 'XTZBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.XIO-B05',
    currency_name: 'XIO',
    currency_symbol: 'XIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfca47962d45adfdfd1ab2d972315db4ce7ccf094',
    currency_name: 'IXT',
    currency_symbol: 'IXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000000',
    currency_name: 'Bitcoin',
    currency_symbol: 'BTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xca0e7269600d353f70b14ad118a49575455c0f2f',
    currency_name: 'AMLT',
    currency_symbol: 'AMLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c50ba1ed5e4574c1b613b044bd1876f0b0b87a9',
    currency_name: 'Kids Cash',
    currency_symbol: 'KASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000d1',
    currency_name: 'Bismuth',
    currency_symbol: 'BIS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfd6c31bb6f05fc8db64f4b740ab758605c271fd8',
    currency_name: 'CONTRACOIN',
    currency_symbol: 'CTCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.1578103c13e39df15d0d29826d957e85d770d8c9',
    currency_name: 'Red Pulse Phoenix',
    currency_symbol: 'PHX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.08e8c4400f1af2c20c28e0018f29535eb85d15b6',
    currency_name: 'Trinity Network Credit',
    currency_symbol: 'TNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000116',
    currency_name: 'Bolivarcoin',
    currency_symbol: 'BOLI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x436f0f3a982074c4a05084485d421466a994fe53',
    currency_name: 'Rate3',
    currency_symbol: 'RTE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x32C4ADB9cF57f972bc375129de91C897b4F364F1',
    currency_name: 'Flowchain',
    currency_symbol: 'FLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000007d',
    currency_name: 'Stealth',
    currency_symbol: 'XST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe41d2489571d322189246dafa5ebde1f4699f498',
    currency_name: '0x',
    currency_symbol: 'ZRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9e77d5a1251b6f7d456722a6eac6d2d5980bd891',
    currency_name: 'BROTHER',
    currency_symbol: 'BRAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x905e337c6c8645263d3521205aa37bf4d034e745',
    currency_name: 'doc.com Token',
    currency_symbol: 'MTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.CVN',
    currency_name: 'CVCoin',
    currency_symbol: 'CVN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca. bnb1e4q8whcufp6d72w8nwmpuhxd96r4n0fstegyuy',
    currency_name: 'THORChain',
    currency_symbol: 'RUNE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb2135ab9695a7678dd590b1a996cb0f37bcb0718',
    currency_name: 'Signals Network',
    currency_symbol: 'SGN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xad5fe5b0b8ec8ff4565204990e4405b2da117d8e',
    currency_name: 'TRONCLASSIC',
    currency_symbol: 'TRXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3adfc4999f77d04c8341bac5f3a76f58dff5b37a',
    currency_name: 'Privatix',
    currency_symbol: 'PRIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x187d1018e8ef879be4194d6ed7590987463ead85',
    currency_name: 'FUZE Token',
    currency_symbol: 'FUZE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb683d83a532e2cb7dfa5275eed3698436371cc9f',
    currency_name: 'BTU Protocol',
    currency_symbol: 'BTU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa3d58c4e56fedcae3a7c43a725aee9a71f0ece4e',
    currency_name: 'Metronome',
    currency_symbol: 'MET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x580c8520deda0a441522aeae0f9f7a5f29629afa',
    currency_name: 'Dawn Protocol',
    currency_symbol: 'DAWN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000005',
    currency_name: 'Dash',
    currency_symbol: 'DASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x46b4a7d906F1A943b7744Df23625E63726d79035',
    currency_name: 'Codeo Token',
    currency_symbol: 'CODEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbf05571988daab22d33c28bbb13566eae9dee626',
    currency_name: '999',
    currency_symbol: '999',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.GMAT-FC8',
    currency_name: 'GoWithMi',
    currency_symbol: 'GMAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.GOLD',
    currency_name: 'bitGold',
    currency_symbol: 'BITGOLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc27a2f05fa577a83ba0fdb4c38443c0718356501',
    currency_name: 'Lamden',
    currency_symbol: 'TAU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BLINK-9C6',
    currency_name: 'Blockmason Link',
    currency_symbol: 'BLINK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1cb3209d45b2a60b7fbca1ccdbf87f674237a4aa',
    currency_name: 'ThoreCoin',
    currency_symbol: 'THR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000020d',
    currency_name: 'Gossip Coin',
    currency_symbol: 'GOSS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.ETLzrCpBqTrpyuMGdiVLBPZnUoKwte88oVdJjoFi5R2h',
    currency_name: 'Shadow Token',
    currency_symbol: 'SHDW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x763186eb8d4856d536ed4478302971214febc6a9',
    currency_name: 'BetterBetting',
    currency_symbol: 'BETR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x675ce995953136814cb05aaaa5d02327e7dc8c93',
    currency_name: 'Blue Baikal',
    currency_symbol: 'BBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x307d45afbb7e84f82ef3d251a6bb0f00edf632e4',
    currency_name: 'PLANET',
    currency_symbol: 'PLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcb94be6f13a1182e4a4b6140cb7bf2025d28e41b',
    currency_name: 'WeTrust',
    currency_symbol: 'TRST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    currency_name: 'USD Coin',
    currency_symbol: 'USDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GCVNN7O5JISPEYUTLK3JYGBDWCPDIHB4MTG4PMSJVIKJCR64NOXWI3YH',
    currency_name: 'Stellar Gold',
    currency_symbol: 'XLMG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe50365f5d679cb98a1dd62d6f6e58e59321bcddf',
    currency_name: 'LATOKEN',
    currency_symbol: 'LA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfb444c1f2b718ddfc385cb8fd9f2d1d776b24668',
    currency_name: 'Elamachain',
    currency_symbol: 'ELAMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x810908B285f85Af668F6348cD8B26D76B3EC12e1',
    currency_name: 'Swapcoinz',
    currency_symbol: 'SPAZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbcd8756ea481608ea3dd5a555493305cf0a79640',
    currency_name: 'Paparazzi',
    currency_symbol: 'PAZZI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd559f20296ff4895da39b5bd9add54b442596a61',
    currency_name: 'FintruX Network',
    currency_symbol: 'FTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2',
    currency_name: 'EOS',
    currency_symbol: 'EOS',
    is_active: true,
    addr_extra_info: ['memo'],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbd0793332e9fb844a52a205a233ef27a5b34b927',
    currency_name: 'ZB Token',
    currency_symbol: 'ZB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb70835d7822ebb9426b56543e391846c107bd32c',
    currency_name: 'Game.com',
    currency_symbol: 'GTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf3db5fa2c66b7af3eb0c0b782510816cbe4813b8',
    currency_name: 'Everex',
    currency_symbol: 'EVX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf1e9e1a218cff9888faef311d6fbb472e4175ce',
    currency_name: 'eToro Australian Dollar',
    currency_symbol: 'AUDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb0280743b44bf7db4b6be482b2ba7b75e5da096c',
    currency_name: 'Transcodium',
    currency_symbol: 'TNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x578b49c45961f98d8df92854b53f1641af0a5036',
    currency_name: 'LINKA',
    currency_symbol: 'LINKA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3d1ba9be9f66b8ee101911bc36d3fb562eac2244',
    currency_name: 'Rivetz',
    currency_symbol: 'RVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1fc52f1abade452dd4674477d4711951700b3d27',
    currency_name: 'Noku',
    currency_symbol: 'NOKU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x14e9',
    currency_name: 'Handshake ',
    currency_symbol: 'HNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xEd91879919B71bB6905f23af0A68d231EcF87b14',
    currency_name: 'DMM: Governance',
    currency_symbol: 'DMG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26CB3641aaA43911f1D4cB2ce544eb652AAc7c47',
    currency_name: 'Crystal Token',
    currency_symbol: 'CYL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfb5a551374b656c6e39787b1d3a03feab7f3a98e',
    currency_name: 'ThingsOperatingSystem',
    currency_symbol: 'TOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x916885426255235DA7a0BD90447986c00675f9EC',
    currency_name: 'GoalTime N',
    currency_symbol: 'GTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xC16b542ff490e01fcc0DC58a60e1EFdc3e357cA6',
    currency_name: 'ICE ROCK MINING',
    currency_symbol: 'ROCK2',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000786',
    currency_name: 'City Coin',
    currency_symbol: 'CITY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70c621f949b6556c4545707a2d5d73a776b98359',
    currency_name: 'Skychain',
    currency_symbol: 'SKCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x28c8d01ff633ea9cd8fc6a451d7457889e698de6',
    currency_name: 'Ethereum Gold',
    currency_symbol: 'ETG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d285f735998f36631f678ff41fb56a10a4d0429',
    currency_name: 'MixMarvel',
    currency_symbol: 'MIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf263292e14d9d8ecd55b58dad1f1df825a874b7c',
    currency_name: 'EduCoin',
    currency_symbol: 'EDU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5046e860ff274fb8c66106b0ffb8155849fb0787',
    currency_name: 'JavaScript Token',
    currency_symbol: 'JS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2396fbc0e2e3ae4b7206ebdb5706e2a5920349cb',
    currency_name: 'Color Platform',
    currency_symbol: 'CLR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5635ddeabf9cdda686995fe90beb5411831563fc',
    currency_name: 'TravelNote',
    currency_symbol: 'TVNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0a76aad21948ea1ef447d26dee91a54370e151e0',
    currency_name: 'Ethereum Lite',
    currency_symbol: 'ELITE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x177d39ac676ed1c67a2b268ad7f1e58826e5b0af',
    currency_name: 'Blox',
    currency_symbol: 'CDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd7efb00d12c2c13131fd319336fdf952525da2af',
    currency_name: 'Proton',
    currency_symbol: 'XPR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xacfa209fb73bf3dd5bbfb1101b9bc999c49062a5',
    currency_name: 'Blockchain Certified Data Token',
    currency_symbol: 'BCDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd0a4b8946cb52f0661273bfbc6fd0e0c75fc6433',
    currency_name: 'Storm',
    currency_symbol: 'STORM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000006f',
    currency_name: 'Ark',
    currency_symbol: 'ARK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1014613e2b3cbc4d575054d4982e580d9b99d7b1',
    currency_name: 'BitCapitalVendor',
    currency_symbol: 'BCV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4289c043a12392f1027307fb58272d8ebd853912',
    currency_name: 'AiLink Token',
    currency_symbol: 'ALI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc72fe8e3dd5bef0f9f31f259399f301272ef2a2d',
    currency_name: 'Insights Network',
    currency_symbol: 'INSTAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xEF9c8a1b3cE9055266E1CE20b98a4c882F0e5c78',
    currency_name: 'Amun Ether 3x Daily Short',
    currency_symbol: 'ETH3S',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4f56221252d117f35E2f6Ab937A3F77CAd38934D',
    currency_name: 'Crypto Cricket Club',
    currency_symbol: '3Cs',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa0d440c6da37892dc06ee7930b2eede0634fd681',
    currency_name: 'MASTERNET',
    currency_symbol: 'MASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8275ebf521dc217aa79c88132017a5bcef001dd9',
    currency_name: 'Jewel',
    currency_symbol: 'JWL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x40AdFc7c23c22Cc06f94F199a4750D7196F46fbe',
    currency_name: 'Coinchase Token',
    currency_symbol: 'CCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc64500dd7b0f1794807e67802f8abbf5f8ffb054',
    currency_name: 'Locus Chain',
    currency_symbol: 'LOCUS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GBNLJIYH34UWO5YZFA3A3HD3N76R6DOI33N4JONUOHEEYZYCAYTEJ5AK',
    currency_name: 'Realio Network',
    currency_symbol: 'RIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x386cabc0b14a507a4e024dea15554342865b20de',
    currency_name: 'Dapp Token',
    currency_symbol: 'DAPPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000121',
    currency_name: 'Capricoin',
    currency_symbol: 'CPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x081f67afa0ccf8c7b17540767bbe95df2ba8d97f',
    currency_name: 'CoinEx Token',
    currency_symbol: 'CET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaf4dce16da2877f8c9e00544c93b62ac40631f16',
    currency_name: 'Monetha',
    currency_symbol: 'MTH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000009c',
    currency_name: 'Bitcoin Gold',
    currency_symbol: 'BTG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x331fa6c97c64e47475164b9fc8143b533c5ef529',
    currency_name: 'EXMR FDN',
    currency_symbol: 'EXMR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5136c98a80811c3f46bdda8b5c4555cfd9f812f0',
    currency_name: 'indaHash',
    currency_symbol: 'IDH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2e68dfb3f50ea302c88f8db74096d57565d9970a',
    currency_name: 'Amino Network',
    currency_symbol: 'AMIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TUL5yxRKeSWvceLZ3BSU5iNJcQmNxkWayh',
    currency_name: 'VENA',
    currency_symbol: 'VENA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x38A2fDc11f526Ddd5a607C1F251C065f40fBF2f7',
    currency_name: 'PhoenixDAO',
    currency_symbol: 'PHNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd24DFf6117936B6ff97108CF26c1dD8865743d87',
    currency_name: 'Medium',
    currency_symbol: 'MDM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfbc3c8aad80b5934d134e2cce065702ff254ad7d',
    currency_name: 'CHEX',
    currency_symbol: 'CHEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6295ab2be04a617747481b292c390bfca592cf28',
    currency_name: 'TokenDesk',
    currency_symbol: 'TDS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001bc',
    currency_name: 'Phore',
    currency_symbol: 'PHR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf03045a4c8077e38f3b8e2ed33b8aee69edf869f',
    currency_name: 'BlockMesh',
    currency_symbol: 'BMH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc96df921009b790dffca412375251ed1a2b75c60',
    currency_name: 'Ormeus Coin',
    currency_symbol: 'ORMEUS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaec2e87e0a235266d9c5adc9deb4b2e29b54d009',
    currency_name: 'SingularDTV',
    currency_symbol: 'SNGLS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x86807Da5B92d31F67E128771CAcb85F3579646eA',
    currency_name: '3X Short TRX Token',
    currency_symbol: 'TRXBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xadf8b8050639b6236915f7516d69de714672f0bf',
    currency_name: 'Scanetchain',
    currency_symbol: 'SWC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x07597255910a51509ca469568b048f2597e72504',
    currency_name: 'Uptrennd',
    currency_symbol: '1UP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa829f97373069ee5d23175e4105df8fd49238be7',
    currency_name: 'Opennity',
    currency_symbol: 'OPNN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5102791ca02fc3595398400bfe0e33d7b6c82267',
    currency_name: 'Leadcoin',
    currency_symbol: 'LDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x09617f6fd6cf8a71278ec86e23bbab29c04353a7',
    currency_name: 'Shardus',
    currency_symbol: 'ULT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa101e27f06a97985b925e244111b61560ecd97db',
    currency_name: 'BITTO',
    currency_symbol: 'BITTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ARN-71B',
    currency_name: 'Aeron',
    currency_symbol: 'ARN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.XBASE-CD2',
    currency_name: 'Eterbase Coin',
    currency_symbol: 'XBASE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe9a95d175a5f4c9369f3b74222402eb1b837693b',
    currency_name: 'NOW Token',
    currency_symbol: 'NOW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a0f2ab46ec630f9fd638029027b552afa64b94c',
    currency_name: 'Aston',
    currency_symbol: 'ATX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8e1b448ec7adfc7fa35fc2e885678bd323176e34',
    currency_name: 'Egretia',
    currency_symbol: 'EGT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x48c1b2f3efa85fbafb2ab951bf4ba860a08cdbb7',
    currency_name: 'ShowHand',
    currency_symbol: 'HAND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004f',
    currency_name: 'ClubCoin',
    currency_symbol: 'CLUB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001bd',
    currency_name: 'Vitae',
    currency_symbol: 'VITAE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GCEFMSNWXTALXQPRQFIXOMWJHZFDEQJBM26RGEDZUDFMU32JB6WJGRJX',
    currency_name: 'EthereumX',
    currency_symbol: 'ETX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x832904863978b94802123106e6eb491bdf0df928',
    currency_name: 'OptiToken',
    currency_symbol: 'OPTI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcca0c9c383076649604ee31b20248bc04fdf61ca',
    currency_name: 'BitMax Token',
    currency_symbol: 'BTMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4922a015c4407f87432b179bb209e125432e4a2a',
    currency_name: 'Tether Gold',
    currency_symbol: 'XAUT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BCPT-95A',
    currency_name: 'Blockmason Credit Protocol',
    currency_symbol: 'BCPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3c6a7ab47b5f058be0e7c7fe1a4b7925b8aca40e',
    currency_name: 'Cajutel',
    currency_symbol: 'CAJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xec46f8207d766012454c408de210bcbc2243e71c',
    currency_name: 'Nitro',
    currency_symbol: 'NOX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9ea20fbfaa44efbc60c6728fcdba17f01b7e04fe',
    currency_name: 'Torex',
    currency_symbol: 'TOR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000156',
    currency_name: 'BLAST',
    currency_symbol: 'BLAST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB1A30851E3f7d841b231B086479608e17198363A',
    currency_name: 'Homeros',
    currency_symbol: 'HMR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe1bad922f84b198a08292fb600319300ae32471b',
    currency_name: 'FirmaChain',
    currency_symbol: 'FCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4b317864a05c91225ab8f401ec7be0aeb87e9c12',
    currency_name: 'BingoCoin',
    currency_symbol: 'BOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9a49f02e128a8e989b443a8f94843c0918bf45e7',
    currency_name: 'TOKOK',
    currency_symbol: 'TOK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000192',
    currency_name: 'Galilel',
    currency_symbol: 'GALI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd99b8a7fa48e25cce83b81812220a3e03bf64e5f',
    currency_name: 'Skrumble Network',
    currency_symbol: 'SKM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe477292f1b3268687a29376116b0ed27a9c76170',
    currency_name: 'HEROcoin',
    currency_symbol: 'PLAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4fe327c5a809fa721d47b80c5038a0b393e61305',
    currency_name: 'IOTW',
    currency_symbol: 'IOTW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007c3',
    currency_name: 'EtherGem',
    currency_symbol: 'EGEM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001bf',
    currency_name: 'Dinero',
    currency_symbol: 'DIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4d0425e47ee2d16b94c036715dfcb52a0cebc4dc',
    currency_name: 'TAGZ5',
    currency_symbol: 'TAGZ5',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000012',
    currency_name: 'Digitalcoin',
    currency_symbol: 'DGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfe5f141bf94fe84bc28ded0ab966c16b17490657',
    currency_name: 'Cred',
    currency_symbol: 'LBA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd4f6f9ae14399fd5eb8dfc7725f0094a1a7f5d80',
    currency_name: 'Bitsten Token',
    currency_symbol: 'BST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2ef52Ed7De8c5ce03a4eF0efbe9B7450F2D7Edc9',
    currency_name: 'Revain',
    currency_symbol: 'REV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa6446d655a0c34bc4f05042ee88170d056cbaf45',
    currency_name: 'Caspian',
    currency_symbol: 'CSP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000069',
    currency_name: 'Stratis',
    currency_symbol: 'STRAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x737fa0372c8d001904ae6acaf0552d4015f9c947',
    currency_name: 'MediBit',
    currency_symbol: 'MEDIBIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbf52f2ab39e26e0951d2a02b49b7702abe30406a',
    currency_name: 'ODEM',
    currency_symbol: 'ODE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8a732bc91c33c167f868e0af7e6f31e0776d0f71',
    currency_name: 'LitecoinToken',
    currency_symbol: 'LTK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3277dd536471a3cBEB0c9486aCad494C95A31E73',
    currency_name: 'CoinHe Token',
    currency_symbol: 'CHT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13e9ec660d872f55405d70e5c52d872136f0970c',
    currency_name: 'Twinkle',
    currency_symbol: 'TKT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x37e1160184f7dd29f00b78c050bf13224780b0b0',
    currency_name: 'Yuan Chain Coin',
    currency_symbol: 'YCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB119Ce94D098C18fe380904c24e358bd887F00BE',
    currency_name: 'MACH Project',
    currency_symbol: 'MACH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x31Bb711de2e457066c6281f231fb473FC5c2afd3',
    currency_name: 'Vether',
    currency_symbol: 'VETH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.PLG-D8D',
    currency_name: 'Pledge Coin',
    currency_symbol: 'PLG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4575f41308EC1483f3d399aa9a2826d74Da13Deb',
    currency_name: 'Orchid',
    currency_symbol: 'OXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.de2ed49b691e76754c20fe619d891b78ef58e537',
    currency_name: 'Travala.com',
    currency_symbol: 'AVA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x72c9fb7ed19d3ce51cea5c56b3e023cd918baadf',
    currency_name: 'Agrolot',
    currency_symbol: 'AGLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xC0Eb85285d83217CD7c891702bcbC0FC401E2D9D',
    currency_name: 'Hiveterminal Token',
    currency_symbol: 'HVN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xac3211a5025414af2866ff09c23fc18bc97e79b1',
    currency_name: 'Dovu',
    currency_symbol: 'DOV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000da',
    currency_name: 'Bitcloud',
    currency_symbol: 'BTDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcbcc0f036ed4788f63fc0fee32873d6a7487b908',
    currency_name: 'Humaniq',
    currency_symbol: 'HMQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb19cvhgyrxmkw30hlqs9c5lp966drjzyylytl74z',
    currency_name: 'WazirX',
    currency_symbol: 'WRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6aeb95f06cda84ca345c2de0f3b7f96923a44f4c',
    currency_name: 'Rentberry',
    currency_symbol: 'BERRY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x36c85687eeDAe01C50eB7d04d74C0ec74f930c54',
    currency_name: 'Destiny Success',
    currency_symbol: 'DXTS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x45f2ab0ca2116b2e1a70bf5e13293947b25d0272',
    currency_name: 'Global Reserve System',
    currency_symbol: 'GLOB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6b74dd5d01f8320081247f5cf1f7a48324700db6',
    currency_name: 'UBU',
    currency_symbol: 'UBU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5e6b6d9abad9093fdc861ea1600eba1b355cd940',
    currency_name: 'IoT Chain',
    currency_symbol: 'ITC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332.0x5db3c8a942333f6468176a870db36eef120a34dc',
    currency_name: 'Safe Haven',
    currency_symbol: 'SHA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x037a54aab062628c9bbae1fdb1583c195585fe41',
    currency_name: 'LCX',
    currency_symbol: 'LCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.6fad54d8cc692fc808fd97a207836a846c217705',
    currency_name: 'Alchemint Standards',
    currency_symbol: 'SDS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x58c69ed6cd6887c0225d1fccecc055127843c69b',
    currency_name: 'Qitmeer',
    currency_symbol: 'HLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa44e5137293e855b1b7bc7e2c6f8cd796ffcb037',
    currency_name: 'Sentinel',
    currency_symbol: 'SENT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f72714b35a366285df85886a2ee174601292a17',
    currency_name: '1SG',
    currency_symbol: '1SG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000ee',
    currency_name: 'Quantum Resistant Ledger',
    currency_symbol: 'QRL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xae746520ffdb15d0505e32f1d6e9a2b4ab866572',
    currency_name: 'MorCrypto Coin',
    currency_symbol: 'MOR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x59061b6f26BB4A9cE5828A19d35CFD5A4B80F056',
    currency_name: 'More Coin',
    currency_symbol: 'MORE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x00e150d741eda1d49d341189cae4c08a73a49c95',
    currency_name: 'Infinitus Token',
    currency_symbol: 'INF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007c0',
    currency_name: 'TurtleCoin',
    currency_symbol: 'TRTL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xef51c9377feb29856e61625caf9390bd0b67ea18',
    currency_name: 'Bionic',
    currency_symbol: 'BNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xD8912C10681D8B21Fd3742244f44658dBA12264E',
    currency_name: 'Pluton',
    currency_symbol: 'PLU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.DREP-7D2',
    currency_name: 'DREP',
    currency_symbol: 'DREP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.a721d5893480260bd28ca1f395f2c465d0b5b1c2',
    currency_name: 'Narrative',
    currency_symbol: 'NRVE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe431a4c5db8b73c773e06cf2587da1eb53c41373',
    currency_name: 'Trias',
    currency_symbol: 'TRY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc39e626a04c5971d770e319760d7926502975e47',
    currency_name: 'PayBX',
    currency_symbol: 'AXPR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.0x7bcb8bb59298ba3aed51a6a0348187ba4ffd1c69',
    currency_name: 'OG Fan Token',
    currency_symbol: 'OG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdac17f958d2ee523a2206206994597c13d831ec7',
    currency_name: 'Tether',
    currency_symbol: 'USDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000215',
    currency_name: 'Project Coin',
    currency_symbol: 'PRJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6096d2460cf5177e40b515223428dc005ad35123',
    currency_name: 'Precium',
    currency_symbol: 'PCM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x006bea43baa3f7a6f765f14f10a1a1b08334ef45',
    currency_name: 'Stox',
    currency_symbol: 'STX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc45dbdf28844fdb1482c502897d433ac08d6ccd0',
    currency_name: 'BitNautic Token',
    currency_symbol: 'BTNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.OCT',
    currency_name: 'OracleChain',
    currency_symbol: 'OCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000180',
    currency_name: 'Stakenet',
    currency_symbol: 'XSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe947b388fbe682784170b62f2bd4665f9719a285',
    currency_name: 'Rally',
    currency_symbol: 'RALLY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa5fd1a791c4dfcaacc963d4f73c6ae5824149ea7',
    currency_name: 'Jibrel Network',
    currency_symbol: 'JNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe54b3458c47e44c37a267e7c633afef88287c294',
    currency_name: 'Artfinity',
    currency_symbol: 'AT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeb6985acd6d0cbff60b88032b0b29ac1d9d66a1b',
    currency_name: 'Bitbook Gambling',
    currency_symbol: 'BXK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb9eefc4b0d472a44be93970254df4f4016569d27',
    currency_name: 'DigitalBits',
    currency_symbol: 'XDB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc3e2de0b661cf58f66bde8e896905399ded58af5',
    currency_name: 'Matrexcoin',
    currency_symbol: 'MAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004a.hxf75bfd0df8d96ee0963965135af2485cee6d5000',
    currency_name: 'weBloc',
    currency_symbol: 'WOK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.c875e3e1a9f7ba1291aea40cead371754dfcb3de',
    currency_name: 'ChronoCoin',
    currency_symbol: 'CRN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.0x14a5750b0e54b57d12767b84a326c9fe59472da5',
    currency_name: 'Juventus Fan Token',
    currency_symbol: 'JUV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.RUNE-B1A',
    currency_name: 'THORChain',
    currency_symbol: 'RUNE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x063b98a414eaa1d4a5d4fc235a22db1427199024',
    currency_name: 'VOMER',
    currency_symbol: 'VMR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfca59cd816ab1ead66534d82bc21e7515ce441cf',
    currency_name: 'Rarible',
    currency_symbol: 'RARI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf70d160102cf7a22c1e432d6928a9d625db91170',
    currency_name: 'Kuverit',
    currency_symbol: 'KUV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xed0849BF46CfB9845a2d900A0A4E593F2dD3673c',
    currency_name: 'Saga',
    currency_symbol: 'SGA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000129',
    currency_name: 'Massnet',
    currency_symbol: 'MASS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2730d6fdc86c95a74253beffaa8306b40fedecbb',
    currency_name: 'UNICORN Token',
    currency_symbol: 'UNI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a4743cf1af4c289351390a2b3fe7c13d2f7c235',
    currency_name: 'Castweet',
    currency_symbol: 'CTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c8',
    currency_name: 'Omni',
    currency_symbol: 'OMNI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000172',
    currency_name: 'Vidulum',
    currency_symbol: 'VDL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4a73e60adbb8575500ffc6aaea6128954011c8af',
    currency_name: 'DOCH COIN',
    currency_symbol: 'DCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c974b2d0ba1716e644c1fc59982a89ddd2ff724',
    currency_name: 'Viberate',
    currency_symbol: 'VIB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x91E84EC6101547C1FA39Dd565dd8b020E3c20CF2',
    currency_name: 'Cannadrix',
    currency_symbol: 'CND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.31',
    currency_name: 'Tether',
    currency_symbol: 'USDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x813b428af3920226e059b68a62e4c04933d4ea7a',
    currency_name: 'Decentralized Asset Trading Platform',
    currency_symbol: 'DATP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa110eeebc0751407bdcaea4cd230f04a2b82a33a',
    currency_name: 'GoWithMi',
    currency_symbol: 'GMAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.VNDC-DB9',
    currency_name: 'VNDC',
    currency_symbol: 'VNDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2f8472dd7ecf7ca760c8f6b45db20ca7cf52f8d7',
    currency_name: 'BitStation',
    currency_symbol: 'BSTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x37e8789bb9996cac9156cd5f5fd32599e6b91289',
    currency_name: 'AidCoin',
    currency_symbol: 'AID',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xef8cf79c21637fc6f951bcac348915508a639a41',
    currency_name: 'SRCOIN',
    currency_symbol: 'SRCOIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x23352036e911a22cfc692b5e2e196692658aded9',
    currency_name: 'Friendz',
    currency_symbol: 'FDZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2baac9330cf9ac479d819195794d79ad0c7616e3',
    currency_name: 'adbank',
    currency_symbol: 'ADB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf784682c82526e245f50975190ef0fff4e4fc077',
    currency_name: 'INLOCK',
    currency_symbol: 'ILK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6f259637dcd74c767781e37bc6133cd6a68aa161',
    currency_name: 'Huobi Token',
    currency_symbol: 'HT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa0b207103f764a920b4af9e691f5bd956de14ded',
    currency_name: 'Abitshadow Token',
    currency_symbol: 'ABST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000008c',
    currency_name: 'LBRY Credits',
    currency_symbol: 'LBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4cb10f4df4bf4f64d4797d00d468181ef731be9a',
    currency_name: 'Dimension Chain',
    currency_symbol: 'EON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd91a6162f146ef85922d9a15ee6eb14a00344586',
    currency_name: 'Sessia',
    currency_symbol: 'KICKS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe3818504c1b32bf1557b16c238b2e01fd3149c17',
    currency_name: 'Pillar',
    currency_symbol: 'PLR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.RPD-9E0',
    currency_name: 'Rapids',
    currency_symbol: 'RPD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x720c2c93F5f9A6b82226e84095558B10F399b0FA',
    currency_name: 'Gric Coin',
    currency_symbol: 'GC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7D1AfA7B718fb893dB30A3aBc0Cfc608AaCfeBB0',
    currency_name: 'Matic Network',
    currency_symbol: 'MATIC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb5dbc6d3cf380079df3b27135664b6bcf45d1869',
    currency_name: 'Shivom',
    currency_symbol: 'OMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9205c049c231dda51bace0ba569f047e3e1e9979',
    currency_name: 'Latamcash',
    currency_symbol: 'LMCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7654915a1b82d6d2d0afc37c52af556ea8983c7e',
    currency_name: 'InvestFeed',
    currency_symbol: 'IFT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd28cfec79db8d0a225767d06140aee280718ab7e',
    currency_name: 'BIZKEY',
    currency_symbol: 'BZKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x469eda64aed3a3ad6f868c44564291aa415cb1d9',
    currency_name: 'FLUX',
    currency_symbol: 'FLUX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80579bfc',
    currency_name: 'Waves',
    currency_symbol: 'WAVES',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa8eda9d4aee0eb882f8752c6ba7e16d9233c9ad2',
    currency_name: 'LevelApp Token',
    currency_symbol: 'LVL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5fbdb42bb048c685c990a37f2c87fe087c586655',
    currency_name: 'Xenon',
    currency_symbol: 'XEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8400d94a5cb0fa0d041a3788e395285d61c9ee5e',
    currency_name: 'Unibright',
    currency_symbol: 'UBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000091.3f83fa9f168f01d68933ef5fdb77143b2376ba7bf3a78175258861982d90d500',
    currency_name: 'GoCrypto Token',
    currency_symbol: 'GOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5b71bee9d961b1b848f8485eec8d8787f80217f5',
    currency_name: 'BitForex Token',
    currency_symbol: 'BF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.LIT-099',
    currency_name: 'Lition',
    currency_symbol: 'LIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x74fd51a98a4a1ecbef8cc43be801cce630e260bd',
    currency_name: 'SiaCashCoin',
    currency_symbol: 'SCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x23ccc43365d9dd3882eab88f43d515208f832430',
    currency_name: 'MidasProtocol',
    currency_symbol: 'MAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba5160ba66b286f1b9b6f3a57a6abcfa440e7aa2',
    currency_name: 'Hubi Token',
    currency_symbol: 'HUB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5eb87caa0105a63aa87a36c7bd2573bd13e84fae',
    currency_name: 'Blockchain Quotations Index Token',
    currency_symbol: 'BQT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000009b',
    currency_name: 'WhiteCoin',
    currency_symbol: 'XWC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b4e2b4b13d125238aa0480dd42b4f6fc71b37cc',
    currency_name: 'MyToken',
    currency_symbol: 'MT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3FFfFA8F3cc943e43f9f17a83Cbb18f4Bbb9f4AC',
    currency_name: 'Rewardiqa',
    currency_symbol: 'REW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xE02784175C3BE0DEa7CC0F284041b64503639E66',
    currency_name: 'TouchCon',
    currency_symbol: 'TOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TVnRbEPaMKZzFm1JSBvDSmCXcWJVHzXGG5',
    currency_name: 'INRToken',
    currency_symbol: 'INRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeaD7F3ae4e0Bb0D8785852Cc37CC9d0B5e75c06a',
    currency_name: '3x Long EOS Token',
    currency_symbol: 'EOSBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf2c7238198ad8b389666574f2d8bc411a4b7428',
    currency_name: 'Mainframe',
    currency_symbol: 'MFT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000ae',
    currency_name: 'Onix',
    currency_symbol: 'ONX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd9485499499d66b175cf5ed54c0a19f1a6bcb61a',
    currency_name: 'Usechain Token',
    currency_symbol: 'USE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf4fe95603881d0e07954fd7605e0e9a916e42c44',
    currency_name: 'WHEN Token',
    currency_symbol: 'WHEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4eE6E959d460dE47DfE58E5E6fBAB330Ce8484b6',
    currency_name: 'QURA GLOBAL',
    currency_symbol: 'QURA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80004ae1',
    currency_name: 'Ritocoin',
    currency_symbol: 'RITO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd50649aab1d39d68bc965e0f6d1cfe0010e4908b',
    currency_name: 'T.OS',
    currency_symbol: 'TOSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7ba19b7f7d106a9a1e0985397b94f38eee0b555e',
    currency_name: 'Wixlar',
    currency_symbol: 'WIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x61fd1c62551850d0c04c76fce614cbced0094498',
    currency_name: 'IDK',
    currency_symbol: 'IDK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x48ff53777f747cfb694101222a944de070c15d36',
    currency_name: 'Ether Kingdoms Token',
    currency_symbol: 'IMP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8713d26637cf49e1b6b4a7ce57106aabc9325343',
    currency_name: 'Content Neutrality Network',
    currency_symbol: 'CNN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5C406D99E04B8494dc253FCc52943Ef82bcA7D75',
    currency_name: 'cUSD Currency',
    currency_symbol: 'CUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xda6cb58a0d0c01610a29c5a65c303e13e885887c',
    currency_name: 'carVertical',
    currency_symbol: 'CV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8810c63470d38639954c6b41aac545848c46484a',
    currency_name: 'Aditus',
    currency_symbol: 'ADI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc3761eb917cd790b30dad99f6cc5b4ff93c4f9ea',
    currency_name: 'ERC20',
    currency_symbol: 'ERC20',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000109',
    currency_name: 'TokenPay',
    currency_symbol: 'TPAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc12d099be31567add4e4e4d0d45691c3f58f5663',
    currency_name: 'Auctus',
    currency_symbol: 'AUC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0ee11500e7cfe6124e3716cd7755a0f0f2116244',
    currency_name: 'WXCOINS',
    currency_symbol: 'WXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xae353daeed8dcc7a9a12027f7e070c0a50b7b6a4',
    currency_name: 'InnovaMinex',
    currency_symbol: 'MINX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x24fb4c36a83cbdbcd670856406f622e09a643d4d',
    currency_name: 'Hinto',
    currency_symbol: 'HNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.a58b56b30425d3d1f8902034996fcac4168ef71d',
    currency_name: 'Asura Coin',
    currency_symbol: 'ASA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000019f',
    currency_name: 'Electroneum',
    currency_symbol: 'ETN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb9843e5de0f37d1e22c8075e5814e13565fe7c22',
    currency_name: 'Lucky Block Network',
    currency_symbol: 'LBN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2ba6b1e4424e19816382d15937739959f7da5fd8',
    currency_name: 'MEX',
    currency_symbol: 'MEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c62da804298d5972a323c80b539b8e7517a0dde',
    currency_name: 'VENJOCOIN',
    currency_symbol: 'VJC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x42726d074BBa68Ccc15200442B72Afa2D495A783',
    currency_name: 'Isiklar Coin',
    currency_symbol: 'ISIKC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000fe',
    currency_name: 'BlockStamp',
    currency_symbol: 'BST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x04Fa0d235C4abf4BcF4787aF4CF447DE572eF828',
    currency_name: 'UMA',
    currency_symbol: 'UMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x58959e0c71080434f237bd42d07cd84b74cef438',
    currency_name: 'Tesra',
    currency_symbol: 'TSR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe8780b48bdb05f928697a5e8155f672ed91462f7',
    currency_name: 'Cashaa',
    currency_symbol: 'CAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000ce10',
    currency_name: 'Celo',
    currency_symbol: 'CGLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.4060e21ac01b5c5d2a3f01cecd7cbf820f50be95',
    currency_name: 'Profile Utility Token',
    currency_symbol: 'PUT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0bc61dded5f6710c637cf8288eb6058766ce1921',
    currency_name: 'Coinsuper Ecosystem Network',
    currency_symbol: 'CEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x672a1ad4f667fb18a333af13667aa0af1f5b5bdd',
    currency_name: 'Verify',
    currency_symbol: 'CRED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa982b2e19e90b2d9f7948e9c1b65d119f1ce88d6',
    currency_name: 'WOM Protocol',
    currency_symbol: 'WOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001c2',
    currency_name: 'Solaris',
    currency_symbol: 'XLR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6f6d15e2dabd182c7c0830db1bdff1f920b57ffa',
    currency_name: 'ODE',
    currency_symbol: 'ODE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x446c9033e7516d820cc9a2ce2d0b7328b579406f',
    currency_name: 'SOLVE',
    currency_symbol: 'SOLVE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x39aa39c021dfbae8fac545936693ac917d5e7563',
    currency_name: 'Compound USD Coin',
    currency_symbol: 'CUSDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.BTC',
    currency_name: 'bitBTC',
    currency_symbol: 'BITBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x624d520bab2e4ad83935fa503fb130614374e850',
    currency_name: 'Smartshare',
    currency_symbol: 'SSP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb7cb1c96db6b22b0d3d9536e0108d062bd488f74',
    currency_name: 'Waltonchain',
    currency_symbol: 'WTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.LEVELG-GC3L2FLR2QMUSOJ6QMQHKIWUKEXGOXSIN2KY72SDUHPC2KO6HOSQIXIF',
    currency_name: 'LEVELG',
    currency_symbol: 'LEVELG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1fd27f0cfc6f273b87a5e0f6fcf063422e7bcd6a',
    currency_name: 'Agrocoin',
    currency_symbol: 'AGRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.DUSK-45E',
    currency_name: 'Dusk Network',
    currency_symbol: 'DUSK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbd6467a31899590474ce1e84f70594c53d628e46',
    currency_name: 'KardiaChain',
    currency_symbol: 'KAI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x983f6d60db79ea8ca4eb9968c6aff8cfa04b3c63',
    currency_name: 'SONM',
    currency_symbol: 'SNM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000099',
    currency_name: 'Bytom',
    currency_symbol: 'BTM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa86a0da9d05d0771955df05b44ca120661af16de',
    currency_name: 'OTCBTC Token',
    currency_symbol: 'OTB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x11eef04c884e24d9b7b4760e7476d06ddf797f36',
    currency_name: 'MX Token',
    currency_symbol: 'MX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000e8',
    currency_name: 'TrezarCoin',
    currency_symbol: 'TZC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6fb3e0a217407efff7ca062d46c26e5d60a14d69',
    currency_name: 'IoTeX',
    currency_symbol: 'IOTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf278c1ca969095ffddded020290cf8b5c424ace2',
    currency_name: 'Ruff',
    currency_symbol: 'RUFF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x21d5a14e625d767ce6b7a167491c2d18e0785fda',
    currency_name: 'Jinbi Token',
    currency_symbol: 'JNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80004172',
    currency_name: 'Ardor',
    currency_symbol: 'ARDR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000018e',
    currency_name: 'eXPerience Chain',
    currency_symbol: 'XPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8a88f04e0c905054d2f33b26bb3a46d7091a039a',
    currency_name: 'IGToken',
    currency_symbol: 'IG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8d8129963291740dddd917ab01af18c7aed4ba58',
    currency_name: 'MineBee',
    currency_symbol: 'MB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x40d52577830e01aaefa80659aa90ee8b34685f4e',
    currency_name: 'Bilaxy Token',
    currency_symbol: 'BIA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x701c244b988a513c945973defa05de933b23fe1d',
    currency_name: 'OAX',
    currency_symbol: 'OAX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x55f93985431fc9304077687a35a1ba103dc1e081',
    currency_name: 'SmartMesh',
    currency_symbol: 'SMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x40284109c3309a7c3439111bfd93bf5e0fbb706c',
    currency_name: 'MOTIV Protocol',
    currency_symbol: 'MOV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xddd460bbd9f79847ea08681563e8a9696867210c',
    currency_name: 'Spendcoin',
    currency_symbol: 'SPND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0e0989b1f9b8a38983c2ba8053269ca62ec9b195',
    currency_name: 'Po.et',
    currency_symbol: 'POE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd2d6158683aee4cc838067727209a0aaf4359de3',
    currency_name: 'Bounty0x',
    currency_symbol: 'BNTY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3affcca64c2a6f4e3b6bd9c64cd2c969efd1ecbe',
    currency_name: 'DSLA Protocol',
    currency_symbol: 'DSLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004b',
    currency_name: 'FujiCoin',
    currency_symbol: 'FJC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba2184520a1cc49a6159c57e61e1844e085615b6',
    currency_name: 'HelloGold',
    currency_symbol: 'HGT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2bdc0d42996017fce214b21607a515da41a9e0c5',
    currency_name: 'SkinCoin',
    currency_symbol: 'SKIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcc4304a31d09258b0029ea7fe63d032f52e44efe',
    currency_name: 'TrustSwap',
    currency_symbol: 'SWAP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.323571cfc42a40d48d64832a7da594039fbac76a',
    currency_name: 'STEM CELL COIN',
    currency_symbol: 'SCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc80c5e40220172b36adee2c951f26f2a577810c5',
    currency_name: 'Bankera',
    currency_symbol: 'BNK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x06a8f2bcc622ac55d596ea02ce5bb5f318f485e9',
    currency_name: 'MoCo Token',
    currency_symbol: 'MoCo',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x923108a439c4e8c2315c4f6521e5ce95b44e9b4c',
    currency_name: 'Devery',
    currency_symbol: 'EVE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xF1290473E210b2108A85237fbCd7b6eb42Cc654F',
    currency_name: 'HedgeTrade',
    currency_symbol: 'HEDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000ec',
    currency_name: 'Bitcoin SV',
    currency_symbol: 'BSV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd42debe4edc92bd5a3fbb4243e1eccf6d63a4a5d',
    currency_name: 'Carboneum [C8] Token',
    currency_symbol: 'C8',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb3BACe433288645114FE8e8aA91F87659CBF665b',
    currency_name: 'Wallet Plus X',
    currency_symbol: 'WPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xE814aeE960a85208C3dB542C53E7D4a6C8D5f60F',
    currency_name: 'Chronologic',
    currency_symbol: 'DAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x765f0c16d1ddc279295c1a7c24b0883f62d33f75',
    currency_name: 'Databroker',
    currency_symbol: 'DTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xC7e43A1c8E118aA2965F5EAbe0e718D83DB7A63C',
    currency_name: 'ZCore Token',
    currency_symbol: 'ZCRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6a27348483d59150ae76ef4c0f3622a78b0ca698',
    currency_name: 'BeeKan',
    currency_symbol: 'BKBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb37a769b37224449d92aac57de379e1267cd3b00',
    currency_name: 'COVA',
    currency_symbol: 'COVA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.132947096727c84c7f9e076c90f08fec3bc17f18',
    currency_name: 'THEKEY',
    currency_symbol: 'TKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x445f51299ef3307dbd75036dd896565f5b4bf7a5',
    currency_name: 'V-ID',
    currency_symbol: 'VIDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6393e822874728f8afa7e1c9944e417d37ca5878',
    currency_name: 'Cryptoindex.com 100',
    currency_symbol: 'CIX100',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000025',
    currency_name: 'Pandacoin',
    currency_symbol: 'PND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000059',
    currency_name: 'Bata',
    currency_symbol: 'BTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70da48f4b7e83c386ef983d4cef4e58c2c09d8ac',
    currency_name: 'QURAS',
    currency_symbol: 'XQC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf0bc1ae4ef7ffb126a8347d06ac6f8add770e1ce',
    currency_name: '1Million Token',
    currency_symbol: '1MT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0abdace70d3790235af448c88547603b945604ea',
    currency_name: 'district0x',
    currency_symbol: 'DNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800003e8',
    currency_name: 'BitNewChain',
    currency_symbol: 'BTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x56be94d29e1125d2d61d06629c1b251d72c1b3b3',
    currency_name: 'The Hustle App',
    currency_symbol: 'HUSL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x946ea588417ffa565976efda354d82c01719a2ea',
    currency_name: 'Swapzilla',
    currency_symbol: 'SWZL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x95a41fB80ca70306e9Ecf4e51ceA31bD18379C18',
    currency_name: 'Aladdin',
    currency_symbol: 'ADN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5f53f7a8075614b699baad0bc2c899f4bad8fbbf',
    currency_name: 'REBL',
    currency_symbol: 'REBL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9ccbd05d4d25c745d49f5e6bf17e09113eb4c769',
    currency_name: 'Decentralized Crypto Token',
    currency_symbol: 'DCTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x607f4c5bb672230e8672085532f7e901544a7375',
    currency_name: 'iExec RLC',
    currency_symbol: 'RLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcDd0A6B15B49A9eb3Ce011CCE22FAc2ccf09ecE6',
    currency_name: 'ARMTOKEN',
    currency_symbol: 'TARM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.1002984',
    currency_name: 'ZIMBOCASH',
    currency_symbol: 'ZASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TCFLL5dx5ZJdKnWuesXxi1VPwjLVmWZZy9',
    currency_name: 'JUST',
    currency_symbol: 'JST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000053',
    currency_name: 'Terracoin',
    currency_symbol: 'TRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1410434b0346f5be678d0fb554e5c7ab620f8f4a',
    currency_name: 'BitKan',
    currency_symbol: 'KAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa8262eb913fccea4c3f77fc95b8b4043b384cfbb',
    currency_name: 'Krypton Galaxy Coin',
    currency_symbol: 'KGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x93065b5c7eb63333b8e57a73012d25f687895785',
    currency_name: 'Colletrix',
    currency_symbol: 'CIPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1f88B2eF9ea3B29C90F559e645fC8CC2dc6F5b27',
    currency_name: 'GoMoney',
    currency_symbol: 'GOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3',
    currency_name: 'TRON',
    currency_symbol: 'TRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5e7ebea68ab05198f771d77a875480314f1d0aae',
    currency_name: 'DABANKING',
    currency_symbol: 'DAB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5228a22e72ccc52d415ecfd199f99d0665e7733b',
    currency_name: 'pTokens BTC',
    currency_symbol: 'PBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x72DC3D52b7EF107a7CFFb6953eaa8A2aD6a204Cd',
    currency_name: 'ChainZ Arena',
    currency_symbol: 'SOUL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc343f099d3e41aa5c1b59470450e21e92e2d840b',
    currency_name: 'TrueVett',
    currency_symbol: 'VME',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa74476443119A942dE498590Fe1f2454d7D4aC0d',
    currency_name: 'Golem',
    currency_symbol: 'GNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x796e47b85a0d759f300f1de96a3583004235d4d8',
    currency_name: 'Electrum Dark',
    currency_symbol: 'ELD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd26114cd6EE289AccF82350c8d8487fedB8A0C07',
    currency_name: 'OmiseGO',
    currency_symbol: 'OMG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2bba3cf6de6058cc1b4457ce00deb359e2703d7f',
    currency_name: 'HashCoin',
    currency_symbol: 'HSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.ptitokenhome',
    currency_name: 'Paytomat',
    currency_symbol: 'PTI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7fe92ec600f15cd25253b421bc151c51b0276b7d',
    currency_name: 'Japan Content Token',
    currency_symbol: 'JCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4a42d2c580f83dce404acad18dab26db11a1750e',
    currency_name: 'Relex',
    currency_symbol: 'RLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb6ee9668771a79be7967ee29a63d4184f8097143',
    currency_name: 'CargoX',
    currency_symbol: 'CXO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4355fc160f74328f9b383df2ec589bb3dfd82ba0',
    currency_name: 'Opus',
    currency_symbol: 'OPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.a87cc2a513f5d8b4a42432343687c2127c60bc3f',
    currency_name: 'Master Contract Token',
    currency_symbol: 'MCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xBA5F11b16B155792Cf3B2E6880E8706859A8AEB6',
    currency_name: 'Aeron',
    currency_symbol: 'ARN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9064c91e51d7021A85AD96817e1432aBf6624470',
    currency_name: 'ShineChain',
    currency_symbol: 'SHE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x39e743fee400a5d9b36f1167b70c10e8f06440e5',
    currency_name: 'TNC Coin',
    currency_symbol: 'TNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x82070415fee803f94ce5617be1878503e58f0a6a',
    currency_name: 'Vision Network',
    currency_symbol: 'VSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.GTO-908',
    currency_name: 'Gifto',
    currency_symbol: 'GTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xDB61354E9cf2217a29770E9811832B360a8DAad3',
    currency_name: '3x Long Litecoin Token',
    currency_symbol: 'LTCBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa8258abc8f2811dd48eccd209db68f25e3e34667',
    currency_name: 'Constellation',
    currency_symbol: 'DAG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ANKR-E97',
    currency_name: 'Ankr',
    currency_symbol: 'ANKR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x567300e14f8d67e1f6720a95291dce2511a86723',
    currency_name: 'Helper Search Token',
    currency_symbol: 'HSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a9',
    currency_name: 'BUZZCoin',
    currency_symbol: 'BUZZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002b.prx',
    currency_name: 'ProximaX',
    currency_symbol: 'XPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BULL-BE4',
    currency_name: '3X Long Bitcoin Token',
    currency_symbol: 'BULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000012d',
    currency_name: 'Divi',
    currency_symbol: 'DIVI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001b3',
    currency_name: 'PeepCoin',
    currency_symbol: 'PCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd46ba6d942050d489dbd938a2c909a5d5039a161',
    currency_name: 'Ampleforth',
    currency_symbol: 'AMPL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6c6ee5e31d828de241282b9606c8e98ea48526e2',
    currency_name: 'Holo',
    currency_symbol: 'HOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GASBLVHS5FOABSDNW5SPPH3QRJYXY5JHA2AOA2QHH2FJLZBRXSG4SWXT',
    currency_name: 'Wirex Token',
    currency_symbol: 'WXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xAb418c5A8a85f9cC0fbA1802a57a30Bf7dB34d7C',
    currency_name: 'Benscoin',
    currency_symbol: 'BSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MBL-2D2',
    currency_name: 'MovieBloc',
    currency_symbol: 'MBL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000126',
    currency_name: 'Bitcoin CZ',
    currency_symbol: 'BCZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000ef',
    currency_name: 'ChainX',
    currency_symbol: 'PCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeEEE2a622330E6d2036691e983DEe87330588603',
    currency_name: 'Askobar Network',
    currency_symbol: 'ASKO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26e75307fc0c021472feb8f727839531f112f317',
    currency_name: 'CRYPTO20',
    currency_symbol: 'C20',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x34d6a0f5c2f5d0082141fe73d93b9dd00ca7ce11',
    currency_name: 'Golden Token',
    currency_symbol: 'GOLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe8a1df958be379045e2b46a31a98b93a2ecdfded',
    currency_name: 'EtherSportz',
    currency_symbol: 'ESZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x44a67c8570a61a28bafd0035042f2f0a73a64428',
    currency_name: 'GermanCoin',
    currency_symbol: 'GCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001c9',
    currency_name: 'Aeternity',
    currency_symbol: 'AE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2f9b6779c37df5707249eeb3734bbfc94763fbe2',
    currency_name: 'CrowdWiz',
    currency_symbol: 'WIZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfef3884b603c33ef8ed4183346e093a173c94da6',
    currency_name: 'MetaMorph',
    currency_symbol: 'METM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf9e5af7b42d31d51677c75bbbd37c1986ec79aee',
    currency_name: 'QuickX Protocol',
    currency_symbol: 'QCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5f3789907b35dce5605b00c0be0a7ecdbfa8a841',
    currency_name: 'Content and AD Network',
    currency_symbol: 'CAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xde4c5a791913838027a2185709e98c5c6027ea63',
    currency_name: 'General Attention Currency',
    currency_symbol: 'XAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x27054b13b1b798b345b591a4d22e6562d47ea75a',
    currency_name: 'AirSwap',
    currency_symbol: 'AST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x171d750d42d661b62c277a6b486adb82348c3eca',
    currency_name: 'Omnitude',
    currency_symbol: 'ECOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x85e076361cc813a908ff672f9bad1541474402b2',
    currency_name: 'Telcoin',
    currency_symbol: 'TEL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6531f133e6deebe7f2dce5a0441aa7ef330b4e53',
    currency_name: 'Chrono.tech',
    currency_symbol: 'TIME',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3597bfd533a99c9aa083587b074434e61eb0a258',
    currency_name: 'Dent',
    currency_symbol: 'DENT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x225927f8fa71d16ee07968b8746364d1d9f839bd',
    currency_name: 'Bitcoin Fast',
    currency_symbol: 'BTCF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0488401c3f535193fa8df029d9ffe615a06e74e6',
    currency_name: 'Sparkpoint',
    currency_symbol: 'SRK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f235d23354857efe6c541db92a9ef1877689bcb',
    currency_name: 'BOLT',
    currency_symbol: 'BOLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb8ddc930c2bab6c71610a2be639036e829f9c10b',
    currency_name: 'KWHCoin',
    currency_symbol: 'KWH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800005fd',
    currency_name: 'Beam',
    currency_symbol: 'BEAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x705EE96c1c160842C92c1aeCfCFfccc9C412e3D9',
    currency_name: 'ClearPoll',
    currency_symbol: 'POLL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x89ab32156e46f46d02ade3fecbe5fc4243b9aaed',
    currency_name: 'pNetwork',
    currency_symbol: 'PNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d51fcced3114a8bb5e90cdd0f9d682bcbcc5393',
    currency_name: 'B2BX',
    currency_symbol: 'B2B',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0Ae055097C6d159879521C384F1D2123D1f195e6',
    currency_name: 'xDai',
    currency_symbol: 'STAKE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3f038991d7359daaeb8290483e6cd7d7bd6a6df2',
    currency_name: 'Work Force Coin',
    currency_symbol: 'WFC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000019d',
    currency_name: 'Masari',
    currency_symbol: 'MSR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000006c',
    currency_name: 'Ubiq',
    currency_symbol: 'UBQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b7',
    currency_name: 'Bitcoin Private',
    currency_symbol: 'BTCP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26DB5439F651CAF491A87d48799dA81F191bDB6b',
    currency_name: 'CashBet Coin',
    currency_symbol: 'CBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GAMV4COOMALXHQJ673E4ME2JIUNQ75YXYRWS4FCIJYLCRPRLSZFSPQFJ',
    currency_name: 'CCUniverse',
    currency_symbol: 'UVU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6c2adc2073994fb2ccc5032cc2906fa221e9b391',
    currency_name: 'Delphy',
    currency_symbol: 'DPY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000009',
    currency_name: 'Counterparty',
    currency_symbol: 'XCP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x49d09cda1deb8a1680f1270c5ed15218fc4b18f0',
    currency_name: 'OVCODE',
    currency_symbol: 'OVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8057414e.0xc6f4465a6A521124c8E3096b62575C157999d361',
    currency_name: 'FinNexus',
    currency_symbol: 'FNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3e65e1eefde5ea7ccfc9a9a1634abe90f32262f8',
    currency_name: 'BaaSid',
    currency_symbol: 'BAAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.TRUE-D84',
    currency_name: 'TrueChain',
    currency_symbol: 'TRUE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13c2b7f851e756415cf7d51d04dcf4f94a5b382e',
    currency_name: 'CITEX Token',
    currency_symbol: 'CTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000008d',
    currency_name: 'Komodo',
    currency_symbol: 'KMD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000717',
    currency_name: 'Cardano',
    currency_symbol: 'ADA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0c963a1b52eb97c5e457c7d76696f8b95c3087ed',
    currency_name: 'Tokoin',
    currency_symbol: 'TOKO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe0c72452740414d861606a44ccd5ea7f96488278',
    currency_name: 'EncryptoTel [ETH]',
    currency_symbol: 'ETT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000394',
    currency_name: 'Metadium',
    currency_symbol: 'META',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.3Z4SBCZ2LRZLuDweUYJkypmjrWkcLuduxpf3Vj8FddSk',
    currency_name: 'SignatureChain ',
    currency_symbol: 'SIGN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x57C75ECCc8557136D32619a191fBCDc88560d711',
    currency_name: 'VeriDocGlobal',
    currency_symbol: 'VDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000135',
    currency_name: 'Nervos Network',
    currency_symbol: 'CKB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x497baef294c11a5f0f5bea3f2adb3073db448b56',
    currency_name: 'DEX',
    currency_symbol: 'DEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x72430a612adc007c50e3b6946dbb1bb0fd3101d1',
    currency_name: 'Thingschain',
    currency_symbol: 'TIC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x37d6e7f287200c740012747d2a79295caed2db35',
    currency_name: 'ALP Coin',
    currency_symbol: 'ALP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x89551b940e2a8ed8eccf509935bac9213fe30584',
    currency_name: 'DoDreamChain',
    currency_symbol: 'DRM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6ce21e5f5383c95691d243879a86a6025e0870c0',
    currency_name: 'Hintchain',
    currency_symbol: 'HINT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000010e',
    currency_name: 'Harmony',
    currency_symbol: 'ONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GDZURZR6RZKIQVOWZFWPVAUBMLLBQGXP2K5E5G7PEOV75IYPDFA36WK4',
    currency_name: 'Atlantis Blue Digital Token',
    currency_symbol: 'ABDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x24ddff6d8b8a42d835af3b440de91f3386554aa4',
    currency_name: 'Iungo',
    currency_symbol: 'ING',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xD38dE88687172BDE440755b5237987e4A87c23A7',
    currency_name: 'Aencoin',
    currency_symbol: 'AEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd0d6d6c5fe4a677d343cc433536bb717bae167dd',
    currency_name: 'adToken',
    currency_symbol: 'ADT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xee4458e052b533b1aabd493b5f8c4d85d7b263dc',
    currency_name: 'Blockpass',
    currency_symbol: 'PASS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4270bb238f6dd8b1c3ca01f96ca65b2647c06d3c',
    currency_name: 'Fortuna',
    currency_symbol: 'FOTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8eFFd494eB698cc399AF6231fCcd39E08fd20B15',
    currency_name: 'Lampix',
    currency_symbol: 'PIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x69b148395ce0015c13e36bffbad63f49ef874e03',
    currency_name: 'DATA',
    currency_symbol: 'DTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4fac0ccd9e2ed9fd462d42b66fb81ba9a1f6f25e',
    currency_name: 'Axial Entertainment Digital Asset',
    currency_symbol: 'AXL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.NEXO-A84',
    currency_name: 'Nexo',
    currency_symbol: 'NEXO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.GCcQTo78M7GTMCKFmn4qULtw79SQfUio1cy8ZX1QjjLi',
    currency_name: 'GM Holding',
    currency_symbol: 'GM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe7775a6e9bcf904eb39da2b68c5efb4f9360e08c',
    currency_name: 'TaaS',
    currency_symbol: 'TAAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x59be937f05cf2c406b61c42c6c82a093fa54edfe',
    currency_name: 'PlayCoin [ERC20]',
    currency_symbol: 'PLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe5caef4af8780e59df925470b050fb23c43ca68c',
    currency_name: 'Ferrum Network',
    currency_symbol: 'FRM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000dc',
    currency_name: 'ANON',
    currency_symbol: 'ANON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.FTM-A64',
    currency_name: 'Fantom',
    currency_symbol: 'FTM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd111BCb8C30a600c12F4AF8314235F628EA2Cb3C',
    currency_name: 'Webflix Token',
    currency_symbol: 'WFX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CPC-FED',
    currency_name: 'CPChain',
    currency_symbol: 'CPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7BD6a4E7DB3A34c485A8DD02b30B6565e3bbC633',
    currency_name: 'Keystone of Opportunity & Knowledge',
    currency_symbol: 'KOK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2e98a6804e4b6c832ed0ca876a943abd3400b224',
    currency_name: 'Bela',
    currency_symbol: 'BELA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4457dc5a9e71b975a8e0f855bbe795f5cbdcc10f',
    currency_name: 'VEHICLE DATA ARTIFICIAL INTELLIGENCE PLATFORM',
    currency_symbol: 'VAIP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x998b3b82bc9dba173990be7afb772788b5acb8bd',
    currency_name: 'Banca',
    currency_symbol: 'BANCA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x179e31fb25e433441a2839389a7b8ec9c4654b7b',
    currency_name: 'SynchroBitcoin',
    currency_symbol: 'SNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x66186008c1050627f979d464eabb258860563dbe',
    currency_name: 'MediShares',
    currency_symbol: 'MDS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c4b7d0e1885bd7667af3378e0c538f74e712006',
    currency_name: 'Playgroundz',
    currency_symbol: 'IOG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000009a',
    currency_name: 'BioCoin',
    currency_symbol: 'BIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x922ac473a3cc241fd3a0049ed14536452d58d73c',
    currency_name: 'Vetri',
    currency_symbol: 'VLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x501262281b2ba043e2fbf14904980689cddb0c78',
    currency_name: 'Mithril Ore',
    currency_symbol: 'MORE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1122b6a0e00dce0563082b6e2953f3a943855c1f',
    currency_name: 'Centrality',
    currency_symbol: 'CENNZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbc647aad10114b89564c0a7aabe542bd0cf2c5af',
    currency_name: 'IONChain',
    currency_symbol: 'IONC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x79cdfa04e3c4eb58c4f49dae78b322e5b0d38788',
    currency_name: 'TrueFeedBack',
    currency_symbol: 'TFB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x37f04d2c3ae075fad5483bb918491f656b12bdb6',
    currency_name: 'VestChain',
    currency_symbol: 'VEST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c3a228510d246b78a3765c20221cbf3082b44a4',
    currency_name: 'Storiqa',
    currency_symbol: 'STQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.ICOO',
    currency_name: 'ICO OpenLedger',
    currency_symbol: 'ICOO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x52a7cb918c11a16958be40cba7e31e32a499a465',
    currency_name: 'FidentiaX',
    currency_symbol: 'FDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x12f649a9e821f90bb143089a6e56846945892ffb',
    currency_name: 'Howdoo',
    currency_symbol: 'UDOO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1b957dc4aefeed3b4a2351a6a6d5cbfbba0cecfa',
    currency_name: 'HOQU',
    currency_symbol: 'HQX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b70740e708a083c6ff38df52297020f5dfaa5ee',
    currency_name: 'Daneel',
    currency_symbol: 'DAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x14c926f2290044b647e1bf2072e67b495eff1905',
    currency_name: 'Bethereum',
    currency_symbol: 'BETHER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000162',
    currency_name: 'Polkadot [IOU]',
    currency_symbol: 'DOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x05860d453c7974cbf46508c06cba14e211c629ce',
    currency_name: 'Eden',
    currency_symbol: 'EDN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8c4e7f814d40f8929f9112c5d09016f923d34472',
    currency_name: 'XcelToken Plus',
    currency_symbol: 'XLAB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8b79656FC38a04044E495e22fAD747126ca305C4',
    currency_name: 'AgaveCoin',
    currency_symbol: 'AGVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbdeb4b83251fb146687fa19d1c660f99411eefe3',
    currency_name: 'savedroid',
    currency_symbol: 'SVD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007e2',
    currency_name: 'EOS Force',
    currency_symbol: 'EOSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfe4455fd433ed3ca025ec7c43cb8686ed89826cd',
    currency_name: 'Moozicore',
    currency_symbol: 'MZG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x28dee01d53fed0edf5f6e310bf8ef9311513ae40',
    currency_name: 'BlitzPredict',
    currency_symbol: 'XBP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000051',
    currency_name: 'PotCoin',
    currency_symbol: 'POT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbdcfbf5c4d91abc0bc9709c7286d00063c0e6f22',
    currency_name: 'Peerguess',
    currency_symbol: 'GUESS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4983f767b1bc44328e434729ddabea0a064ca1ac',
    currency_name: 'Constant',
    currency_symbol: 'CONST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000af',
    currency_name: 'Ravencoin',
    currency_symbol: 'RVN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1822126feedb4c7d61eecdbe3682fe61e91383d6',
    currency_name: 'Xtock',
    currency_symbol: 'XTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x38c87aa89b2b8cd9b95b736e1fa7b612ea972169',
    currency_name: 'AMO Coin',
    currency_symbol: 'AMO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x10086399dd8c1e3de736724af52587a2044c9fa2',
    currency_name: 'The Midas Touch Gold',
    currency_symbol: 'TMTG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb3104b4b9da82025e8b9f8fb28b3553ce2f67069',
    currency_name: 'Bibox Token',
    currency_symbol: 'BIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x90528aeb3a2b736b780fd1b6c478bb7e1d643170',
    currency_name: 'XPA',
    currency_symbol: 'XPA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6bC1F3A1ae56231DbB64d3E82E070857EAe86045',
    currency_name: 'Xensor',
    currency_symbol: 'XSR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000208',
    currency_name: 'Bitcoin Vault',
    currency_symbol: 'BTCV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4b742b5bdb1d252907ae7f399a891d4a178dbc24',
    currency_name: 'B ONE PAYMENT',
    currency_symbol: 'B1P',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcbe79ceca09092648995b2ccdf91ca5ecd1edec9',
    currency_name: 'BTCSHORT',
    currency_symbol: 'BTCSHORT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe0d95530820aafc51b1d98023aa1ff000b78d8b2',
    currency_name: 'PressOne',
    currency_symbol: 'PRS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7a3d3c4f30c46f51b814bee23d970a7c9b757a32',
    currency_name: 'Asgard',
    currency_symbol: 'ASG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb9ef770b6a5e12e45983c5d80545258aa38f3b78',
    currency_name: '0Chain',
    currency_symbol: 'ZCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4ba012f6e411a1be55b98e9e62c3a4ceb16ec88b',
    currency_name: 'Cybercoin',
    currency_symbol: 'CBR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GCVWTTPADC5YB5AYDKJCTUYSCJ7RKPGE4HT75NIZOUM4L7VRTS5EKLFN',
    currency_name: 'SureRemit',
    currency_symbol: 'RMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002c',
    currency_name: 'Particl',
    currency_symbol: 'PART',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe48972fcd82a274411c01834e2f031d4377fa2c0',
    currency_name: '2key.network',
    currency_symbol: '2KEY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xF84df2db2C87dd650641f8904aF71EbFC3ddE0Ea',
    currency_name: 'YouLive Coin',
    currency_symbol: 'UC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8e870d67f660d95d5be530380d0ec0bd388289e1',
    currency_name: 'Paxos Standard',
    currency_symbol: 'PAX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6f919d67967a97ea36195a2346d9244e60fe0ddb',
    currency_name: 'Blockcloud',
    currency_symbol: 'BLOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1pwcluc3a2lswrdd8v3uq43qrgfdl6kv2ahrz43',
    currency_name: 'Tixl',
    currency_symbol: 'MTXLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7CC62d8E80Be9bEa3947F3443aD136f50f75b505',
    currency_name: 'Knekted',
    currency_symbol: 'KNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2e071D2966Aa7D8dECB1005885bA1977D6038A65',
    currency_name: 'Etheroll',
    currency_symbol: 'DICE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9a005c9a89bd72a4bd27721e7a09a3c11d2b03c4',
    currency_name: 'StarterCoin',
    currency_symbol: 'STAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfe76be9cec465ed3219a9972c21655d57d21aec6',
    currency_name: 'PalletOne',
    currency_symbol: 'PTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe95990825aab1a7f0af4cc648f76a3bcc99f25b2',
    currency_name: 'Zenswap Network Token',
    currency_symbol: 'ZNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x50ee674689d75c0f88e8f83cfe8c4b69e8fd590d',
    currency_name: 'Emphy',
    currency_symbol: 'EPY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BPRO-5A6',
    currency_name: 'Bitcloud Pro',
    currency_symbol: 'BPRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff603f43946a3a28df5e6a73172555d8c8b02386',
    currency_name: 'OneRoot Network',
    currency_symbol: 'RNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80776772',
    currency_name: 'Wagerr',
    currency_symbol: 'WGR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000001c',
    currency_name: 'Vertcoin',
    currency_symbol: 'VTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba11d00c5f74255f56a5e366f4f77f5a186d7f55',
    currency_name: 'Band Protocol',
    currency_symbol: 'BAND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.PVT-554',
    currency_name: 'Pivot Token',
    currency_symbol: 'PVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x667088b212ce3d06a1b553a7221E1fD19000d9aF',
    currency_name: 'Wings',
    currency_symbol: 'WINGS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd536bbd5414a8c2beed82a63737b9327d2fa35a6',
    currency_name: 'Aitheon',
    currency_symbol: 'ACU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe64509f0bf07ce2d29a7ef19a8a9bc065477c1b4',
    currency_name: 'PiplCoin',
    currency_symbol: 'PIPL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a3496c18d558bd9c6c8f609e1b129f67ab08163',
    currency_name: 'DEAPcoin',
    currency_symbol: 'DEP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf70a642bd387f94380ffb90451c2c81d4eb82cbc',
    currency_name: 'Starbase',
    currency_symbol: 'STAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6fff3806bbac52a20e0d79bc538d527f6a22c96b',
    currency_name: 'CDX Network',
    currency_symbol: 'CDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x82f4ded9cec9b5750fbff5c2185aee35afc16587',
    currency_name: 'DreamTeam Token',
    currency_symbol: 'DREAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe5f166c0d8872b68790061317bb6cca04582c912',
    currency_name: 'TE-FOOD',
    currency_symbol: 'TFD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x40395044Ac3c0C57051906dA938B54BD6557F212',
    currency_name: 'MobileGo',
    currency_symbol: 'MGO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x47e67ba66b0699500f18a53f94e2b9db3d47437e',
    currency_name: 'PlayGame',
    currency_symbol: 'PXG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x881ef48211982d01e2cb7092c915e647cd40d85c',
    currency_name: 'Open Trading Network',
    currency_symbol: 'OTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000013e',
    currency_name: 'Matrix AI Network',
    currency_symbol: 'MAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000131',
    currency_name: 'DeepOnion',
    currency_symbol: 'ONION',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.KAVA-10C',
    currency_name: 'Kava',
    currency_symbol: 'KAVA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe1A178B681BD05964d3e3Ed33AE731577d9d96dD',
    currency_name: 'BOX Token',
    currency_symbol: 'BOX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xA8b0279aCc96efEBD09955AD8240B2d30B53055e',
    currency_name: 'MyDexPay',
    currency_symbol: 'XMD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5dbe296f97b23c4a6aa6183d73e574d02ba5c719',
    currency_name: 'Level Up Coin',
    currency_symbol: 'LUC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x31274db8b609df99e5988ee527071643b5160fc3',
    currency_name: 'Business Credit Substitute',
    currency_symbol: 'BCS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26DDF6CabADcBF4F013841BD8d914830BeB0d984',
    currency_name: 'Kuai Token',
    currency_symbol: 'KT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x82f39cd08a942f344ca7e7034461cc88c2009199',
    currency_name: 'AZBI CORE',
    currency_symbol: 'AZBI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.LTO-BDF',
    currency_name: 'LTO Network',
    currency_symbol: 'LTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800017ac',
    currency_name: 'GoChain',
    currency_symbol: 'GO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000140',
    currency_name: 'Rapids',
    currency_symbol: 'RPD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa6714a2e5f0b1bdb97b895b0913b4fcd3a775e4d',
    currency_name: 'Promotion Coin',
    currency_symbol: 'PC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.EQL-586',
    currency_name: 'Equal',
    currency_symbol: 'EQL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x286bda1413a2df81731d4930ce2f862a35a609fe',
    currency_name: 'Tael',
    currency_symbol: 'WABI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa7C71d444bf9aF4bfEd2adE75595d7512Eb4DD39',
    currency_name: 'Travel1Click',
    currency_symbol: 'T1C',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001aa',
    currency_name: 'Bitcoin Confidential',
    currency_symbol: 'BC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x47bc01597798dcd7506dcca36ac4302fc93a8cfb',
    currency_name: 'Crowd Machine',
    currency_symbol: 'CMCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2001f2a0cf801ecfda622f6c28fb6e10d803d969',
    currency_name: 'CoinLoan',
    currency_symbol: 'CLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x63120ccd7b415743e8753afd167f5ad4a1732c43',
    currency_name: 'QANplatform',
    currency_symbol: 'QARK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4Fabb145d64652a948d72533023f6E7A623C7C53',
    currency_name: 'Binance USD',
    currency_symbol: 'BUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5547136b913b68881596275ace01e9a589c5b16b',
    currency_name: 'BITCOINHEDGE',
    currency_symbol: 'BTCHG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8606a8f28e1e2fd50b9074d65c01548b1f040b32',
    currency_name: 'Cryptrust',
    currency_symbol: 'CTRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd234bf2410a0009df9c3c63b610c09738f18ccd7',
    currency_name: 'Dynamic Trading Rights',
    currency_symbol: 'DTR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GCKA6K5PCQ6PNF5RQBF7PQDJWRHO6UOGFMRLK3DYHDOI244V47XKQ4GP',
    currency_name: 'Smartlands',
    currency_symbol: 'SLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa95592dcffa3c080b4b40e459c5f5692f67db7f8',
    currency_name: 'Elysian',
    currency_symbol: 'ELY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb6f43025b29196af2dddd69b0a58afba079cd600',
    currency_name: 'Intelligent Investment Chain',
    currency_symbol: 'IIC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf0fac7104aac544e4a7ce1a55adf2b5a25c65bd1',
    currency_name: 'Pamp Network',
    currency_symbol: 'PAMP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2AA4a3E8bB72BE68a31c9c3C98CA7BeC723C6222',
    currency_name: 'Bispex',
    currency_symbol: 'BPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000016b',
    currency_name: 'CPUchain',
    currency_symbol: 'CPU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbbe761ea1447a20b75aa485b7bcad4837415d7d7',
    currency_name: 'Global Crypto Alliance',
    currency_symbol: 'CALL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb83cd8d39462b761bb0092437d38b37812dd80a2',
    currency_name: 'Golden Ratio Token',
    currency_symbol: 'GRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x056dd20b01799e9c1952c7c9a5ff4409a6110085',
    currency_name: 'WPP TOKEN',
    currency_symbol: 'WPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2ab05b915c30093679165bcdba9c26d8cd8bee99',
    currency_name: 'BitCherry',
    currency_symbol: 'BCHC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4946fcea7c692606e8908002e55a582af44ac121',
    currency_name: 'FOAM',
    currency_symbol: 'FOAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x85eee30c52b0b379b046fb0f85f4f3dc3009afec',
    currency_name: 'Keep Network',
    currency_symbol: 'KEEP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000045',
    currency_name: 'OKCash',
    currency_symbol: 'OK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa10ae543db5d967a73e9abcc69c81a18a7fc0a78',
    currency_name: 'BLOCKCLOUT',
    currency_symbol: 'CLOUT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000123',
    currency_name: 'IOST',
    currency_symbol: 'IOST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf8e06e4e4a80287fdca5b02dccecaa9d0954840f',
    currency_name: 'Truegame',
    currency_symbol: 'TGAME',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80002710',
    currency_name: 'FIBOS',
    currency_symbol: 'FO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000005e',
    currency_name: 'GoldBlocks',
    currency_symbol: 'GB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4b3a0c6d668b43f3f07904e124328659b90bb4ca',
    currency_name: 'AceD',
    currency_symbol: 'ACED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378',
    currency_name: 'Neo',
    currency_symbol: 'NEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BUSD-BD1',
    currency_name: 'Binance USD',
    currency_symbol: 'BUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2f141ce366a2462f02cea3d12cf93e4dca49e4fd',
    currency_name: 'FREE Coin',
    currency_symbol: 'FREE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b6',
    currency_name: 'MinexCoin',
    currency_symbol: 'MNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfc44ec51c80e35a87bc2140299b1636ec83dfb04',
    currency_name: 'Volt',
    currency_symbol: 'ACDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x986EE2B944c42D017F52Af21c4c69B84DBeA35d8',
    currency_name: 'BitMart Token',
    currency_symbol: 'BMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8888889213dd4da823ebdd1e235b09590633c150',
    currency_name: 'Marblecoin',
    currency_symbol: 'MBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8014095a',
    currency_name: 'Ether-1',
    currency_symbol: 'ETHO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x28b5e12cce51f15594b0b91d5b5adaa70f684a02',
    currency_name: 'NaPoleonX',
    currency_symbol: 'NPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe3278df3eb2085ba9b6899812a99a10f9ca5e0df',
    currency_name: 'Tourist Token',
    currency_symbol: 'TOTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000012f',
    currency_name: 'EUNO',
    currency_symbol: 'EUNO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaaf37055188feee4869de63464937e683d61b2a1',
    currency_name: 'UChain',
    currency_symbol: 'UCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x25b6325f5bb1c1e03cfbc3e53f470e1f1ca022e3',
    currency_name: 'Lisk Machine Learning',
    currency_symbol: 'LML',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CAS-167',
    currency_name: 'Cashaa',
    currency_symbol: 'CAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x884181554dfa9e578d36379919c05c25dc4a15bb',
    currency_name: 'Gene Source Code Chain',
    currency_symbol: 'GENE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa8006c4ca56f24d6836727d106349320db7fef82',
    currency_name: 'Internxt',
    currency_symbol: 'INXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xea5f88e54d982cbb0c441cde4e79bc305e5b43bc',
    currency_name: 'PARETO Rewards',
    currency_symbol: 'PARETO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.d613223fa138a1555ff711581982462acde209c5',
    currency_name: 'TranslateMe Network Token',
    currency_symbol: 'TMN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x919d3a363776b1ceec9352610c82dfaf80edc32d',
    currency_name: 'GoldFund',
    currency_symbol: 'GFUN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xF3b3Cad094B89392fcE5faFD40bC03b80F2Bc624',
    currency_name: 'Patron',
    currency_symbol: 'PAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x627e2ee3dbda546e168eaaff25a2c5212e4a95a0',
    currency_name: 'Inverse Bitcoin Volatility Token',
    currency_symbol: 'IBVOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000029a',
    currency_name: 'Achain',
    currency_symbol: 'ACT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b9',
    currency_name: 'Bitcoin Atom',
    currency_symbol: 'BCA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x97fb6fc2ad532033af97043b563131c5204f8a35',
    currency_name: 'Plus-Coin',
    currency_symbol: 'NPLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.e8f98440ad0d7a6e76d84fb1c3d3f8a16e162e97',
    currency_name: 'Experience Token',
    currency_symbol: 'EXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4a6058666cf1057eac3cd3a5a614620547559fc9',
    currency_name: 'Brickblock',
    currency_symbol: 'BBK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xD29F0b5b3F50b07Fe9a9511F7d86F4f4bAc3f8c4',
    currency_name: 'Liquidity Network',
    currency_symbol: 'LQD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c48f86ae57291f7686349f12601910bd8d470bb',
    currency_name: 'USDK',
    currency_symbol: 'USDK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332.0x89827f7bb951fd8a56f8ef13c5bfee38522f2e1f',
    currency_name: 'Plair',
    currency_symbol: 'PLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.TUSDB-888',
    currency_name: 'TrueUSD',
    currency_symbol: 'TUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80030fb1',
    currency_name: 'Akroma',
    currency_symbol: 'AKA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1d464ac5e046e5fe280c9588edf8eb681b07008f',
    currency_name: 'GMB',
    currency_symbol: 'GMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.EBST-783',
    currency_name: 'eBoost',
    currency_symbol: 'EBST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000f9',
    currency_name: 'Electra',
    currency_symbol: 'ECA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x491c9a23db85623eed455a8efdd6aba9b911c5df',
    currency_name: 'HeroNode',
    currency_symbol: 'HER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x708aA4e8AaeAaD6074dD09cc4e5C52A70452eB39',
    currency_name: 'Bitcoffeen',
    currency_symbol: 'BFF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x41875c2332b0877cdfaa699b641402b7d4642c32',
    currency_name: 'FUTURAX',
    currency_symbol: 'FTXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6425c6be902d692ae2db752b3c268afadb099d3b',
    currency_name: 'Restart Energy MWAT',
    currency_symbol: 'MWAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf0e6019c0f16d31294937b3334229909349e00f4',
    currency_name: 'IBStoken',
    currency_symbol: 'IBS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2ccbff3a042c68716ed2a2cb0c544a9f1d1935e1',
    currency_name: 'DMarket',
    currency_symbol: 'DMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000165',
    currency_name: 'Aryacoin',
    currency_symbol: 'AYA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x8000003c.0x0371f7b219fff864b437bcfb564810f323fffcca#tokenInfo',
    currency_name: 'Bitcurate',
    currency_symbol: 'BTCR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x53066cddbc0099eb6c96785d9b3df2aaeede5da3',
    currency_name: 'Penta',
    currency_symbol: 'PNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TWVVcRqRmpyAi9dASvTXrqnS7FrwvDezMn',
    currency_name: 'Oikos',
    currency_symbol: 'OKS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000007b',
    currency_name: 'Bitzeny',
    currency_symbol: 'ZNY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b3',
    currency_name: 'NewYorkCoin',
    currency_symbol: 'NYC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4ecdb6385f3db3847f9c4a9bf3f9917bb27a5452',
    currency_name: 'EDUCare',
    currency_symbol: 'EKT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb17df9a3b09583a9bdcf757d6367171476d4d8a3',
    currency_name: 'Maverick Chain',
    currency_symbol: 'MVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x746dda2ea243400d5a63e0700f190ab79f06489e',
    currency_name: 'BOSAGORA',
    currency_symbol: 'BOA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80737978',
    currency_name: 'ION',
    currency_symbol: 'ION',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000095',
    currency_name: 'NoLimitCoin',
    currency_symbol: 'NLC2',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8050194a',
    currency_name: 'BitcoinHD',
    currency_symbol: 'BHD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x996229D0c6a485c7F4B52E092EAa907cB2def5C6',
    currency_name: 'BuckHathCoin',
    currency_symbol: 'BHIG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GAJ7V3EMD3FRWAPBEJAP7EC4223XI5EACDZ46RFMY5DYOMCIMWEFR5II',
    currency_name: 'Gratz',
    currency_symbol: 'GRAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c36204a0712a2a50e54a62f7c4f01867e78cb53',
    currency_name: 'Taklimakan Network',
    currency_symbol: 'TAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80067932',
    currency_name: 'x42 Protocol',
    currency_symbol: 'X42',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5abaff0b83f81dc061c590aadcba013c69237fd7',
    currency_name: 'Jade Currency',
    currency_symbol: 'JADE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x679badc551626e01b23ceecefbc9b877ea18fc46',
    currency_name: 'Ccore',
    currency_symbol: 'CCO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4129d3b7a6a2c5c997774077ac02bdafd1af1d6a',
    currency_name: 'Ladder Network Token',
    currency_symbol: 'LAD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x780116d91e5592e58a3b3c76a351571b39abcec6',
    currency_name: 'Blockparty (BOXX Token)',
    currency_symbol: 'BOXX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB48B7E5bF6563B3e0A85055821A83Deb8CFc12f6',
    currency_name: 'NOVA',
    currency_symbol: 'NOVA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9fbfed658919a896b5dc7b00456ce22d780f9b65',
    currency_name: 'PlutusDeFi',
    currency_symbol: 'PLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x355a458d555151d3b27f94227960ade1504e526a',
    currency_name: 'StockChain',
    currency_symbol: 'SCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ETHBEAR-B2B',
    currency_name: '3X Short Ethereum Token',
    currency_symbol: 'ETHBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332.0x0000000000000000000000000000456E65726779',
    currency_name: 'VeThor Token',
    currency_symbol: 'VTHO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000148',
    currency_name: 'Blocknet',
    currency_symbol: 'BLOCK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8E5610ab5E39d26828167640EA29823fe1dD5843',
    currency_name: 'KanadeCoin',
    currency_symbol: 'KNDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb6ed7644c69416d67b522e20bc294a9a9b405b31',
    currency_name: '0xBitcoin',
    currency_symbol: '0xBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.RAVEN-F66',
    currency_name: 'Raven Protocol',
    currency_symbol: 'RAVEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x755eb14D2fefF2939EB3026f5CaD9D03775b9fF4',
    currency_name: 'BunnyToken',
    currency_symbol: 'BUNNY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb97048628db6b661d4c2aa833e95dbe1a905b280',
    currency_name: 'TenX',
    currency_symbol: 'PAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x19fFfd124CD9089E21026d10dA97f8cD6B442Bff',
    currency_name: 'Zuflo Coin',
    currency_symbol: 'ZFL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdac4ae188ace3c8985765edc6c9b4739d4845ddc',
    currency_name: 'InterValue',
    currency_symbol: 'INVE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa849eaae994fb86afa73382e9bd88c2b6b18dc71',
    currency_name: 'MVL',
    currency_symbol: 'MVL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000ca',
    currency_name: 'Unknown Fair Object',
    currency_symbol: 'UFO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6888a16ea9792c15a4dcf2f6c623d055c8ede792',
    currency_name: 'Spectiv',
    currency_symbol: 'SIG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x07a58629aaf3e1a0d07d8f43114b76bd5eee3b91',
    currency_name: 'Guaranteed Ethurance Token Extra',
    currency_symbol: 'GETX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xddaaf4a0702a03a4505f2352a1aba001ffc344be',
    currency_name: 'ATC Coin',
    currency_symbol: 'ATCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd433138d12beB9929FF6fd583DC83663eea6Aaa5',
    currency_name: 'Bitrue Coin',
    currency_symbol: 'BTR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf29992d7b589a0a6bd2de7be29a97a6eb73eaf85',
    currency_name: 'DMST',
    currency_symbol: 'DMST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x78f5bbc74fb9137a75d85f3c9c3c599be49f0a56',
    currency_name: 'Smartup',
    currency_symbol: 'SMARTUP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9c23d67aea7b95d80942e3836bcdf7e708a747c2',
    currency_name: 'LOCIcoin',
    currency_symbol: 'LOCI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb5a73f5fc8bbdbce59bfd01ca8d35062e0dad801',
    currency_name: 'Perlin',
    currency_symbol: 'PERL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6c37bf4f042712c978a73e3fd56d1f5738dd7c43',
    currency_name: 'Elementeum',
    currency_symbol: 'ELET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000093',
    currency_name: 'ZClassic',
    currency_symbol: 'ZCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf2705d4368cd2ee3bbff73594ae47244064dffb',
    currency_name: 'Xenoverse',
    currency_symbol: 'XENO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x026e62dded1a6ad07d93d39f96b9eabd59665e0d',
    currency_name: 'Birdchain',
    currency_symbol: 'BIRD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9e46a38f5daabe8683e10793b06749eef7d733d1',
    currency_name: 'PolySwarm',
    currency_symbol: 'NCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeed3ae7b0f8b5b9bb8c035a9941382b1822671cd',
    currency_name: 'EveryCoin ',
    currency_symbol: 'EVY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc8cac7672f4669685817cf332a33eb249f085475',
    currency_name: 'LivenPay',
    currency_symbol: 'LVN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfb559ce67ff522ec0b9ba7f5dc9dc7ef6c139803',
    currency_name: 'ProBit Token',
    currency_symbol: 'PROB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcdb7ecfd3403eef3882c65b761ef9b5054890a47',
    currency_name: 'Hurify',
    currency_symbol: 'HUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000079',
    currency_name: 'Horizen',
    currency_symbol: 'ZEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a5',
    currency_name: 'Nano',
    currency_symbol: 'NANO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf53c580bc4065405bc649cc077ff4f2f28528f4b',
    currency_name: 'Bittwatt',
    currency_symbol: 'BWT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000171',
    currency_name: 'Asch',
    currency_symbol: 'XAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MTXLT-286',
    currency_name: 'Tixl',
    currency_symbol: 'MTXLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x94501b6A153C8973fd1f321fCC8188d40dC5d72d',
    currency_name: 'DigiDinar Token',
    currency_symbol: 'DDRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.XRPBULL-E7C',
    currency_name: '3x Long XRP Token',
    currency_symbol: 'XRPBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x574f84108a98c575794f75483d801d1d5dc861a5',
    currency_name: 'Robotina',
    currency_symbol: 'ROX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000d4',
    currency_name: 'Utrum',
    currency_symbol: 'OOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000320',
    currency_name: 'Beetle Coin',
    currency_symbol: 'BEET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000168',
    currency_name: 'v.systems',
    currency_symbol: 'VSYS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332.0x0ce6661b4ba86a0ea7ca2bd86a0de87b0b860f14',
    currency_name: 'OceanEx Token',
    currency_symbol: 'OCE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000147',
    currency_name: 'Commercium',
    currency_symbol: 'CMM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa00a4d5786a6e955e9539d01d78bf68f3271c050',
    currency_name: 'Quiztok',
    currency_symbol: 'QTCON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeb4c2781e4eba804ce9a9803c67d0893436bb27d',
    currency_name: 'renBTC',
    currency_symbol: 'RENBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf8483E2d6560585C02D46bF7B3186Bf154a96166',
    currency_name: 'Idea Chain Coin',
    currency_symbol: 'ICH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb72b31907c1c95f3650b64b2469e08edacee5e8f',
    currency_name: 'bZx Vesting Token',
    currency_symbol: 'VBZRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4092678e4e78230f46a1534c0fbc8fa39780892b',
    currency_name: 'Odyssey',
    currency_symbol: 'OCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.EdDvbhk4wJ1kL6pMCq1V36GbQE2nGE7Metb87zbaY2JL',
    currency_name: 'Primalbase Token',
    currency_symbol: 'PBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xab5c04bbe42667610a2da07ac98ea9fa6e4a9514',
    currency_name: 'IZEROIUM',
    currency_symbol: 'IZER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x12b19d3e2ccc14da04fae33e63652ce469b3f2fd',
    currency_name: 'Grid+',
    currency_symbol: 'GRID',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x820a8481451e893bc66dce50c84d45617cac3705',
    currency_name: 'Bitcoin True',
    currency_symbol: 'BTCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5a4b14aea23a605abc463f04a6b8aaf52dd3e7c6',
    currency_name: 'HeartBout Pay',
    currency_symbol: 'HP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002b.comsa',
    currency_name: 'COMSA [XEM]',
    currency_symbol: 'CMS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.7f86d61ff377f1b12e589a5907152b57e2ad9a7a',
    currency_name: 'Alphacat',
    currency_symbol: 'ACAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CAN-677',
    currency_name: 'CanYaCoin',
    currency_symbol: 'CAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7865af71cf0b288b4e7f654f4f7851eb46a2b7f8',
    currency_name: 'Sentivate',
    currency_symbol: 'SNTVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x58b6a8a3302369daec383334672404ee733ab239',
    currency_name: 'Livepeer',
    currency_symbol: 'LPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332.#hai-insights',
    currency_name: 'HackenAI',
    currency_symbol: 'HAI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x20bcae16a8ba95d8e8363e265de4ecfc36ec5cd9',
    currency_name: 'Hey Bitcoin',
    currency_symbol: 'HYBN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4946583c5b86e01ccd30c71a05617d06e3e73060',
    currency_name: 'PTON',
    currency_symbol: 'PTON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbc7f402f3bc1c6d56c8041f551b47a0ad7714d1b',
    currency_name: 'GoldenPyrex',
    currency_symbol: 'GPYX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x06e0feb0d74106c7ada8497754074d222ec6bcdf',
    currency_name: 'BitBall',
    currency_symbol: 'BTB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8b1f49491477e0fb46a29fef53f1ea320d13c349',
    currency_name: 'MicroMoney',
    currency_symbol: 'AMM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf3586684107ce0859c44aa2b2e0fb8cd8731a15a',
    currency_name: 'Karatgold Coin',
    currency_symbol: 'KBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9135d92e3a34e2a94e4474b74b9dc2d51118eed5',
    currency_name: 'Ulgen Hash Power',
    currency_symbol: 'UHP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb4058411967d5046f3510943103805be61f0600e',
    currency_name: 'STONK',
    currency_symbol: 'STONK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcc34366e3842ca1bd36c1f324d15257960fcc801',
    currency_name: 'Bonpay',
    currency_symbol: 'BON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf6dbe88ba55f1793ff0773c9b1275300f830914f',
    currency_name: 'Asian Dragon',
    currency_symbol: 'AD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f8c45b896784a1e408526b9300519ef8660209c',
    currency_name: 'XMax',
    currency_symbol: 'XMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xffe02ee4c69edf1b340fcad64fbd6b37a7b9e265',
    currency_name: 'NANJCOIN',
    currency_symbol: 'NANJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xade7b5f4a421d81ddad8ce86f77a0efe8921e9cc',
    currency_name: 'Diligence',
    currency_symbol: 'IRA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2ecb13a8c458c379c4d9a7259e202de03c8f3d19',
    currency_name: 'Block-Chain.com',
    currency_symbol: 'BC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xee059f0ca1507e4e20c689b20cff71b5e924f7bd',
    currency_name: 'Litecoin SV',
    currency_symbol: 'LSV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6f4ee03ca6c942c9397d2ba5f8f83ea58f918f47',
    currency_name: 'CashBackPro',
    currency_symbol: 'CBP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfa456cf55250a839088b27ee32a424d7dacb54ff',
    currency_name: 'Blocktrade Token',
    currency_symbol: 'BTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000009.BITCRYSTALS',
    currency_name: 'BitCrystals',
    currency_symbol: 'BCY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe5b826ca2ca02f09c1725e9bd98d9a8874c30532',
    currency_name: 'ZEON',
    currency_symbol: 'ZEON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe3f4b4a5d91e5cb9435b947f090a319737036312',
    currency_name: 'POPCHAIN',
    currency_symbol: 'PCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1C09EF4493465569f6d704A5CC4f9864BCD2E56a',
    currency_name: 'Originate Coin',
    currency_symbol: 'ORC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TMwFHYXLJaRUPeW6421aqXL4ZEzPRFGkGT',
    currency_name: 'JUST Stablecoin',
    currency_symbol: 'USDJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x62a56a4a2ef4d355d34d10fbf837e747504d38d4',
    currency_name: 'Paypex',
    currency_symbol: 'PAYX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001e1',
    currency_name: 'Mooncoin',
    currency_symbol: 'MOON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000066a',
    currency_name: 'Newton',
    currency_symbol: 'NEW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000001e',
    currency_name: 'Burst',
    currency_symbol: 'BURST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe2fb6529ef566a080e6d23de0bd351311087d567',
    currency_name: 'Covesting',
    currency_symbol: 'COV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x07d9e49ea402194bf48a8276dafb16e4ed633317',
    currency_name: 'Dalecoin',
    currency_symbol: 'DALC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8a77e40936bbc27e80e9a3f526368c967869c86d',
    currency_name: 'Merculet',
    currency_symbol: 'MVP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TMCMPzmosnQ8UAYW1zcBwjLTxDq8ce4Y5e',
    currency_name: 'PYRO Network (TRON)',
    currency_symbol: 'PYRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x107c4504cd79c5d2696ea0030a8dd4e92601b82e',
    currency_name: 'Bloom',
    currency_symbol: 'BLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004d',
    currency_name: 'Verge',
    currency_symbol: 'XVG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000115',
    currency_name: 'Arepacoin',
    currency_symbol: 'AREPA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x41e5560054824ea6b0732e656e3ad64e20e94e45',
    currency_name: 'Civic',
    currency_symbol: 'CVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c64031c62061865e5fd0f53d3cdaef80f72e99d',
    currency_name: 'Hashgard',
    currency_symbol: 'GARD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c743a35e903f6c584514ec617acee0611cf44f3',
    currency_name: 'Experty',
    currency_symbol: 'EXY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x06147110022b768ba8f99a8f385df11a151a9cc8',
    currency_name: 'ACE (TokenStars)',
    currency_symbol: 'ACE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaf8a215e81faea7c180ce22b72483525121813bd',
    currency_name: 'Engine',
    currency_symbol: 'EGCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GCZNF24HPMYTV6NOEHI7Q5RJFFUI23JKUKY3H3XTQAFBQIBOHD5OXG3B',
    currency_name: 'REPO',
    currency_symbol: 'REPO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TG37mUxRUaH1E8DWSrrmoQ79BnZn1yHztb',
    currency_name: 'BitGuild PLAT',
    currency_symbol: 'PLAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb18kha55gvsxl7gkdh8y329hu3p6wndh6jkwqnxn',
    currency_name: 'Binance KRW',
    currency_symbol: 'BKRW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800a2c2a',
    currency_name: 'VITE',
    currency_symbol: 'VITE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GB2WB73G5IWRXEUTJANAIF52JFTDXOXORAP4S5HZRW23TZG2ONDVZMVA',
    currency_name: 'Scopuly',
    currency_symbol: 'SKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000014a',
    currency_name: 'Terra',
    currency_symbol: 'LUNA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x688ff43c3c19e4714f0beb76df8ee394207ab411',
    currency_name: 'CitiOs',
    currency_symbol: 'R2R',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x47d1a59cbdd19aee060c859c0009277e245328ae',
    currency_name: 'SONDER',
    currency_symbol: 'SNR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x017b584acfd16d767541ae9e80cdc702f4527b0b',
    currency_name: 'ASYAGRO',
    currency_symbol: 'ASY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9af839687f6c94542ac5ece2e317daae355493a1',
    currency_name: 'Hydro Protocol',
    currency_symbol: 'HOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000016f',
    currency_name: 'ABBC Coin',
    currency_symbol: 'ABBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70debcdab2ef20be3d1dbff6a845e9ccb6e46930',
    currency_name: 'BIKI',
    currency_symbol: 'BIKI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd7b3669c7d3e38ab5a441383d41f25e003e02148',
    currency_name: 'Seal Network',
    currency_symbol: 'SEAL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x45804880de22913dafe09f4980848ece6ecbaf78',
    currency_name: 'PAX Gold',
    currency_symbol: 'PAXG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd0bd12a8d5ebca1e2fa46da59f1993ec51c3d75c',
    currency_name: 'BKEX Token',
    currency_symbol: 'BKK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000038',
    currency_name: 'EDRCoin',
    currency_symbol: 'EDRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000005a',
    currency_name: 'Myriad',
    currency_symbol: 'XMY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb020ed54651831878e5c967e0953a900786178f9',
    currency_name: 'Bazooka Token',
    currency_symbol: 'BAZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8057414e',
    currency_name: 'Wanchain',
    currency_symbol: 'WAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000127',
    currency_name: 'I/O Coin',
    currency_symbol: 'IOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4CF488387F035FF08c371515562CBa712f9015d4',
    currency_name: 'WePower',
    currency_symbol: 'WPR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaffcdd96531bcd66faed95fc61e443d08f79efef',
    currency_name: 'Perth Mint Gold Token',
    currency_symbol: 'PMGT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5925f67d2767d937f47141dac24166b469558222',
    currency_name: 'OLXA',
    currency_symbol: 'OLXA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4257d9c82767d0bf1e53e14cdee1e9ce50ebdc3b',
    currency_name: 'BUDDY',
    currency_symbol: 'BUD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x09970aec766b6f3223aca9111555e99dc50ff13a',
    currency_name: 'Levolution',
    currency_symbol: 'LEVL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b11efcaaa1890f6ee52c6bb7cf8153ac5d74139',
    currency_name: 'ATMChain',
    currency_symbol: 'ATM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe09394f8ba642430ed448ca20f342ec7aa1ba2e1',
    currency_name: 'Fesschain',
    currency_symbol: 'FESS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x871baed4088b863fd6407159f3672d70cd34837d',
    currency_name: '3X Long Ethereum Token',
    currency_symbol: 'ETHBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x42bedd647e387dabec65a7dc3a3babcc68bb664d',
    currency_name: 'Blockmason Link',
    currency_symbol: 'BLINK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000378.602c79718b16e442de58778e148d0b1084e3b2dffd5de6b7b16cee7969282de7',
    currency_name: 'Gas',
    currency_symbol: 'GAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2ec95b8eda549b79a1248335a39d299d00ed314c',
    currency_name: 'Fatcoin',
    currency_symbol: 'FAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x57700244b20f84799a31c6c96dadff373ca9d6c5',
    currency_name: 'TrustDAO',
    currency_symbol: 'TRUST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1dEa979ae76f26071870F824088dA78979eb91C8',
    currency_name: 'SPINDLE',
    currency_symbol: 'SPD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80002645',
    currency_name: 'Energi',
    currency_symbol: 'NRG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x608f006b6813f97097372d0d31fb0f11d1ca3e4e',
    currency_name: 'CryptoAds Marketplace',
    currency_symbol: 'CRAD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe75ad3aab14e4b0df8c5da4286608dabb21bd864',
    currency_name: 'Acute Angle Cloud',
    currency_symbol: 'AAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xac4d22e40bf0b8ef4750a99ed4e935b99a42685e',
    currency_name: 'Aeryus',
    currency_symbol: 'AER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.DG2xFkPdDwKUoBkzGAhQtLpSGzfXLiCYPEzeKH2Ad24p',
    currency_name: 'Neutrino Dollar',
    currency_symbol: 'USDN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcb39c3502415152b2ec90ff07ee18cc94f681a72',
    currency_name: 'Storeum',
    currency_symbol: 'STO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4CcC3759eB48fAF1c6cfadaD2619E7038db6b212',
    currency_name: 'SuperEdge',
    currency_symbol: 'ECT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff5c25d2f40b47c4a37f989de933e26562ef0ac0',
    currency_name: 'Kora Network Token',
    currency_symbol: 'KNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc9a2c4868f0f96faaa739b59934dc9cb304112ec',
    currency_name: 'Binance GBP Stable Coin',
    currency_symbol: 'BGBP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000015a',
    currency_name: 'Orient Walt',
    currency_symbol: 'HTDF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4156d3342d5c385a87d264f90653733592000581',
    currency_name: 'SALT',
    currency_symbol: 'SALT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000019a',
    currency_name: 'SnowGem',
    currency_symbol: 'XSG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d64d850c8368008afb39224e92ad0dceff3cf38',
    currency_name: 'MINDOL',
    currency_symbol: 'MIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xCa208BfD69ae6D2667f1FCbE681BAe12767c0078',
    currency_name: 'HOMIHELP',
    currency_symbol: 'HOMI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.Dd3ppdB8zuW8gEJK5RamNmNngo4BjcxC9gHM1ZDX2smF',
    currency_name: 'wave edu coin',
    currency_symbol: 'WEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.0e86a40588f715fcaf7acd1812d50af478e6e917',
    currency_name: 'Orbis Token',
    currency_symbol: 'OBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6944d3e38973C4831dA24E954fbD790c7E688bDd',
    currency_name: 'IZE',
    currency_symbol: 'IZE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1ccaa0f2a7210d76e1fdec740d5f323e2e1b1672',
    currency_name: 'Faceter',
    currency_symbol: 'FACE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8762db106b2c2a0bccb3a80d1ed41273552616e8',
    currency_name: 'Reserve Rights',
    currency_symbol: 'RSR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008ff',
    currency_name: 'GXChain',
    currency_symbol: 'GXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xea1f346faf023f974eb5adaf088bbcdf02d761f4',
    currency_name: 'Blocktix',
    currency_symbol: 'TIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001a6',
    currency_name: 'Conceal',
    currency_symbol: 'CCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0223fc70574214f65813fe336d870ac47e147fae',
    currency_name: 'CanonChain',
    currency_symbol: 'CZR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8a854288a5976036a725879164ca3e91d30c6a1b',
    currency_name: 'GET Protocol',
    currency_symbol: 'GET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x971d048e737619884f2df75e31c7eb6412392328',
    currency_name: 'Sparkster',
    currency_symbol: 'SPRK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc28e931814725bbeb9e670676fabbcb694fe7df2',
    currency_name: 'QuadrantProtocol',
    currency_symbol: 'EQUAD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c4481750daa5ff521a2a7490d9981ed46465dbd',
    currency_name: 'Blockmason Credit Protocol',
    currency_symbol: 'BCPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa7DE087329BFcda5639247F96140f9DAbe3DeED1',
    currency_name: 'STATERA',
    currency_symbol: 'STA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf51EBf9a26DbC02B13F8B3a9110dac47a4d62D78',
    currency_name: 'APIX',
    currency_symbol: 'APIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0bef619cf38cf0c22967289b8419720fbd1db9f7',
    currency_name: 'Aencoin',
    currency_symbol: 'AEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6b193e107a773967bd821bcf8218f3548cfa2503',
    currency_name: 'Posscoin',
    currency_symbol: 'POSS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800003e7',
    currency_name: 'Bitcoin Diamond',
    currency_symbol: 'BCD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001d8',
    currency_name: 'Arweave',
    currency_symbol: 'AR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002b.pundix',
    currency_name: 'Pundi X NEM',
    currency_symbol: 'NPXSXEM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5ca381bbfb58f0092df149bd3d243b08b9a8386e',
    currency_name: 'Machine Xchange Coin',
    currency_symbol: 'MXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf04a8ac553fcedb5ba99a64799155826c136b0be',
    currency_name: 'Flixxo',
    currency_symbol: 'FLIXX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000de',
    currency_name: 'BitGreen',
    currency_symbol: 'BITG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeb269732ab75a6fd61ea60b06fe994cd32a83549',
    currency_name: 'USDx stablecoin',
    currency_symbol: 'USDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcf8048b2d336c569a3985bd93cbb91b758ded178',
    currency_name: 'IZE',
    currency_symbol: 'IZE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc011a72400e58ecd99ee497cf89e3775d4bd732f',
    currency_name: 'Synthetix Network Token',
    currency_symbol: 'SNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7105eC15995A97496eC25de36CF7eEc47b703375',
    currency_name: 'Red Box Dapp Token',
    currency_symbol: 'RBD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6b4689e4514957699edeb2ee91c947f18e439806',
    currency_name: 'ZeuxCoin',
    currency_symbol: 'ZUC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x15a664416e42766a6cc0a1221d9c088548a6e731',
    currency_name: 'WEBN token',
    currency_symbol: 'WEBN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x419b8ed155180a8c9c64145e76dad49c0a4efb97',
    currency_name: 'Alt.Estate token',
    currency_symbol: 'ALT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaff84e86d72edb971341a6a66eb2da209446fa14',
    currency_name: 'The Currency Analytics',
    currency_symbol: 'TCAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x840fe75abfadc0f2d54037829571b2782e919ce4',
    currency_name: 'Webcoin',
    currency_symbol: 'WEB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4bd70556ae3f8a6ec6c4080a0c327b24325438f3',
    currency_name: 'Hxro',
    currency_symbol: 'HXRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdb25f211ab05b1c97d595516f45794528a807ad8',
    currency_name: 'STASIS EURO',
    currency_symbol: 'EURS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4f9254c83eb525f9fcf346490bbb3ed28a81c667',
    currency_name: 'Celer Network',
    currency_symbol: 'CELR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000088',
    currency_name: 'Zcoin',
    currency_symbol: 'XZC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f02e27745e3b6e9e1310d19469e2b5d7b5ec99a',
    currency_name: 'Peculium',
    currency_symbol: 'PCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6fe355c62c6faf6946ce888ffaba9fd12355ae27',
    currency_name: 'HashBX ',
    currency_symbol: 'HBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd2946be786f35c3cc402c29b323647abda799071',
    currency_name: 'VikkyToken',
    currency_symbol: 'VIKKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x35b08722aa26be119c1608029ccbc976ac5c1082',
    currency_name: 'Eminer',
    currency_symbol: 'EM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x95c9bd1f81cee7391da3eac81693e60f3292c1e0',
    currency_name: 'Linfinity',
    currency_symbol: 'LFC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000309',
    currency_name: 'BitWhite',
    currency_symbol: 'BTW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1fe70be734e473e5721ea57c8b5b01e6caa52686',
    currency_name: 'BitRent',
    currency_symbol: 'RNTB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x02c4c78c462e32cca4a90bc499bf411fb7bc6afb',
    currency_name: 'InvestDigital',
    currency_symbol: 'IDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbac7a1798350cdf2dbfe0c210c2c9861223f4b31',
    currency_name: 'Moneynet',
    currency_symbol: 'MNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfec0cf7fe078a500abf15f1284958f22049c2c7e',
    currency_name: 'Maecenas',
    currency_symbol: 'ART',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.EOSBEAR-721',
    currency_name: '3x Short EOS Token',
    currency_symbol: 'EOSBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x37236cd05b34cc79d3715af2383e96dd7443dcf1',
    currency_name: 'Small Love Potion',
    currency_symbol: 'SLP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1063ce524265d5a3a624f4914acd573dd89ce988',
    currency_name: 'Aigang',
    currency_symbol: 'AIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8f8221afbb33998d8584a2b05749ba73c37a938a',
    currency_name: 'Request',
    currency_symbol: 'REQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000801',
    currency_name: 'TrueChain',
    currency_symbol: 'TRUE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xda4129919f964a3a526d3182bb03e6449e5a8872',
    currency_name: '1irstGold',
    currency_symbol: '1GOLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4e15361fd6b4bb609fa63c81a2be19d873717870',
    currency_name: 'Fantom',
    currency_symbol: 'FTM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb190acfwshh899eylweut9xarls2ma953rvkdlhf',
    currency_name: 'PAYCENT',
    currency_symbol: 'PYN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c9',
    currency_name: 'Bitcoin File',
    currency_symbol: 'BIFI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.06fa8be9b6609d963e8fc63977b9f8dc5f10895f',
    currency_name: 'Loopring [NEO]',
    currency_symbol: 'LRN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeaf61fc150cd5c3bea75744e830d916e60ea5a9f',
    currency_name: 'Typerium',
    currency_symbol: 'TYPE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b39a0b97319a9bd5fed217c1db7b030453bac91',
    currency_name: 'TigerCash',
    currency_symbol: 'TCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc00e94cb662c3520282e6f5717214004a7f26888',
    currency_name: 'Compound',
    currency_symbol: 'COMP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaaaebe6fe48e54f431b0c390cfaf0b017d09d42d',
    currency_name: 'Celsius',
    currency_symbol: 'CEL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf7920b0768ecb20a123fac32311d07d193381d6f',
    currency_name: 'Time New Bank',
    currency_symbol: 'TNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000001d',
    currency_name: 'Nxt',
    currency_symbol: 'NXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbE9375C6a420D2eEB258962efB95551A5b722803',
    currency_name: 'StormX',
    currency_symbol: 'STMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x01c0987e88f778df6640787226bc96354e1a9766',
    currency_name: 'UltrAlpha',
    currency_symbol: 'UAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8f136cc8bef1fea4a7b71aa2301ff1a52f084384',
    currency_name: 'StarChain',
    currency_symbol: 'STC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaad54c9f27b876d2538455dda69207279ff673a5',
    currency_name: 'Davinci Coin',
    currency_symbol: 'DAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4aac461c86abfa71e9d00d9a2cde8d74e4e1aeea',
    currency_name: 'ZINC',
    currency_symbol: 'ZINC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x24692791bc444c5cd0b81e3cbcaba4b04acd1f3b',
    currency_name: 'Unikoin Gold',
    currency_symbol: 'UKG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf347911910b6c9a4286ba8e2ee5ea4a39eb2134',
    currency_name: "Bob's Repair",
    currency_symbol: 'BOB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xac2e58a06e6265f1cf5084ee58da68e5d75b49ca',
    currency_name: 'ORS Group',
    currency_symbol: 'ORS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f5f3cfd7a32700c93f971637407ff17b91c7342',
    currency_name: 'Scry.info',
    currency_symbol: 'DDD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000011d',
    currency_name: 'GravityCoin',
    currency_symbol: 'GXX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x40c6f861a08f97dfbc3c0931485bff4921975a56',
    currency_name: 'HGH Token',
    currency_symbol: 'HGH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000006c.0x500684ce0d4f04abedff3e54fcf8acc5e6cfc4bd',
    currency_name: 'GeoCoin',
    currency_symbol: 'GEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa809d363a66c576a2a814cdbfefc107c600a55f0',
    currency_name: 'Esportbits',
    currency_symbol: 'HLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x57C09A8de0b0F471F8567609777aDdFfb5c46a08',
    currency_name: 'Bitex Global XBX Coin',
    currency_symbol: 'XBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.SLV-986',
    currency_name: 'Silverway',
    currency_symbol: 'SLV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xA1248c718d52752b2cC257eeb0eBa900408dAeB8',
    currency_name: 'SWYFT',
    currency_symbol: 'SWYFTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf03f8d65bafa598611c3495124093c56e8f638f0',
    currency_name: 'View',
    currency_symbol: 'VIEW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001a3',
    currency_name: 'Lethean',
    currency_symbol: 'LTHN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbc86727e770de68b1060c91f6bb6945c73e10388',
    currency_name: 'Ink Protocol',
    currency_symbol: 'XNK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1602af2C782cC03F9241992E243290Fccf73Bb13',
    currency_name: 'Qubitica',
    currency_symbol: 'QBIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeda8b016efa8b1161208cf041cd86972eee0f31e',
    currency_name: 'IHT Real Estate Protocol',
    currency_symbol: 'IHT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6863be0e7cf7ce860a574760e9020d519a8bdc47',
    currency_name: 'On.Live',
    currency_symbol: 'ONL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x41dbecc1cdc5517c6f76f6a6e836adbee2754de3',
    currency_name: 'Medicalchain',
    currency_symbol: 'MTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7b68d272eda2185ea2f9283f241b1c44c51e712a',
    currency_name: 'Okschain',
    currency_symbol: 'OKS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfa05A73FfE78ef8f1a739473e462c54bae6567D9',
    currency_name: 'Lunyr',
    currency_symbol: 'LUN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x147faf8de9d8d8daae129b187f0d02d819126750',
    currency_name: 'GeoDB',
    currency_symbol: 'GEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa3BeD4E1c75D00fa6f4E5E6922DB7261B5E9AcD2',
    currency_name: 'Meta',
    currency_symbol: 'MTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd9a12cde03a86e800496469858de8581d3a5353d',
    currency_name: 'Crowdholding',
    currency_symbol: 'YUP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001f4',
    currency_name: 'THETA',
    currency_symbol: 'THETA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5319e86F0e41a06E49eb37046b8c11D78bcAd68C',
    currency_name: 'Zelwin',
    currency_symbol: 'ZLW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5137a403dd25e48de528912a4af62881e625d801',
    currency_name: 'HUDDL',
    currency_symbol: 'HUDDL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe0c6ce3e73029f201e5c0bedb97f67572a93711c',
    currency_name: 'ETHplode',
    currency_symbol: 'ETHPLO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc56b13ebbcffa67cfb7979b900b736b3fb480d78',
    currency_name: 'Social Activity Token',
    currency_symbol: 'SAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xad22f63404f7305e4713ccbd4f296f34770513f4',
    currency_name: 'Atomic Wallet Coin',
    currency_symbol: 'AWC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.8ofu3VpEaVCFjRqLLqzTMNs5URKUUQMrPp3k6oFmiCc6',
    currency_name: 'EncryptoTel [WAVES]',
    currency_symbol: 'ETT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9e3319636e2126e3c0bc9e3134AEC5e1508A46c7',
    currency_name: 'Universa',
    currency_symbol: 'UTNP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x297e4e5e59ad72b1b0a2fd446929e76117be0e0a',
    currency_name: 'Valor Token',
    currency_symbol: 'VALOR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000026',
    currency_name: 'Startcoin',
    currency_symbol: 'START',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1234567461d3f8db7496581774bd869c83d51c93',
    currency_name: 'BitClave',
    currency_symbol: 'CAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x226f15cdbaa36814ce3cb287563069c32cc1a293',
    currency_name: 'CRYPTOFOREX',
    currency_symbol: 'CFX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x72955ecff76e48f2c8abcce11d54e5734d6f3657',
    currency_name: 'TrustVerse',
    currency_symbol: 'TRV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0c3ef32f802967db75b9d49fe1e76620151ccb81',
    currency_name: 'Whole Network',
    currency_symbol: 'NODE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9669890e48f330acd88b78d63e1a6b3482652cd9',
    currency_name: 'Bincentive',
    currency_symbol: 'BCNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcD62b1C403fa761BAadFC74C525ce2B51780b184',
    currency_name: 'Aragon Court',
    currency_symbol: 'ANJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x81b4d08645da11374a03749ab170836e4e539767',
    currency_name: 'Paymon',
    currency_symbol: 'PMNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0947b0e6d821378805c9598291385ce7c791a6b2',
    currency_name: 'Lendingblock',
    currency_symbol: 'LND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xacce88f5a63a5e65db9aa7303720be16b556e751',
    currency_name: 'Coineal Token',
    currency_symbol: 'NEAL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6913ccabbc337f0ea7b4109dd8200d61c704d332',
    currency_name: 'Asac Coin',
    currency_symbol: 'ASAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa960d2ba7000d58773e7fa5754dec3bb40a069d5',
    currency_name: 'One DEX',
    currency_symbol: 'ODEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x36b4b58de030e93775e151a78d796039a11a2548',
    currency_name: 'vSportCoin',
    currency_symbol: 'VSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd2bb16cf38ca086cab5128d5c25de9477ebd596b',
    currency_name: 'xCrypt Token',
    currency_symbol: 'XCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x79b4D12Fa63A8d1202b26c5Ba6d62136A4A09dDa',
    currency_name: 'SymVerse',
    currency_symbol: 'SYM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000154',
    currency_name: 'NativeCoin',
    currency_symbol: 'N8V',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004e',
    currency_name: 'e-Gulden',
    currency_symbol: 'EFL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x558ec3152e2eb2174905cd19aea4e34a23de9ad6',
    currency_name: 'Bread',
    currency_symbol: 'BRD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3cC5EB07E0e1227613F1DF58f38b549823d11cB9',
    currency_name: 'Ethereum eRush',
    currency_symbol: 'EER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1d8ca7baf0895da8afcf153657be064b5092a274',
    currency_name: 'Ethlyte Crypto',
    currency_symbol: 'EtLyteT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x943ed852dadb5c3938ecdc6883718df8142de4c8',
    currency_name: 'FansTime',
    currency_symbol: 'FTI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6871799a4866bb9068b36b7a9bb93475ac77ac5d',
    currency_name: 'eToro New Zealand Dollar',
    currency_symbol: 'NZDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xF433089366899D83a9f26A773D59ec7eCF30355e',
    currency_name: 'Metal',
    currency_symbol: 'MTL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8b40761142b9aa6dc8964e61d0585995425c3d94',
    currency_name: 'Tripio',
    currency_symbol: 'TRIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x264dc2dedcdcbb897561a57cba5085ca416fb7b4',
    currency_name: 'QunQun',
    currency_symbol: 'QUN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf26893f89b23084c4c6216038d6ebdbe9e96c5cb',
    currency_name: 'Mega Lottery Services Global',
    currency_symbol: 'MLR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000174',
    currency_name: 'ZVCHAIN',
    currency_symbol: 'ZVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe1aee98495365fc179699c1bb3e761fa716bee62',
    currency_name: 'Bezant',
    currency_symbol: 'BZNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80004adf',
    currency_name: 'Zel',
    currency_symbol: 'ZEL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TDFRfJLThnLXgtEBBBmA2LGwz3Ex9dAMCE',
    currency_name: 'CryptoBharatCoin',
    currency_symbol: 'CBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001b6',
    currency_name: 'LINK',
    currency_symbol: 'LN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    currency_name: 'WETH',
    currency_symbol: 'WETH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08f5a9235b08173b7569f83645d2c7fb55e8ccd8',
    currency_name: 'Tierion',
    currency_symbol: 'TNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x03b155af3f4459193a276395dd76e357bb472da1',
    currency_name: 'Swace',
    currency_symbol: 'SWACE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba100000625a3754423978a60c9317c58a424e3D',
    currency_name: 'Balancer',
    currency_symbol: 'BAL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1b80eeeadcc590f305945bcc258cfa770bbe1890',
    currency_name: 'Bitsdaq',
    currency_symbol: 'BQQQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x491604c0fdf08347dd1fa4ee062a822a5dd06b5d',
    currency_name: 'Cartesi',
    currency_symbol: 'CTSI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0a2d9370cf74da3fd3df5d764e394ca8205c50b6',
    currency_name: 'Save Environment Token',
    currency_symbol: 'SET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x999967e2ec8a74b7c8e9db19e039d920b31d39d0',
    currency_name: 'Ties.DB',
    currency_symbol: 'TIE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x255aa6df07540cb5d3d297f0d0d4d84cb52bc8e6',
    currency_name: 'Raiden Network Token',
    currency_symbol: 'RDN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x41ad4093349c8a60de591a3c37dcd184558eaae3',
    currency_name: 'Bitcoin & Company Network',
    currency_symbol: 'BITN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7788d759f21f53533051a9ae657fa05a1e068fc6',
    currency_name: 'FLETA',
    currency_symbol: 'FLETA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c872500c00565505f3624ab435c222e558e9ff8',
    currency_name: 'CoTrader',
    currency_symbol: 'COT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.45d493a6f73fa5f404244a5fb8472fc014ca5885',
    currency_name: 'Apex',
    currency_symbol: 'CPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000003',
    currency_name: 'Dogecoin',
    currency_symbol: 'DOGE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x00059ae69c1622a7542edc15e8d17b060fe307b6',
    currency_name: 'AmonD',
    currency_symbol: 'AMON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x420167d87d35c3a249b32ef6225872fbd9ab85d2',
    currency_name: 'MESG',
    currency_symbol: 'MESG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002b.ecobit',
    currency_name: 'Ecobit',
    currency_symbol: 'ECOB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MZK-2C7',
    currency_name: 'Muzika',
    currency_symbol: 'MZK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb4a677b0e363c3815d46326954a4e4d2b1ace357',
    currency_name: 'THENODE',
    currency_symbol: 'THE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x241ba672574a78a3a604cdd0a94429a73a84a324',
    currency_name: '4NEW',
    currency_symbol: 'KWATT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002a',
    currency_name: 'Decred',
    currency_symbol: 'DCR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x48783486ddD7fa85ECa6B0C4AE8920Bc25DfbcD7',
    currency_name: 'AnimalGo',
    currency_symbol: 'GOM2',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007cf',
    currency_name: 'ColossusXT',
    currency_symbol: 'COLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1519aff03b3e23722511d2576c769a77baf09580',
    currency_name: 'B91',
    currency_symbol: 'B91',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x108d27f9c4b2a98c025c94c76ca78c6ce6c7a4eb',
    currency_name: 'Metaprediction',
    currency_symbol: 'METP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe0b9bcd54bf8a730ea5d3f1ffce0885e911a502c',
    currency_name: 'ZUM TOKEN',
    currency_symbol: 'ZUM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7d3cb11f8c13730c24d01826d8f2005f0e1b348f',
    currency_name: 'COS',
    currency_symbol: 'COS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6ec47a178a9d50d4ec4683003d8324f19ca35382',
    currency_name: 'NDN Link',
    currency_symbol: 'NDN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9d8be94d0612170ce533ac4d7b43cc3cd91e5a1a',
    currency_name: 'BQT',
    currency_symbol: 'BQTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3810a4ddf41e586fa0dba1463a7951b748cecfca',
    currency_name: 'MenaPay',
    currency_symbol: 'MPAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000070',
    currency_name: 'Ultimate Secure Cash',
    currency_symbol: 'USC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbe6ac6b50f577205c9d107f37b6e205aa6acc5d4',
    currency_name: 'Unification',
    currency_symbol: 'UND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.IDRTB-178',
    currency_name: 'Rupiah Token',
    currency_symbol: 'IDRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5af2be193a6abca9c8817001f45744777db30756',
    currency_name: 'Voyager Token',
    currency_symbol: 'VGX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.eosiomeetone',
    currency_name: 'MEET.ONE',
    currency_symbol: 'MEETONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2859021ee7f2cb10162e67f33af2d22764b31aff',
    currency_name: 'Silent Notary',
    currency_symbol: 'SNTR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc7bba5b765581efb2cdd2679db5bea9ee79b201f',
    currency_name: 'Gems ',
    currency_symbol: 'GEM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000018',
    currency_name: 'Primecoin',
    currency_symbol: 'XPM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc528c28fec0a90c083328bc45f587ee215760a0f',
    currency_name: 'Endor Protocol',
    currency_symbol: 'EDR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc05d14442a510de4d3d71a3d316585aa0ce32b50',
    currency_name: 'LINA',
    currency_symbol: 'LINA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb7e77aebbe0687d2eff24cc90c41a3b6ea74bdab',
    currency_name: 'Project WITH',
    currency_symbol: 'WIKEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000190',
    currency_name: 'NIX',
    currency_symbol: 'NIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe8ff5c9c75deb346acac493c463c8950be03dfba',
    currency_name: 'VIBE',
    currency_symbol: 'VIBE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb64ef51c888972c908cfacf59b47c1afbc0ab8ac',
    currency_name: 'Storj',
    currency_symbol: 'STORJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.OBITS',
    currency_name: 'OBITS',
    currency_symbol: 'OBITS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x56d811088235F11C8920698a204A5010a788f4b3',
    currency_name: 'bZx Protocol',
    currency_symbol: 'BZRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6cbedec4f1ac9d874987d2769596544e0d9161ab',
    currency_name: 'DeepCloud AI',
    currency_symbol: 'DEEP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000090',
    currency_name: 'XRP',
    currency_symbol: 'XRP',
    is_active: true,
    addr_extra_info: ['tag'],
  },
  {
    currency_id: 'sygna:0x800022b8',
    currency_name: 'Super Bitcoin',
    currency_symbol: 'SBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2167fb82309cf76513e83b25123f8b0559d6b48f',
    currency_name: 'Coin Lion',
    currency_symbol: 'LION',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.81c089ab996fc89c468a26c0a88d23ae2f34b5c0',
    currency_name: 'Endorsit',
    currency_symbol: 'EDS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x04a020325024f130988782bd5276e53595e8d16e',
    currency_name: 'Herbalist Token',
    currency_symbol: 'HERB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GDSTRSHXHGJ7ZIVRBXEYE5Q74XUVCUSEKEBR7UCHEUUEK72N7I7KJ6JH',
    currency_name: 'Stronghold Token',
    currency_symbol: 'SHX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.b951ecbbc5fe37a9c280a76cb0ce0014827294cf',
    currency_name: 'DeepBrain Chain',
    currency_symbol: 'DBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca',
    currency_name: 'Binance Coin',
    currency_symbol: 'BNB',
    is_active: true,
    addr_extra_info: ['memo'],
  },
  {
    currency_id: 'sygna:0x8000003c.0x27201232579491Ce9b116Ac6F37D354Cc723A2f3',
    currency_name: 'MESEFA',
    currency_symbol: 'SEFA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2822f6D1B2f41F93f33d937bc7d84A8Dfa4f4C21',
    currency_name: 'Poseidon Network',
    currency_symbol: 'QQQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1245ef80f4d9e02ed9425375e8f649b9221b31d8',
    currency_name: 'ArbitrageCT',
    currency_symbol: 'ARCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4730fb1463a6f1f44aeb45f6c5c422427f37f4d0',
    currency_name: '4THPILLAR TECHNOLOGIES',
    currency_symbol: 'FOUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4ac00f287f36a6aad655281fe1ca6798c9cb727b',
    currency_name: 'GazeCoin',
    currency_symbol: 'GZE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfd8971d5e8e1740ce2d0a84095fca4de729d0c16',
    currency_name: 'Zilla',
    currency_symbol: 'ZLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4a57e687b9126435a9b19e4a802113e266adebde',
    currency_name: 'Flexacoin',
    currency_symbol: 'FXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfae4ee59cdd86e3be9e8b90b53aa866327d7c090',
    currency_name: 'CPChain',
    currency_symbol: 'CPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb4272071ecadd69d933adcd19ca99fe80664fc08',
    currency_name: 'CryptoFranc',
    currency_symbol: 'XCHF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8a9c67fee641579deba04928c4bc45f66e26343a',
    currency_name: 'Jarvis Network',
    currency_symbol: 'JRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a66e09f7dccc10eae46e27cfa6b8d44a50df1e7',
    currency_name: 'PRASM',
    currency_symbol: 'PSM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800003e9',
    currency_name: 'Thunder Token',
    currency_symbol: 'TT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MEETONE-031',
    currency_name: 'MEET.ONE',
    currency_symbol: 'MEETONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000008',
    currency_name: 'Feathercoin',
    currency_symbol: 'FTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6710c63432a2de02954fc0f851db07146a6c0312',
    currency_name: 'SyncFab',
    currency_symbol: 'MFG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.Bi4w2UuGRt2jAJFfRb8b3SwDUV5x8krCzX2zZHcRfPNc',
    currency_name: 'CryptoPing',
    currency_symbol: 'PING',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c5887e55bbe41472acdba5fae989788c6f7ab59',
    currency_name: 'STK Coin',
    currency_symbol: 'STK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001ad',
    currency_name: 'Ergo',
    currency_symbol: 'ERG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c4e8f2d746113d0696ce89b35f0d8bf88e0aeca',
    currency_name: 'OST',
    currency_symbol: 'OST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdd16ec0f66e54d453e6756713e533355989040e4',
    currency_name: 'Tokenomy',
    currency_symbol: 'TEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f612a09ead55bb81b6534e80ed5a21bf0a27b16',
    currency_name: 'EUNOMIA',
    currency_symbol: 'ENTS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb5a4ac5b04e777230ba3381195eff6a60c3934f2',
    currency_name: 'inSure',
    currency_symbol: 'SURE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000019e',
    currency_name: 'Sumokoin',
    currency_symbol: 'SUMO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeb7c20027172e5d143fb030d50f91cece2d1485d',
    currency_name: 'eBitcoin',
    currency_symbol: 'EBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d21ef5f25a985380b65c8e943a0082feda0db84',
    currency_name: 'Ethereum Cash',
    currency_symbol: 'ECASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000089.0x2acc95758f8b5f583470ba265eb685a8f45fc9d5',
    currency_name: 'RSK Infrastructure Framework',
    currency_symbol: 'RIF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd6875274b000462f59e9327cbde2cef637914569',
    currency_name: 'QUSD',
    currency_symbol: 'QUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4c14114c107d6374ec31981f5f6cc27a13e22f9a',
    currency_name: 'SBank',
    currency_symbol: 'STS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4eeea7b48b9c3ac8f70a9c932a8b1e8a5cb624c7',
    currency_name: 'Membrana',
    currency_symbol: 'MBN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8ad6739649f1fbf079882c14d27862d5c2206660',
    currency_name: 'IOU',
    currency_symbol: 'IOUX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GDOEVDDBU6OBWKL7VHDAOKD77UP4DKHQYKOKJJT5PR3WRDBTX35HUEUX',
    currency_name: 'Doge Token',
    currency_symbol: 'DOGET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b20dabcec77f6289113e61893f7beefaeb1990a',
    currency_name: 'FairGame',
    currency_symbol: 'FAIR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f8794f66C7170c4f9163a8498371A747114f6C4',
    currency_name: 'FLAMA',
    currency_symbol: 'FMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7728dFEF5aBd468669EB7f9b48A7f70a501eD29D',
    currency_name: 'Paragon',
    currency_symbol: 'PRG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe541b34f73a4789a033a962ad43655221b4e516e',
    currency_name: 'Creatanium',
    currency_symbol: 'CMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x73Cee8348b9bDd48c64E13452b8a6fbc81630573',
    currency_name: 'Egoras',
    currency_symbol: 'EGR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4DF812F6064def1e5e029f1ca858777CC98D2D81',
    currency_name: 'Xaurum',
    currency_symbol: 'XAUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000006',
    currency_name: 'Peercoin',
    currency_symbol: 'PPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.VDX-A17',
    currency_name: 'Vodi X',
    currency_symbol: 'VDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe7d3e4413e29ae35b0893140f4500965c74365e5',
    currency_name: 'TraDove B2BCoin',
    currency_symbol: 'BBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb351da6ffebd5dddd1da037929fcf334d6b4a8d5',
    currency_name: 'Flit Token',
    currency_symbol: 'FLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8c9e4cf756b9d01d791b95bc2d0913ef2bf03784',
    currency_name: 'Aerotoken',
    currency_symbol: 'AET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x697ef32b4a3f5a4c39de1cb7563f24ca7bfc5947',
    currency_name: 'Insula',
    currency_symbol: 'ISLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd8698a985b89650d0a70f99ad2909bd0c0b4b51c',
    currency_name: 'Consentium',
    currency_symbol: 'CSM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xee9e5eff401ee921b138490d00ca8d1f13f67a72',
    currency_name: 'Asian Fintech',
    currency_symbol: 'AFIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9cb1aeafcc8a9406632c5b084246ea72f62d37b6',
    currency_name: 'LBK',
    currency_symbol: 'LBK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5edc1a266e8b2c5e8086d373725df0690af7e3ea',
    currency_name: 'YottaChain',
    currency_symbol: 'YTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5aaefe84e0fb3dd1f0fcff6fa7468124986b91bd',
    currency_name: 'Evedo',
    currency_symbol: 'EVED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000f6',
    currency_name: 'Energy Web Token',
    currency_symbol: 'EWT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x998FFE1E43fAcffb941dc337dD0468d52bA5b48A',
    currency_name: 'Rupiah Token',
    currency_symbol: 'IDRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000e0',
    currency_name: 'SmartCash',
    currency_symbol: 'SMART',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000012e',
    currency_name: 'CyberMiles',
    currency_symbol: 'CMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0d5516103752b3954d95621f470a8261151da2e4',
    currency_name: 'Bloomzed Token',
    currency_symbol: 'BZT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb2e260f12406c401874ecc960893c0f74cd6afcd',
    currency_name: 'BitUP Token',
    currency_symbol: 'BUT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x646b41183bb0d18c01f75f630688d613a5774dc7',
    currency_name: 'Bluekey',
    currency_symbol: 'BKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003a',
    currency_name: 'SolarCoin',
    currency_symbol: 'SLR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8cc3e2bdc17682c622eb789eda23fbd6988c84da',
    currency_name: 'Innovative Bioresearch Classic',
    currency_symbol: 'INNBCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70861e862e1ac0c96f853c8231826e469ead37b1',
    currency_name: 'DOS Network',
    currency_symbol: 'DOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9d1a62c2ad99019768b9126fda004a9952853f6e',
    currency_name: '3X Long BNB Token',
    currency_symbol: 'BNBBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7995ab36bB307Afa6A683C24a25d90Dc1Ea83566',
    currency_name: 'HitChain',
    currency_symbol: 'HIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000144',
    currency_name: 'eBoost',
    currency_symbol: 'EBST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb7c4a82936194fee52a4e3d4cec3415f74507532',
    currency_name: 'iBTC',
    currency_symbol: 'IBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe884cc2795b9c45beeac0607da9539fd571ccf85',
    currency_name: 'Ultiledger',
    currency_symbol: 'ULT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd82df0abd3f51425eb15ef7580fda55727875f14',
    currency_name: 'DAV Coin',
    currency_symbol: 'DAV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6de037ef9ad2725eb40118bb1702ebb27e4aeb24',
    currency_name: 'Render Token',
    currency_symbol: 'RNDR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf83301c5cd1ccbb86f466a6b3c53316ed2f8465a',
    currency_name: 'COMSA [ETH]',
    currency_symbol: 'CMS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9c794f933b4dd8b49031a79b0f924d68bef43992',
    currency_name: 'XTRD',
    currency_symbol: 'XTRD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xedd7c94fd7b4971b916d15067bc454b9e1bad980',
    currency_name: 'Zippie',
    currency_symbol: 'ZIPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe7976c4Efc60d9f4C200Cc1bCEF1A1e3B02c73e7',
    currency_name: 'MAX Exchange Token',
    currency_symbol: 'MAX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a7',
    currency_name: 'FirstCoin',
    currency_symbol: 'FRST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc690f7c7fcffa6a82b79fab7508c466fefdfc8c5',
    currency_name: 'Lympo',
    currency_symbol: 'LYM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x757703bD5B2c4BBCfde0BE2C0b0E7C2f31FCf4E9',
    currency_name: 'Thar Token',
    currency_symbol: 'THAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb41f09a973a85c7f497c10b00a939de667b55a78',
    currency_name: 'KNOW',
    currency_symbol: 'KNOW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800003e6',
    currency_name: 'Lightning Bitcoin',
    currency_symbol: 'LBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfb2f26f266fb2805a387230f2aa0a331b4d96fba',
    currency_name: 'Edge',
    currency_symbol: 'DADI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000800',
    currency_name: 'Mcashchain',
    currency_symbol: 'MCASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4e12eb8e506ccd1427f6b8f7faa3e88fb698eb28',
    currency_name: 'Jack Token',
    currency_symbol: 'JACK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0ebb614204e47c09b6c3feb9aaecad8ee060e23e',
    currency_name: 'Cryptopay',
    currency_symbol: 'CPAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9bbc9286cdf6eefebf21df19bac71c6bdd7759d4',
    currency_name: 'QUEENBEE',
    currency_symbol: 'QBZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.bnb1rgylg0f3ka24a63rnq926quvet438fxrz3320c',
    currency_name: 'EarnBet',
    currency_symbol: 'BET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x633eE3fbE5ffc05bD44Ecd8240732fF9ef9Dee1d',
    currency_name: 'MarketPeak',
    currency_symbol: 'PEAK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70968feaf13299d0dbf78f66860bab9dbe3856bc',
    currency_name: 'Treelion',
    currency_symbol: 'TRN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x68d57c9a1c35f63e2c83ee8e49a64e9d70528d25',
    currency_name: 'SIRIN LABS Token',
    currency_symbol: 'SRN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4fbb350052bca5417566f188eb2ebce5b19bc964',
    currency_name: 'RigoBlock',
    currency_symbol: 'GRG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe99a894a69d7c2e3c92e61b64c505a6a57d2bc07',
    currency_name: 'Hyperion',
    currency_symbol: 'HYN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3f8a2f7bcd70e7f7bdd3fbb079c11d073588dea2',
    currency_name: 'Fireball',
    currency_symbol: 'FIRE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000203',
    currency_name: 'Metaverse Dualchain Network Architecture',
    currency_symbol: 'DNA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ba',
    currency_name: 'Veil',
    currency_symbol: 'VEIL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.WRX-ED1',
    currency_name: 'WazirX',
    currency_symbol: 'WRX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x19ca83a13b4c4be43fa82c5e415e16f1d86f57f7',
    currency_name: 'bitCEO',
    currency_symbol: 'BCEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xced4e93198734ddaff8492d525bd258d49eb388e',
    currency_name: 'Eidoo',
    currency_symbol: 'EDO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x725440512cb7b78bf56b334e50e31707418231cb',
    currency_name: 'DEXA COIN',
    currency_symbol: 'DEXA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000006d',
    currency_name: 'Pesetacoin',
    currency_symbol: 'PTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb31c219959e06f9afbeb36b388a4bad13e802725',
    currency_name: 'ONOToken',
    currency_symbol: 'ONOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3a1bda28adb5b0a812a7cf10a1950c920f79bcd3',
    currency_name: 'FLIP',
    currency_symbol: 'FLP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x51db5ad35c671a87207d88fc11d593ac0c8415bd',
    currency_name: 'Moeda Loyalty Points',
    currency_symbol: 'MDA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xE14A603f7c77d4101A87859b8736a04CFD85C688',
    currency_name: 'TENA',
    currency_symbol: 'TENA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0affa06e7fbe5bc9a764c979aa66e8256a631f02',
    currency_name: 'Polybius',
    currency_symbol: 'PLBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff18dbc487b4c2e3222d115952babfda8ba52f5f',
    currency_name: 'LIFE',
    currency_symbol: 'LIFE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x03c780cd554598592b97b7256ddaad759945b125',
    currency_name: 'Biotron',
    currency_symbol: 'BTRN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f8f72aa9304c8b593d555f12ef6589cc3a579a2',
    currency_name: 'Maker',
    currency_symbol: 'MKR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0d88ed6e74bbfd96b831231638b66c05571e824f',
    currency_name: 'Aventus',
    currency_symbol: 'AVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4162178b78d6985480a308b2190ee5517460406d',
    currency_name: 'Colu Local Network',
    currency_symbol: 'CLN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1FA3bc860bF823d792f04F662f3AA3a500a68814',
    currency_name: '1x Short Bitcoin Token',
    currency_symbol: 'HEDGE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x91e64f39c1fe14492e8fdf5a8b0f305bd218c8a1',
    currency_name: 'Vodi X',
    currency_symbol: 'VDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001b2',
    currency_name: 'Kusama',
    currency_symbol: 'KSM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x61bfc979ea8160ede9b862798b7833a97bafa02a',
    currency_name: 'Release Project',
    currency_symbol: 'REL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8D75959f1E61EC2571aa72798237101F084DE63a',
    currency_name: 'Substratum',
    currency_symbol: 'SUB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.1002000',
    currency_name: 'BitTorrent',
    currency_symbol: 'BTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xca00bc15f67ebea4b20dfaaa847cace113cc5501',
    currency_name: 'CapdaxToken',
    currency_symbol: 'XCD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0af44e2784637218dd1d32a322d44e603a8f0c6a',
    currency_name: 'Matryx',
    currency_symbol: 'MTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd36e9f8f194a47b10af16c7656a68eba1dfe88e4',
    currency_name: 'Atlas Token',
    currency_symbol: 'ATLS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe469c4473af82217b30cf17b10bcdb6c8c796e75',
    currency_name: 'EXRNchain',
    currency_symbol: 'EXRN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x89Fb927240750c1B15d4743cd58440fc5f14A11C',
    currency_name: 'Attila',
    currency_symbol: 'ATT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.0x235639f72e127bbdd1509bfc9dc6e2caeb3fb741',
    currency_name: 'AS Roma Fan Token',
    currency_symbol: 'ASR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x87210f1d3422ba75b6c40c63c78d79324dabcd55',
    currency_name: 'EOS TRUST',
    currency_symbol: 'EOST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000270f',
    currency_name: 'Bitcoin God',
    currency_symbol: 'GOD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2d3e7d4870a51b918919e7b851fe19983e4c38d5',
    currency_name: 'Ubcoin Market',
    currency_symbol: 'UBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x622dffcc4e83c64ba959530a5a5580687a57581b',
    currency_name: 'Cube',
    currency_symbol: 'AUTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000d35',
    currency_name: 'Dynamic',
    currency_symbol: 'DYN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2a469a22101f1f672b745d729d13ab8dbd203c9b',
    currency_name: 'Coinsto',
    currency_symbol: 'CSO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8207c1ffc5b6804f6024322ccf34f29c3541ae26',
    currency_name: 'Origin Protocol',
    currency_symbol: 'OGN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2604fa406be957e542beb89e6754fcde6815e83f',
    currency_name: 'Playkey',
    currency_symbol: 'PKT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0cbc9b02b8628ae08688b5cc8134dc09e36c443b',
    currency_name: 'Tratok',
    currency_symbol: 'TRAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x687bfc3e73f6af55f0ccca8450114d107e781a0e',
    currency_name: 'QChi',
    currency_symbol: 'QCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd7394087e1dbbe477fe4f1cf373b9ac9459565ff',
    currency_name: 'RealTract',
    currency_symbol: 'RET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe9fa21e671bcfb04e6868784b89c19d5aa2424ea',
    currency_name: 'EurocoinToken',
    currency_symbol: 'ECTE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8f3470A7388c05eE4e7AF3d01D8C722b0FF52374',
    currency_name: 'Veritaseum',
    currency_symbol: 'VERI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000183',
    currency_name: 'Quebecoin',
    currency_symbol: 'QBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.TROY-9B8',
    currency_name: 'TROY',
    currency_symbol: 'TROY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd341d1680eeee3255b8c4c75bcce7eb57f144dae',
    currency_name: 'SoMee.Social',
    currency_symbol: 'ONG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1f35a281036be57e64e7e7a2a556b4f888a1b829',
    currency_name: 'Muzika',
    currency_symbol: 'MZK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x40FD72257597aA14C7231A7B1aaa29Fce868F677',
    currency_name: 'Sora',
    currency_symbol: 'XOR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x84f7c44b6fed1080f647e354d552595be2cc602f',
    currency_name: 'Bigbom',
    currency_symbol: 'BBO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x10BC518c32fbAE5e38Ecb50A612160571bD81e44',
    currency_name: 'VeraOne',
    currency_symbol: 'VRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbbff862d906e348e9946bfb2132ecb157da3d4b4',
    currency_name: 'Sharder',
    currency_symbol: 'SS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x86e6a4f512b1290c043970b04e0b570d4fc98291',
    currency_name: 'IntelliShare',
    currency_symbol: 'INE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000110',
    currency_name: 'IPChain',
    currency_symbol: 'IPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd4a293ae8bb9e0be12e99eb19d48239e8c83a136',
    currency_name: 'Insureum',
    currency_symbol: 'ISR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd01db73e047855efb414e6202098c4be4cd2423b',
    currency_name: 'Uquid Coin',
    currency_symbol: 'UQC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x78b7fada55a64dd895d8c8c35779dd8b67fa8a05',
    currency_name: 'ATLANT',
    currency_symbol: 'ATL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe8663a64a96169ff4d95b4299e7ae9a76b905b31',
    currency_name: 'DPRating',
    currency_symbol: 'RATING',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaa7a9ca87d3694b5755f213b5d04094b8d0f0a6f',
    currency_name: 'OriginTrail',
    currency_symbol: 'TRAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.CNY',
    currency_name: 'bitCNY',
    currency_symbol: 'BITCNY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe530441f4f73bDB6DC2fA5aF7c3fC5fD551Ec838',
    currency_name: 'GSENetwork',
    currency_symbol: 'GSE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f1ed66c251bcb52ecf7e67ac64bb72482048adb',
    currency_name: 'SEER',
    currency_symbol: 'SEER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xca694eb79ef355ea0999485d211e68f39ae98493',
    currency_name: 'Traceability Chain',
    currency_symbol: 'TAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x20910e5b5f087f6439dfcb0dda4e27d1014ac2b8',
    currency_name: 'Bananatok',
    currency_symbol: 'BNA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdb096cc19b8227e2115855c5b39dcc247470013c',
    currency_name: 'Bitfex',
    currency_symbol: 'BFX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000bbb',
    currency_name: 'LUXCoin',
    currency_symbol: 'LUX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TVQ6jYV5yTtRsKcD8aRc1a4Kei4V45ixLn',
    currency_name: 'IG Gold',
    currency_symbol: 'IGG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000014',
    currency_name: 'DigiByte',
    currency_symbol: 'DGB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x17e67d1cb4e349b9ca4bc3e17c7df2a397a7bb64',
    currency_name: 'Freyrchain',
    currency_symbol: 'FREC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x979ebc09e55ea0ab563cf7175e4c4b1a03afc19a',
    currency_name: 'UTEMIS',
    currency_symbol: 'UTS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b1e1fc958b83e801d1342f9f9ba7da3a55ba1ef',
    currency_name: 'Tronipay',
    currency_symbol: 'TRP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7703c35cffdc5cda8d27aa3df2f9ba6964544b6e',
    currency_name: 'Pylon Network',
    currency_symbol: 'PYLNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c5b760f133220855340003b43cc9113ec494823',
    currency_name: 'Cointorox',
    currency_symbol: 'OROX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f237d5ea7876e0e2906034d98fdb20d43666ad4',
    currency_name: 'Connect Coin',
    currency_symbol: 'XCON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff44b5719f0b77a9951636fc5e69d3a1fc9e7d73',
    currency_name: '4ART Coin',
    currency_symbol: '4ART',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8fC01E6CbDfFaf09B54F423f9Bb1F856b22e47b2',
    currency_name: 'Obitan Chain',
    currency_symbol: 'OBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.QMUDYjQpjkxZjjfEdKznqsim7VMS5LZzqp',
    currency_name: 'Qbao',
    currency_symbol: 'QBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x99ea4db9ee77acd40b119bd1dc4e33e1c070b80d',
    currency_name: 'Quantstamp',
    currency_symbol: 'QSP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x001f0aa5da15585e5b2305dbab2bac425ea71007',
    currency_name: 'IP Exchange',
    currency_symbol: 'IPSX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8752bf7ad53d25a4165b9370f2becc22dd8ae838',
    currency_name: 'BlockNoteX',
    currency_symbol: 'BNOX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1u9j9hkst6gf09dkdvxlj7puk8c7vh68a0kkmht',
    currency_name: 'Contentos',
    currency_symbol: 'COS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4e352cf164e64adcbad318c3a1e222e9eba4ce42',
    currency_name: 'MCDEX',
    currency_symbol: 'MCB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa645264c5603e96c3b0b078cdab68733794b0a71',
    currency_name: 'Mysterium',
    currency_symbol: 'MYST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6ca88cc8d9288f5cad825053b6a1b179b05c76fc',
    currency_name: 'Universal Protocol Token',
    currency_symbol: 'UPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x69d2779533a4d2c780639713558b2cc98c46a9b7',
    currency_name: 'VNT Chain',
    currency_symbol: 'VNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TLa2f6VPqDgRE67v1736s7bJ8Ray5wYjU7',
    currency_name: 'WINk',
    currency_symbol: 'WIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB41422D5a1d5d5C73c229686935b40F881502785',
    currency_name: 'Payfair',
    currency_symbol: 'PFR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3a10b7a22ae98e0f53276923f19f99b259f61778',
    currency_name: 'Secrets of Zurich',
    currency_symbol: 'SOZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x64a60493d888728cf42616e034a0dfeae38efcf0',
    currency_name: 'OneLedger',
    currency_symbol: 'OLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1453dbb8a29551ade11d89825ca812e05317eaeb',
    currency_name: 'Tendies',
    currency_symbol: 'TEND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf7b098298f7c69fc14610bf71d5e02c60792894c',
    currency_name: 'Matchpool',
    currency_symbol: 'GUP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001f3',
    currency_name: 'Rupaya',
    currency_symbol: 'RUPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe11609b9a51caf7d32a55896386ac52ed90e66f1',
    currency_name: 'CMITCOIN',
    currency_symbol: 'CMIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000035',
    currency_name: 'DopeCoin',
    currency_symbol: 'DOPE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb8B7791b1A445FB1e202683a0a329504772e0E52',
    currency_name: 'Student Coin',
    currency_symbol: 'STC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x88d50b466be55222019d71f9e8fae17f5f45fca1',
    currency_name: 'Cryptaur',
    currency_symbol: 'CPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d4d57cd06fa7fe99e26fdc481b468f77f05073c',
    currency_name: 'NetKoin',
    currency_symbol: 'NTK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x182a603541a4483c308475147d621bbb4e2587c6',
    currency_name: 'Zerobank',
    currency_symbol: 'ZB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x301C755bA0fcA00B1923768Fffb3Df7f4E63aF31',
    currency_name: 'Global Digital Content',
    currency_symbol: 'GDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9af4f26941677c706cfecf6d3379ff01bb85d5ab',
    currency_name: 'DomRaider',
    currency_symbol: 'DRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdcD85914b8aE28c1E62f1C488E1D968D5aaFfE2b',
    currency_name: 'TOP',
    currency_symbol: 'TOP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x51bc0deaf7bbe82bc9006b0c3531668a4206d27f',
    currency_name: 'RAKUN',
    currency_symbol: 'RAKU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdecf7be29f8832e9c2ddf0388c9778b8ba76af43',
    currency_name: 'BonusCloud',
    currency_symbol: 'BXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe25b0bba01dc5630312b6a21927e578061a13f55',
    currency_name: 'ShipChain',
    currency_symbol: 'SHIP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1dq8ae0ayztqp99peggq5sygzf3n7u2ze4t0jne',
    currency_name: 'CryptoBonusMiles',
    currency_symbol: 'CBM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x56325d180ec3878a9028afc7b0edcee7486cc9df',
    currency_name: 'Fountain',
    currency_symbol: 'FTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2daee1aa61d60a252dc80564499a69802853583a',
    currency_name: 'Authorship',
    currency_symbol: 'ATS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000033',
    currency_name: 'Affil Coin',
    currency_symbol: 'AC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x64cdf819d3e75ac8ec217b3496d7ce167be42e80',
    currency_name: 'VouchForMe',
    currency_symbol: 'IPL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc72ed4445b3fe9f0863106e344e241530d338906',
    currency_name: 'Mallcoin',
    currency_symbol: 'MLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa017ac5fac5941f95010b12570b812c974469c2c',
    currency_name: 'Proxeus',
    currency_symbol: 'XES',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa0008f510fe9ee696e7e320c9e5cbf61e27791ee',
    currency_name: 'GAMB',
    currency_symbol: 'GMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7297862b9670ff015192799cc849726c88bf1d77',
    currency_name: 'SkyMap',
    currency_symbol: 'SKYM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2781246fe707bb15cee3e5ea354e2154a2877b16',
    currency_name: 'ELYSIA',
    currency_symbol: 'EL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x81824663353a9d29b01b2de9dd9a2bb271d298cd',
    currency_name: '1x Long Bitcoin Implied Volatility Token',
    currency_symbol: 'BVOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd44bb6663936cab1310584a277f7daa6943d4904',
    currency_name: 'Winco',
    currency_symbol: 'WCO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x555d051538c7a13712f1f590fa6b4c176ca4529f',
    currency_name: 'iOWN Token',
    currency_symbol: 'iOWN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007d1',
    currency_name: 'MNPCoin',
    currency_symbol: 'MNP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a3',
    currency_name: 'Ellaism',
    currency_symbol: 'ELLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x076641af1b8f06b7f8c92587156143c109002cbe',
    currency_name: 'SoPay',
    currency_symbol: 'SOP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x44449Fa4d607F807d1eD4a69ad942971728391C8',
    currency_name: 'XMCT',
    currency_symbol: 'XMCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xef65887a05415bf6316204b5ffb350d4d1a19bba',
    currency_name: 'ExtStock Token',
    currency_symbol: 'XT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa5b46ff9a887180c8fb2d97146398ddfc5fef1cd',
    currency_name: 'SuperSkynet',
    currency_symbol: 'SSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80001092',
    currency_name: 'Axe',
    currency_symbol: 'AXE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xF29226914595052a04F5AFbe6410D0C3eD707548',
    currency_name: 'NEXT',
    currency_symbol: 'NET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd9af2d11d788da0097076f4eb21bd1c5533743d9',
    currency_name: 'LegalBlock',
    currency_symbol: 'LBK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeb9951021698b42e4399f9cbb6267aa35f82d59d',
    currency_name: 'Winding Tree',
    currency_symbol: 'LIF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000094',
    currency_name: 'Stellar',
    currency_symbol: 'XLM',
    is_active: true,
    addr_extra_info: ['memo'],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8e30ea2329d95802fd804f4291220b0e2f579812',
    currency_name: 'Decentralized Vulnerability Platform',
    currency_symbol: 'DVP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x786001c9c5ca6e502deb8a8a72480d2147891f32',
    currency_name: 'BetProtocol',
    currency_symbol: 'BEPRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcfd6ae8bf13f42de14867351eaff7a8a3b9fbbe7',
    currency_name: 'SINERGIA',
    currency_symbol: 'SNG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5825fba40871aaacba45e4b34629abb86520b807',
    currency_name: 'Chainpay',
    currency_symbol: 'CPAY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1b6c5864375b34af3ff5bd2e5f40bc425b4a8d79',
    currency_name: 'TopChain',
    currency_symbol: 'TOPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.everipediaiq',
    currency_name: 'Everipedia',
    currency_symbol: 'IQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x228ba514309ffdf03a81a205a6d040e429d6e80c',
    currency_name: 'Global Social Chain',
    currency_symbol: 'GSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000157',
    currency_name: 'DECENT',
    currency_symbol: 'DCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.d8dec2b605005749abbf4b060edad3070e23cf5c',
    currency_name: 'Energo',
    currency_symbol: 'TSL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8290333ceF9e6D528dD5618Fb97a76f268f3EDD4',
    currency_name: 'Ankr',
    currency_symbol: 'ANKR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2b591e99afe9f32eaa6214f7b7629768c40eeb39',
    currency_name: 'HEX',
    currency_symbol: 'HEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.40bb36a54bf28872b6ffdfa7fbc6480900e58448',
    currency_name: 'Wowbit',
    currency_symbol: 'WWB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GDGQDVO6XPFSY4NMX75A7AOVYCF5JYGW2SHCJJNWCQWIDGOZB53DGP6C',
    currency_name: 'Ternio',
    currency_symbol: 'TERN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000007',
    currency_name: 'Namecoin',
    currency_symbol: 'NMC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80001a20',
    currency_name: 'Safe',
    currency_symbol: 'SAFE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x965f109d31ccb77005858defae0ebaf7b4381652',
    currency_name: 'BitStash',
    currency_symbol: 'STASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9041fe5b3fdea0f5e4afdc17e75180738d877a01',
    currency_name: 'ProChain',
    currency_symbol: 'PRA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ADI-6BB',
    currency_name: 'Aditus',
    currency_symbol: 'ADI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1f54638b7737193ffd86c19ec51907a7c41755d8',
    currency_name: 'Sola Token',
    currency_symbol: 'SOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7D29A64504629172a429e64183D6673b9dAcbFCe',
    currency_name: 'Vectorspace AI',
    currency_symbol: 'VXV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000057',
    currency_name: 'Gulden',
    currency_symbol: 'NLG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x76974c7b79dc8a6a109fd71fd7ceb9e40eff5382',
    currency_name: 'DOWCOIN',
    currency_symbol: 'DOW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5456bc77dd275c45c3c15f0cf936b763cf57c3b5',
    currency_name: 'Anchor',
    currency_symbol: 'ANCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xef1344bdf80bef3ff4428d8becec3eea4a2cf574',
    currency_name: 'Era Swap',
    currency_symbol: 'ES',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaeaabb69dcb0fe926b1979f0b032fcd17fd7b2e0',
    currency_name: 'CYBR Token',
    currency_symbol: 'CYBR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000205',
    currency_name: 'Sierracoin',
    currency_symbol: 'SIERRA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000024',
    currency_name: 'ParkByte',
    currency_symbol: 'PKB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc741f06082aa47f93729070ad0dd95e223bda091',
    currency_name: 'Education Ecosystem',
    currency_symbol: 'LEDU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa90c43e0d6c92b8e6171a829beb38be28a0ad073',
    currency_name: 'Egoras Dollar',
    currency_symbol: 'EUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcfbf70e33d5163e25b0dad73955c1bd9e8cd8ba2',
    currency_name: 'WinStars.live',
    currency_symbol: 'WNL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GDMS6EECOH6MBMCP3FYRYEVRBIV3TQGLOFQIPVAITBRJUMTI6V7A2X6Z',
    currency_name: 'SIX',
    currency_symbol: 'SIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000014f',
    currency_name: 'SwiftCash',
    currency_symbol: 'SWIFT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3fd8f39a962efda04956981c31ab89fab5fb8bc8',
    currency_name: 'Rotharium',
    currency_symbol: 'RTH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba6db13aeae3607d400ddffd675aa4e88ecc9a69',
    currency_name: 'Sensorium',
    currency_symbol: 'SENSO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x464eBE77c293E473B48cFe96dDCf88fcF7bFDAC0',
    currency_name: 'Kryll',
    currency_symbol: 'KRL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9f8f7ea504588a58b8b24b832b5d25a4aeb4706f',
    currency_name: 'Celeum',
    currency_symbol: 'CLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9aab071b4129b083b01cb5a0cb513ce7eca26fa5',
    currency_name: 'HUNT',
    currency_symbol: 'HUNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa4bdb11dc0a2bec88d24a3aa1e6bb17201112ebe',
    currency_name: 'StableUSD',
    currency_symbol: 'USDS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa838be6e4b760e6061d4732d6b9f11bf578f9a76',
    currency_name: 'TV-TWO',
    currency_symbol: 'TTV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1412f6Aa5ADC77C620715BB2a020AA690B85F68A',
    currency_name: 'MargiX',
    currency_symbol: 'MGX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4C133E081dFB5858e39ccA74E69bf603d409e57A',
    currency_name: '3x Long Bitcoin Cash Token',
    currency_symbol: 'BCHBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x83984d6142934bb535793a82adb0a46ef0f66b6d',
    currency_name: 'Remme',
    currency_symbol: 'REM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.EUR',
    currency_name: 'bitEUR',
    currency_symbol: 'BITEUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3c6Da7763cAA0e4b684BbC733f04a8EC08Af3762',
    currency_name: 'MODEL-X-coin',
    currency_symbol: 'MODX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x049399a6b048d52971f7d122ae21a1532722285f',
    currency_name: 'Fire Lotto',
    currency_symbol: 'FLOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e',
    currency_name: 'yearn.finance',
    currency_symbol: 'YFI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8d5682941ce456900b12d47ac06a88b47c764ce1',
    currency_name: 'RightMesh',
    currency_symbol: 'RMESH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.RNO-14E',
    currency_name: 'Earneo',
    currency_symbol: 'SNPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x32d74896f05204d1b6ae7b0a3cebd7fc0cd8f9c7',
    currency_name: 'Kcash',
    currency_symbol: 'KCASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000005b',
    currency_name: 'BitSend',
    currency_symbol: 'BSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.0x6fc212cde3b420733a88496cbdbb15d85beab1ca',
    currency_name: 'Paris Saint-Germain Fan Token',
    currency_symbol: 'PSG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb4efd85c19999d84251304bda99e90b92300bd93',
    currency_name: 'Rocket Pool',
    currency_symbol: 'RPL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CCCX-10D',
    currency_name: 'Clipper Coin',
    currency_symbol: 'CCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe81d72d14b1516e68ac3190a46c93302cc8ed60f',
    currency_name: 'Coinlancer',
    currency_symbol: 'CL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x74faab6986560fd1140508e4266d8a7b87274ffd',
    currency_name: 'HyperDAO',
    currency_symbol: 'HDAO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x78a2a1029e3168b49d3a276c787050ff5106dcf2',
    currency_name: 'EZOOW',
    currency_symbol: 'EZW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf29e46887ffae92f1ff87dfe39713875da541373',
    currency_name: 'Unicrypt',
    currency_symbol: 'UNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa4ea687a2a7f29cf2dc66b39c68e4411c0d00c49',
    currency_name: 'Ivy',
    currency_symbol: 'IVY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000066',
    currency_name: 'Dollarcoin',
    currency_symbol: 'DLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1f3f677ecc58f6a1f9e2cf410df4776a8546b5de',
    currency_name: 'VNDC',
    currency_symbol: 'VNDC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.HzfaJp8YQWLvQG4FkUxq2Q7iYWMYQ2k8UF89vVJAjWPj',
    currency_name: 'Mercury',
    currency_symbol: 'MER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003b',
    currency_name: 'SmileyCoin',
    currency_symbol: 'SMLY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc6abf3c09341741ac6041b0b08195701bdfd460c',
    currency_name: 'Optimal Shelf Availability Token',
    currency_symbol: 'OSA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb1c1Cb8C7c1992dba24e628bF7d38E71daD46aeB',
    currency_name: 'Cloudbric',
    currency_symbol: 'CLB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8de67d55c58540807601dbf1259537bc2dffc84d',
    currency_name: 'Almeela',
    currency_symbol: 'KZE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.www.etherscan.io',
    currency_name: 'Sylo',
    currency_symbol: 'SYLO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc42209accc14029c1012fb5680d95fbd6036e2a0',
    currency_name: 'PayPie',
    currency_symbol: 'PPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x393fac0773c765c80dc887451377d553c46f83b1',
    currency_name: 'RAKSUR',
    currency_symbol: 'RAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.b6c48b3a7c888713dd96eed92a4ee0397dd64e71',
    currency_name: 'PlayCoin [QRC20]',
    currency_symbol: 'PLY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000181',
    currency_name: 'DogeCash',
    currency_symbol: 'DOGEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001c5',
    currency_name: 'Insolar',
    currency_symbol: 'XNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf14922001a2fb8541a433905437ae954419c2439',
    currency_name: 'Digital Insurance Token',
    currency_symbol: 'DIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfdbc1adc26f0f8f8606a5d63b7d3a3cd21c22b23',
    currency_name: '1World',
    currency_symbol: '1WO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000e2',
    currency_name: 'Helium',
    currency_symbol: 'HLM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000654',
    currency_name: 'Atheios',
    currency_symbol: 'ATH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf8c595d070d104377f58715ce2e6c93e49a87f3c',
    currency_name: 'DACC',
    currency_symbol: 'DACC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13c2fab6354d3790d8ece4f0f1a3280b4a25ad96',
    currency_name: 'PHI Token',
    currency_symbol: 'PHI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x554ffc77f4251a9fb3c0e3590a6a205f8d4e067d',
    currency_name: 'ZMINE',
    currency_symbol: 'ZMN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x04f2e7221fdb1b52a68169b25793e51478ff0329',
    currency_name: 'Cappasity',
    currency_symbol: 'CAPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x92e52a1a235d9a103d970901066ce910aacefd37',
    currency_name: 'UNIVERSAL CASH',
    currency_symbol: 'UCASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0d8775f648430679a709e98d2b0cb6250d2887ef',
    currency_name: 'Basic Attention Token',
    currency_symbol: 'BAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7528e3040376edd5db8263db2f5bd1bed91467fb',
    currency_name: 'Simmitri',
    currency_symbol: 'SIM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b0',
    currency_name: 'GoByte',
    currency_symbol: 'GBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3db6ba6ab6f95efed1a6e794cad492faaabf294d',
    currency_name: 'LTO Network',
    currency_symbol: 'LTO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb12c94hfu5vm77a9xkwfyl2ztgwgk503a06zl70e',
    currency_name: 'ShareToken',
    currency_symbol: 'SHR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x21d5678a62dfe63a47062469ebb2fac2817d8832',
    currency_name: 'YoloCash',
    currency_symbol: 'YLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x018d7d179350f1bb9853d04982820e37cce13a92',
    currency_name: 'INMAX',
    currency_symbol: 'INX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x625687081ba9fcbffb0ae6bfe8d7fad6f616f494',
    currency_name: 'Medalte',
    currency_symbol: 'MDTL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8b6cda5cc518c904e8844f445e1a7c7d2db0ff16',
    currency_name: 'SF Capital',
    currency_symbol: 'SFCP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4f27053f32eda8af84956437bc00e5ffa7003287',
    currency_name: 'Thrive Token',
    currency_symbol: 'THRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x89d24a6b4ccb1b6faa2625fe562bdd9a23260359',
    currency_name: 'Single Collateral DAI ',
    currency_symbol: 'SAI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c1c14a6b5074905ce5d367b0a7e098b58ebfd47',
    currency_name: 'FidexToken',
    currency_symbol: 'FEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002cb',
    currency_name: 'SINOVATE',
    currency_symbol: 'SIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfc29b6e626b67776675fff55d5bc0452d042f434',
    currency_name: 'BHEX Token',
    currency_symbol: 'BHT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003d.0x498ae746150fa9ade927808f069e287ab67b62b5',
    currency_name: 'Maya Preferred 223',
    currency_symbol: 'MAPR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TVimprG4oe3i2HapWzPuTh4WtuosRAh5Ew',
    currency_name: 'Tarush',
    currency_symbol: 'TAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x93ed3fbe21207ec2e8f2d3c3de6e058cb73bc04d',
    currency_name: 'Kleros',
    currency_symbol: 'PNK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xF4134146AF2d511Dd5EA8cDB1C4AC88C57D60404',
    currency_name: 'SunContract',
    currency_symbol: 'SNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaa8d0e9A26853D51613ca75729CDE2564913BCfb',
    currency_name: 'Meter',
    currency_symbol: 'MTRG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000194',
    currency_name: 'Bitcoin Incognito',
    currency_symbol: 'XBI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000042',
    currency_name: 'Internet of People',
    currency_symbol: 'IOP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4cc19356f2d37338b9802aa8e8fc58b0373296e7',
    currency_name: 'Selfkey',
    currency_symbol: 'KEY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000d6',
    currency_name: 'Monkey Project',
    currency_symbol: 'MONK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x985dd3d42de1e256d09e1c10f112bccb8015ad41',
    currency_name: 'Ocean Protocol',
    currency_symbol: 'OCEAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf3db7560e820834658b590c96234c333cd3d5e5e',
    currency_name: 'CoinPoker',
    currency_symbol: 'CHP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4a220e6096b25eadb88358cb44068a3248254675',
    currency_name: 'Quant',
    currency_symbol: 'QNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3136ef851592acf49ca4c825131e364170fa32b3',
    currency_name: 'CoinFi',
    currency_symbol: 'COFI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x28ea81fac7b1719138cbf61267198155b433e00e',
    currency_name: 'Cashpayz Token',
    currency_symbol: 'CPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3db99ab08006aefcc9600972eca8c202396b4300',
    currency_name: 'Vinci',
    currency_symbol: 'VINCI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x960b236a07cf122663c4303350609a66a7b288c0',
    currency_name: 'Aragon',
    currency_symbol: 'ANT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x02b1669bc9ee893edaff3cadfd326a294d643f99',
    currency_name: 'Wolfs Group',
    currency_symbol: 'WLF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001e8',
    currency_name: 'Amoveo',
    currency_symbol: 'VEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TMJWuBDvwvcm6QUWXDaC6GqTyq3FgEmWEt',
    currency_name: 'Centric Cash',
    currency_symbol: 'CNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd7631787b4dcc87b1254cfd1e5ce48e96823dee8',
    currency_name: 'Sociall',
    currency_symbol: 'SCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6b785a0322126826d8226d77e173d75dafb84d11',
    currency_name: 'Bankroll Vault',
    currency_symbol: 'VLT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8727c112c712c4a03371ac87a74dd6ab104af768',
    currency_name: 'Jetcoin',
    currency_symbol: 'JET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1akey87kt0r8y3fmhu2l8eyzdjvt9ptl5cppz0v',
    currency_name: 'Bitcoin BEP2',
    currency_symbol: 'BTCB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd3cdc4e75750dc1e59f8342200742b6b29490e70',
    currency_name: 'Decurian',
    currency_symbol: 'ECU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa7f976c360ebbed4465c2855684d1aae5271efa9',
    currency_name: 'TrueFlip',
    currency_symbol: 'TFL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.SPIN-9DD',
    currency_name: 'SPIN Protocol',
    currency_symbol: 'SPIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb8e2e9dddc504688cfe925b9ace8017d48d3e095',
    currency_name: 'Wallabee',
    currency_symbol: 'WLB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd7c49cee7e9188cca6ad8ff264c1da2e69d4cf3b',
    currency_name: 'NXM',
    currency_symbol: 'NXM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb052f8a33d8bb068414eade06af6955199f9f010',
    currency_name: 'Ecoreal Estate',
    currency_symbol: 'ECOREAL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.acbc532904b6b51b5ea6d19b803d78af70e7e6f9',
    currency_name: 'Effect.AI',
    currency_symbol: 'EFX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3918c42f14f2eb1168365f911f63e540e5a306b5',
    currency_name: 'Neural Protocol',
    currency_symbol: 'NRP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x36d10c6800d569bb8c4fe284a05ffe3b752f972c',
    currency_name: 'WeShow Token',
    currency_symbol: 'WET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1C95b093d6C236d3EF7c796fE33f9CC6b8606714',
    currency_name: 'BOMB',
    currency_symbol: 'BOMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6aB4A7d75B0A42B6Bc83E852daB9E121F9C610Aa',
    currency_name: 'Elitium',
    currency_symbol: 'EUM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x04abeda201850ac0124161f037efd70c74ddc74c',
    currency_name: 'NEST Protocol',
    currency_symbol: 'NEST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6fe56c0bcdd471359019fcbc48863d6c3e9d4f41',
    currency_name: 'Props Token',
    currency_symbol: 'PROPS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf6ef343350780bf8c3410bf062e0c015b1dd671',
    currency_name: 'Blackmoon',
    currency_symbol: 'BMC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x045Eb7e34e94B28C7A3641BC5e1A1F61f225Af9F',
    currency_name: 'ZelaaPayAE',
    currency_symbol: 'ZPAE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x174BfA6600Bf90C885c7c01C7031389ed1461Ab9',
    currency_name: 'MGC Token',
    currency_symbol: 'MGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000139',
    currency_name: 'Zilliqa',
    currency_symbol: 'ZIL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfbe878ced08132bd8396988671b450793c44bc12',
    currency_name: 'Fox Trading',
    currency_symbol: 'FOXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x60715e436c37444e29772c0d26a98ae1e8e1a989',
    currency_name: 'Voltz',
    currency_symbol: 'VOLTZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x44197a4c44d6a059297caf6be4f7e172bd56caaf',
    currency_name: 'ELTCOIN',
    currency_symbol: 'ELTCOIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d4abc77b8405ad177d8ac6682d584ecbfd46cec',
    currency_name: 'Primas',
    currency_symbol: 'PST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xebf4ca5319f406602eeff68da16261f1216011b5',
    currency_name: 'Yobit Token',
    currency_symbol: 'YO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.SCOP-GC6OYQJIZF3HFXCYPFCBXYXNGIBQ4TNSFUBUXQJOZWIP6F3YZK4QH3VQ',
    currency_name: 'Scopuly Coin',
    currency_symbol: 'SCOP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc27c95350ecd634c80df89db0f10cd5c24b7b11f',
    currency_name: 'Pixie Coin',
    currency_symbol: 'PXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000010',
    currency_name: 'Rubycoin',
    currency_symbol: 'RBY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5d60d8d7ef6d37e16ebabc324de3be57f135e0bc',
    currency_name: 'MyBit',
    currency_symbol: 'MYB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4d807509aece24c0fa5a102b6a3b059ec6e14392',
    currency_name: 'Menlo One',
    currency_symbol: 'ONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13d0bf45e5f319fa0b58900807049f23cae7c40d',
    currency_name: 'Read',
    currency_symbol: 'READ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000009f',
    currency_name: 'ToaCoin',
    currency_symbol: 'TOA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7abc60b3290f68c85f495fd2e0c3bd278837a313',
    currency_name: 'Cyber Movie Chain',
    currency_symbol: 'CMCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a8',
    currency_name: 'Hellenic Coin',
    currency_symbol: 'HNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5301eae39a4cba1cc2a74e861fded062ca3e3420',
    currency_name: 'Centaure',
    currency_symbol: 'CEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x07ef9e82721ac16809d24dafbe1792ce01654db4',
    currency_name: 'Chimpion',
    currency_symbol: 'BNANA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a4',
    currency_name: 'Pirl',
    currency_symbol: 'PIRL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x22dE9912cd3D74953B1cd1F250B825133cC2C1b3',
    currency_name: 'DREP',
    currency_symbol: 'DREP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1tr49nv08k828n2lqfw0vrgvwj7xtep5kg8wr4c',
    currency_name: 'Ormeus Ecosystem',
    currency_symbol: 'ECO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.etherscan.io',
    currency_name: 'BeautyPayCoin',
    currency_symbol: 'BPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x256845e721c0c46d54e6afbd4fa3b52cb72353ea',
    currency_name: 'UniDollar',
    currency_symbol: 'UNIUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe13ef257cf4d5df928ca11d230427c037666d466',
    currency_name: 'WITChain',
    currency_symbol: 'WIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x454b9f249bc1492ee995793bbc3e57b830f1a5e9',
    currency_name: 'Alphacon',
    currency_symbol: 'ALP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x419c4db4b9e25d6db2ad9691ccb832c8d9fda05e',
    currency_name: 'Dragonchain',
    currency_symbol: 'DRGN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000d9',
    currency_name: 'Megacoin',
    currency_symbol: 'MEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe0b7e882c194881c690924cb46154b8241f9145e',
    currency_name: 'Cofinex Coin',
    currency_symbol: 'CNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xBB1fA4FdEB3459733bF67EbC6f893003fA976a82',
    currency_name: 'Bitnation',
    currency_symbol: 'XPAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80004172.17788804238730072774',
    currency_name: 'Bitswift',
    currency_symbol: 'BITS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc21dBEE65D62770953035f0434C532d578a666c9',
    currency_name: 'CryptoEnergy',
    currency_symbol: 'CNRG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4470bb87d77b963a013db939be332f927f2b992e',
    currency_name: 'AdEx',
    currency_symbol: 'ADX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd0929d411954c47438dc1d871dd6081f5c5e149c',
    currency_name: 'Refereum',
    currency_symbol: 'RFR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd89040ac9823b72f64d71f66fa2deae7c8520671',
    currency_name: 'Newsolution',
    currency_symbol: 'NST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b4',
    currency_name: 'MarteXcoin',
    currency_symbol: 'MXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x763fa6806e1acf68130d2d0f0df754c93cc546b2',
    currency_name: 'Lition',
    currency_symbol: 'LIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.1002413',
    currency_name: 'BeatzCoin',
    currency_symbol: 'BTZC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba14b245d449965bdbeb630ebe135b569474f5b1',
    currency_name: 'Eva Cash',
    currency_symbol: 'EVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2863916c6ebdbbf0c6f02f87b7eb478509299868',
    currency_name: 'SIMBA Storage Token',
    currency_symbol: 'SST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa2a54f1ec1f09316ef12c1770d32ed8f21b1fb6a',
    currency_name: 'DigiFinexToken',
    currency_symbol: 'DFT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000332',
    currency_name: 'VeChain',
    currency_symbol: 'VET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaec7d1069e3a914a3eb50f0bfb1796751f2ce48a',
    currency_name: 'S4FE',
    currency_symbol: 'S4F',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000a0',
    currency_name: 'Bitcore',
    currency_symbol: 'BTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000043',
    currency_name: 'Nexus',
    currency_symbol: 'NXS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB6259685685235c1eF4B8529e7105f00BD42b9f8',
    currency_name: 'RRCoin',
    currency_symbol: 'RRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1367d4a67c1719b58c7e05df8768226fa768279a',
    currency_name: 'OOOBTC TOKEN',
    currency_symbol: 'OBX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.SWZL-E0C',
    currency_name: 'Swapzilla',
    currency_symbol: 'SWZL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b5',
    currency_name: 'WINCOIN',
    currency_symbol: 'WC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.LAMB-46C',
    currency_name: 'Lambda',
    currency_symbol: 'LAMB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x05ac103f68e05da35e78f6165b9082432fe64b58',
    currency_name: 'eToro Euro',
    currency_symbol: 'EURX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff0e5e014cf97e0615cb50f6f39da6388e2fae6e',
    currency_name: 'Origo',
    currency_symbol: 'OGO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0e22734e078d6e399bcee40a549db591c4ea46cb',
    currency_name: 'Streamity',
    currency_symbol: 'STM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7de91b204c1c737bcee6f000aaa6569cf7061cb7',
    currency_name: 'Robonomics.network',
    currency_symbol: 'XRT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcc13fc627effd6e35d2d2706ea3c4d7396c610ea',
    currency_name: 'IDEX Membership',
    currency_symbol: 'IDXM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4672bad527107471cb5067a887f4656d585a8a31',
    currency_name: 'Dropil',
    currency_symbol: 'DROP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000bb',
    currency_name: 'STRAKS',
    currency_symbol: 'STAK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000031',
    currency_name: 'Global Currency Reserve',
    currency_symbol: 'GCR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000027',
    currency_name: 'Moin',
    currency_symbol: 'MOIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x47da42696a866cdc61a4c809a515500a242909c1',
    currency_name: 'BitRewards',
    currency_symbol: 'BIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1966d718A565566e8E202792658D7b5Ff4ECe469',
    currency_name: 'nDEX',
    currency_symbol: 'NDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0198f46f520F33cd4329bd4bE380a25a90536CD5',
    currency_name: 'PlayChip',
    currency_symbol: 'PLA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba3a79d758f19efe588247388754b8e4d6edda81',
    currency_name: 'VeriSafe',
    currency_symbol: 'VSF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08aa0ed0040736dd28d4c8b16ab453b368248d19',
    currency_name: 'Cryptobuyer',
    currency_symbol: 'XPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.pizzatotoken',
    currency_name: 'Pizza',
    currency_symbol: 'PIZZA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4D6b9f281AF31916a0f16D1cEA2ec7384851EAAb',
    currency_name: 'Neuromorphic.io',
    currency_symbol: 'NMP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x954b890704693af242613edef1b603825afcd708',
    currency_name: 'Cardstack',
    currency_symbol: 'CARD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcecede5a20645eac6ca2032eeeb1063572d63c29',
    currency_name: 'P2P Global Network',
    currency_symbol: 'P2PX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbcdfe338d55c061c084d81fd793ded00a27f226d',
    currency_name: 'Decentralized Machine Learning',
    currency_symbol: 'DML',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9992ec3cf6a55b00978cddf2b27bc6882d88d1ec',
    currency_name: 'Polymath',
    currency_symbol: 'POLY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5adc961d6ac3f7062d2ea45fefb8d8167d44b190',
    currency_name: 'Dether',
    currency_symbol: 'DTH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9b62513c8a27290cf6a7a9e29386e600245ea819',
    currency_name: 'Contents Protocol',
    currency_symbol: 'CPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd97579Cea3fE2473682a4C42648134BB982433B9',
    currency_name: 'LightChain',
    currency_symbol: 'LIGHT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x80bD0cc689c206e3F642919244c4251c7Ef19852',
    currency_name: 'Sudan Gold Coin',
    currency_symbol: 'SGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000cc',
    currency_name: 'Bytecoin',
    currency_symbol: 'BCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4618519de4c304f3444ffa7f812dddc2971cc688',
    currency_name: 'Kind Ads Token',
    currency_symbol: 'KIND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9469d013805bffb7d3debe5e7839237e535ec483',
    currency_name: 'Darwinia Network',
    currency_symbol: 'RING',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000403',
    currency_name: 'Mochimo',
    currency_symbol: 'MCM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x19ea630bcbc1a511a16e65b6ecd447c92e1c087c',
    currency_name: 'CARAT',
    currency_symbol: 'CARAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000379',
    currency_name: 'TomoChain',
    currency_symbol: 'TOMO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x737f98ac8ca59f2c68ad658e3c3d8c8963e40a4c',
    currency_name: 'Amon',
    currency_symbol: 'AMN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5a1a29dbb6ad6153db764568c1289076bc876df6',
    currency_name: 'Nework',
    currency_symbol: 'NKC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3c45b24359fb0e107a4eaa56bd0f2ce66c99a0e5',
    currency_name: 'Apple Network',
    currency_symbol: 'ANK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb2f7eb1f2c37645be61d73953035360e768d81e6',
    currency_name: 'Cobinhood',
    currency_symbol: 'COB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB422e605fBd765B80D2C4b5d8196C2f94144438B',
    currency_name: '3x Short Litecoin Token',
    currency_symbol: 'LTCBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb5ca46cf1da09248126682a7bd72401fd7a6b151',
    currency_name: 'Provoco Token',
    currency_symbol: 'VOCO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb9e7f8568e08d5659f5d29c4997173d84cdf2607',
    currency_name: 'Swarm City',
    currency_symbol: 'SWT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xaae81c0194d6459f320b70ca0cedf88e11a242ce',
    currency_name: 'WeToken',
    currency_symbol: 'WT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000076d',
    currency_name: 'CaluraCoin',
    currency_symbol: 'CLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe120c1ecbfdfea7f0a8f0ee30063491e8c26fedf',
    currency_name: 'Suretly',
    currency_symbol: 'SUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.4LHHvYGNKJUg5hj65aGD5vgScvCBmLpdRFtjokvCjSL8',
    currency_name: 'Waves Enterprise',
    currency_symbol: 'WEST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000be',
    currency_name: 'ExclusiveCoin',
    currency_symbol: 'EXCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb3e2cb7cccfe139f8ff84013823bf22da6b6390a',
    currency_name: 'Iconiq Lab Token',
    currency_symbol: 'ICNQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.3d7yFgrmVhMGCUwSSURbvDC5jgBtR699naqvsDEYKp38',
    currency_name: 'Simone',
    currency_symbol: 'SON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x153ed9cc1b792979d2bde0bbf45cc2a7e436a5f9',
    currency_name: 'XOVBank',
    currency_symbol: 'XOV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.VRAB-B56',
    currency_name: 'VERA',
    currency_symbol: 'VERA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0ba45a8b5d5575935b8158a88c631e9f9c95a2e5',
    currency_name: 'Tellor',
    currency_symbol: 'TRB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2260fac5e5542a773aa44fbcfedf7c193bc2c599',
    currency_name: 'Wrapped Bitcoin',
    currency_symbol: 'WBTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.USD',
    currency_name: 'bitUSD',
    currency_symbol: 'BITUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4FBb0B4cD8f960aC3428194F1c94c805D5b35836',
    currency_name: 'Big Bang Game Coin',
    currency_symbol: 'BBGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000b2',
    currency_name: 'POA',
    currency_symbol: 'POA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000044',
    currency_name: 'InsaneCoin',
    currency_symbol: 'INSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3be6e7bf2cd8e1a0a95597e72ca6d3709bbeff76',
    currency_name: 'Demeter Chain',
    currency_symbol: 'DMTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134',
    currency_name: 'BitShares',
    currency_symbol: 'BTS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4ddc2d193948926d02f9b1fe9e1daa0718270ed5',
    currency_name: 'Compound USDT',
    currency_symbol: 'CUSDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xffc63b9146967a1ba33066fb057ee3722221acf0',
    currency_name: 'Alpha Token',
    currency_symbol: 'A',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc12d1c73ee7dc3615ba4e37e4abfdbddfa38907e',
    currency_name: 'KickToken',
    currency_symbol: 'KICK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x55296f69f40ea6d20e478533c15a6b08b654e758',
    currency_name: 'XYO',
    currency_symbol: 'XYO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0c6f5f7d555e7518f6841a79436bd2b1eef03381',
    currency_name: 'Cocos-BCX',
    currency_symbol: 'COCOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x60c24407d01782c2175d32fe7c8921ed732371d1',
    currency_name: 'LemoChain',
    currency_symbol: 'LEMO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000207',
    currency_name: 'BowsCoin',
    currency_symbol: 'BSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x347c099f110ca6761779329d2879957b606b6ace',
    currency_name: 'Joint Ventures',
    currency_symbol: 'JOINT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x310da5e1e61cd9d6eced092f085941089267e71e',
    currency_name: 'Money Token',
    currency_symbol: 'MNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c4',
    currency_name: 'Kobocoin',
    currency_symbol: 'KOBO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0e8e874bb30a5F254f5144EaAE4564C7F73fAbeD',
    currency_name: 'onLEXpa',
    currency_symbol: 'onLEXpa',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000058',
    currency_name: 'Bean Cash',
    currency_symbol: 'BITB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000184',
    currency_name: 'ImageCoin',
    currency_symbol: 'IMG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x10e1E953DDBa597011f8bFA806aB0cC3415a622b',
    currency_name: '1X Short Ethereum Token',
    currency_symbol: 'ETHHEDGE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x340ef83ec8560892168d4062720f030460468656',
    currency_name: 'Ethereum Meta',
    currency_symbol: 'ETHM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3c4bEa627039F0B7e7d21E34bB9C9FE962977518',
    currency_name: 'Ubique Chain Of Things',
    currency_symbol: 'UCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf4065e4477e91c177ded71a7a6fb5ee07dc46bc9',
    currency_name: 'CEDEX Coin',
    currency_symbol: 'CEDEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000000e',
    currency_name: 'Viacoin',
    currency_symbol: 'VIA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4e0603e2a27a30480e5e3a4fe548e29ef12f64be',
    currency_name: 'Credo',
    currency_symbol: 'CREDO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x618e75ac90b12c6049ba3b27f5d5f8651b0037f6',
    currency_name: 'QASH',
    currency_symbol: 'QASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfc82bb4ba86045af6f327323a46e80412b91b27d',
    currency_name: 'Prometeus',
    currency_symbol: 'PROM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000020e',
    currency_name: 'BUMO',
    currency_symbol: 'BU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x57838ff342f36a1ec18224981ea8715a4667fb3a',
    currency_name: 'Qube',
    currency_symbol: 'QUBE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x96b52B5BF8D902252D0714A1BD2651A785Fd2660',
    currency_name: 'EtherBone',
    currency_symbol: 'ETHBN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4ba6ddd7b89ed838fed25d208d4f644106e34279',
    currency_name: 'Vether',
    currency_symbol: 'VETH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa9aad2dc3a8315caeee5f458b1d8edc31d8467bd',
    currency_name: 'BTCMoon',
    currency_symbol: 'BTCM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8578530205cecbe5db83f7f29ecfeec860c297c2',
    currency_name: 'smARTOFGIVING',
    currency_symbol: 'AOG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x016396044709eb3edc69c44f4d5fa6996917e4e8',
    currency_name: 'KingXChain',
    currency_symbol: 'KXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x74ceda77281b339142a36817fa5f9e29412bab85',
    currency_name: 'Eroscoin',
    currency_symbol: 'ERO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8001869f',
    currency_name: 'WaykiChain',
    currency_symbol: 'WICC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x10ba8c420e912bf07bedac03aa6908720db04e0c',
    currency_name: 'Raise',
    currency_symbol: 'RAISE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf25c91c87e0b1fd9b4064af0f427157aab0193a7',
    currency_name: 'BASIC',
    currency_symbol: 'BASIC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4571f3a386d1bd18E25d70d117e7067FA0Bd9D08',
    currency_name: 'Dexter G',
    currency_symbol: 'DXG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xA8b919680258d369114910511cc87595aec0be6D',
    currency_name: 'LUKSO',
    currency_symbol: 'LYXe',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x63f88a2298a5c4aee3c216aa6d926b184a4b2437',
    currency_name: 'GameCredits',
    currency_symbol: 'GAME',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0396340f16bbec973280ab053efc3f208fa37795',
    currency_name: 'BigONE Token',
    currency_symbol: 'ONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa4e8c3ec456107ea67d3075bf9e3df3a75823db0',
    currency_name: 'Loom Network',
    currency_symbol: 'LOOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb0a0a070640b450eb136dc377208469ee4f49fbc',
    currency_name: 'Future1coin',
    currency_symbol: 'F1C',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4b94c8567763654101f690cf4d54957206383b75',
    currency_name: 'NeoWorld Cash',
    currency_symbol: 'NASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x50d1c9771902476076ecfc8b2a83ad6b9355a4c9',
    currency_name: 'FTX Token',
    currency_symbol: 'FTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MTV-4C6',
    currency_name: 'MultiVAC',
    currency_symbol: 'MTV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4234f63b1d202f6c016ca3b6a0d41d7d85f17716',
    currency_name: 'Quanta Utility Token',
    currency_symbol: 'QNTU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80001f40',
    currency_name: 'Skycoin',
    currency_symbol: 'SKY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001d4',
    currency_name: 'Capricoin+',
    currency_symbol: 'CPS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x79650799e7899a802cb96c0bc33a6a8d4ce4936c',
    currency_name: 'AICHAIN',
    currency_symbol: 'AIT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x48fa42D579CE56Cc2797E4d678AD7345A27999B9',
    currency_name: 'Balloon-X',
    currency_symbol: 'BLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000013a',
    currency_name: 'MOAC',
    currency_symbol: 'MOAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xC538143202f3b11382D8606aae90a96b042a19DB',
    currency_name: 'Coinsbit Token',
    currency_symbol: 'CNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xde1e0ae6101b46520cf66fdc0b1059c5cc3d106c',
    currency_name: 'DeltaChain',
    currency_symbol: 'DELTA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x63e634330a20150dbb61b15648bc73855d6ccf07',
    currency_name: 'Blocklancer',
    currency_symbol: 'LNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3eb55d5b22ee0f9b03d59b4994c5ae7fe811be92',
    currency_name: 'Tratin',
    currency_symbol: 'TRAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.XRPBEAR-00B',
    currency_name: '3x Short XRP Token',
    currency_symbol: 'XRPBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800057e8',
    currency_name: 'PWR Coin',
    currency_symbol: 'PWR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000017',
    currency_name: 'Clams',
    currency_symbol: 'CLAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6556d2ec4d96da39cf75cbe50d58fae90079800a',
    currency_name: 'Lukki Operating Token',
    currency_symbol: 'LOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd49ff13661451313ca1553fd6954bd1d9b6e02b9',
    currency_name: 'Electrify.Asia',
    currency_symbol: 'ELEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ERD-D06',
    currency_name: 'Elrond',
    currency_symbol: 'ERD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.TOMOB-4BC',
    currency_name: 'TomoChain',
    currency_symbol: 'TOMO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3e1d5a855ad9d948373ae68e4fe1f094612b1322',
    currency_name: 'HyperQuant',
    currency_symbol: 'HQT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9ab165d795019b6d8b3e971dda91071421305e5a',
    currency_name: 'Aurora',
    currency_symbol: 'AOA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BGBP-CF3',
    currency_name: 'Binance GBP Stable Coin',
    currency_symbol: 'BGBP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa1d0E215a23d7030842FC67cE582a6aFa3CCaB83',
    currency_name: 'yearn.finance II',
    currency_symbol: 'YFII',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08d967bb0134f2d07f7cfb6e246680c53927dd30',
    currency_name: 'MATH',
    currency_symbol: 'MATH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c8.58',
    currency_name: 'Agoras Tokens',
    currency_symbol: 'AGRS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x21efe20be784ac5da569f72070e64525f95ccab6',
    currency_name: 'AZBI CORE',
    currency_symbol: 'AZBI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08399ab5eBBE96870B289754A7bD21E7EC8c6FCb',
    currency_name: 'Becaz',
    currency_symbol: 'BCZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.3QvxP6YFBKpWJSMAfYtL8Niv8KmmKsnpb9uQwQpg8QN2',
    currency_name: 'Tidex Token',
    currency_symbol: 'TDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1jlm66w38gpfuqr4s2jcfwlcrlx46p05thdnv7g',
    currency_name: 'Cubiex',
    currency_symbol: 'CBIX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0a9d68886a0D7Db83a30ec00d62512483e5Ad437',
    currency_name: 'Treecle',
    currency_symbol: 'TRCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdfdc0d82d96f8fd40ca0cfb4a288955becec2088',
    currency_name: 'MTC Mesh Network',
    currency_symbol: 'MTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1abdb309aa592f00a101c545168bfdf9a6ec61ce',
    currency_name: 'Business Credit Alliance Chain',
    currency_symbol: 'BCAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7a8ca2f815a260660158a38c34ca321a3605ecfe',
    currency_name: 'BIZZCOIN',
    currency_symbol: 'BIZZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2d184014b5658c453443aa87c8e9c4d57285620b',
    currency_name: 'JSECOIN',
    currency_symbol: 'JSE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c9023bbc572ff8dc1228c7858a280046ea8c9e5',
    currency_name: 'VideoCoin',
    currency_symbol: 'VID',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x06f3cdabae564b0546529b4dd8fef1bcd4235753',
    currency_name: 'TILWIKI',
    currency_symbol: 'TLW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x77599d2c6db170224243e255e6669280f11f1473',
    currency_name: 'Opacity',
    currency_symbol: 'OPQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x996dc5dfc819408dd98cd92c9a76f64b0738dc3d',
    currency_name: 'Skillchain',
    currency_symbol: 'SKI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x68350d30d9f58c81aaaa41929f1bfc52fff4ea49',
    currency_name: 'Rapidz',
    currency_symbol: 'RPZX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GABLR3ZUR3WURF7GQS4WEA5NT7TUO6DE5RTSNYOJ5HPHGEZZGUGD43GD',
    currency_name: 'Egas',
    currency_symbol: 'EGAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000168.TWZZfKFqcaNVe5TrphLRNEm5DQFnBRJMjDDByqv84',
    currency_name: 'Tachyon Protocol',
    currency_symbol: 'IPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.AERGO-46B',
    currency_name: 'Aergo',
    currency_symbol: 'AERGO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x66bad545596fb17a0b4ebdc003a85def10e8f6ae',
    currency_name: 'Wiki Token',
    currency_symbol: 'WIKI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x60c68a87be1e8a84144b543aacfa77199cd3d024',
    currency_name: 'Themis',
    currency_symbol: 'GET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x49614661737efbfc6a102efaeefdc8e197f7cc0e',
    currency_name: 'Escroco Emerald',
    currency_symbol: 'ESCE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0b4bdc478791897274652dc15ef5c135cae61e60',
    currency_name: 'DAEX',
    currency_symbol: 'DAX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x29536b7ca7029b5cddeb03c0451715615aca35ba',
    currency_name: 'NewsToken',
    currency_symbol: 'NEWOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x208bbb6bCEA22ef2011789331405347394EbAa51',
    currency_name: '1AI Token',
    currency_symbol: '1AI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x01E45B8D0c51f05F17385DD3416fE3aA5BFd89aC',
    currency_name: 'COINBIG',
    currency_symbol: 'CB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ETHBULL-D33',
    currency_name: '3X Long Ethereum Token',
    currency_symbol: 'ETHBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000166',
    currency_name: 'DAPS Coin',
    currency_symbol: 'DAPS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa858bc1b71a895ee83b92f149616f9b3f6afa0fb',
    currency_name: 'Kambria',
    currency_symbol: 'KAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6704b673c70de9bf74c8fba4b4bd748f0e2190e1',
    currency_name: 'Ubex',
    currency_symbol: 'UBEX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4Dd672e77c795844fe3A464eF8eF0FAAe617C8fB',
    currency_name: 'CONUN',
    currency_symbol: 'CON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000012b',
    currency_name: 'Nekonium',
    currency_symbol: 'NUKO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd27d76a1ba55ce5c0291ccd04febbe793d22ebf4',
    currency_name: 'BenePit Protocol',
    currency_symbol: 'BNP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcE13aBCE0DB5A8224616ef24D3979d466F19CF90',
    currency_name: 'REBIT',
    currency_symbol: 'KEYT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x859a9c0b44cb7066d956a958b0b82e54c9e44b4b',
    currency_name: 'iEthereum',
    currency_symbol: 'IETH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4442556a08a841227bef04c67a7ba7acf01b6fc8',
    currency_name: 'Monarch',
    currency_symbol: 'MT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcc80c051057b774cd75067dc48f8987c4eb97a5e',
    currency_name: 'Nectar',
    currency_symbol: 'NEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8f7b0b40e27e357540f90f187d90ce06366ac5a5',
    currency_name: 'ValueChain',
    currency_symbol: 'VLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003d',
    currency_name: 'Ethereum Classic',
    currency_symbol: 'ETC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfe39e6a32acd2af7955cb3d406ba2b55c901f247',
    currency_name: 'ZBG Token',
    currency_symbol: 'ZT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c',
    currency_name: 'Enjin Coin',
    currency_symbol: 'ENJ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1e66008a24a486456a796e0c771fdc0cdf43cad9',
    currency_name: 'Stellar Classic',
    currency_symbol: 'XLMX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xEED736b2b809550D89A941C2005dE93588c628e2',
    currency_name: 'ETHPlus',
    currency_symbol: 'ETHP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x63f584fa56e60e4d0fe8802b27c7e6e3b33e007f',
    currency_name: 'ContentBox',
    currency_symbol: 'BOX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x09Ccd2DA5dCDd0510268d4979e792381337138b8',
    currency_name: 'Naka Bodhi Token',
    currency_symbol: 'NBOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0f5d2fb29fb7d3cfee444a200298f468908cc942',
    currency_name: 'Decentraland',
    currency_symbol: 'MANA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff8be4b22cedc440591dcb1e641eb2a0dd9d25a5',
    currency_name: 'Uranus',
    currency_symbol: 'URAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x72adadb447784dd7ab1f472467750fc485e4cb2d',
    currency_name: 'Worldcore',
    currency_symbol: 'WRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.WGR-D3D',
    currency_name: 'Wagerr',
    currency_symbol: 'WGR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08ad83d779bdf2bbe1ad9cc0f78aa0d24ab97802',
    currency_name: 'Robonomics Web Services',
    currency_symbol: 'RWS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x45245bc59219eeaaf6cd3f382e078a461ff9de7b',
    currency_name: 'BANKEX',
    currency_symbol: 'BKX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001d0',
    currency_name: 'EtherInc',
    currency_symbol: 'ETI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000e1',
    currency_name: 'Xuez',
    currency_symbol: 'XUEZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000065',
    currency_name: 'GameCredits',
    currency_symbol: 'GAME',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.ATP-38C',
    currency_name: 'Atlas Protocol',
    currency_symbol: 'ATP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xFc2C4D8f95002C14eD0a7aA65102Cac9e5953b5E',
    currency_name: 'Rublix',
    currency_symbol: 'RBLX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb1eef147028e9f480dbc5ccaa3277d417d1b85f0',
    currency_name: 'Seele-N',
    currency_symbol: 'SEELE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BTCB-1DE',
    currency_name: 'Bitcoin BEP2',
    currency_symbol: 'BTCB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000167',
    currency_name: 'CasinoCoin',
    currency_symbol: 'CSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbaca8d824f471a6b20fdbac25e9e8943b9cd743b',
    currency_name: 'Lukiu',
    currency_symbol: 'LKU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcaaa93712bdac37f736c323c93d4d5fdefcc31cc',
    currency_name: 'CryptalDash',
    currency_symbol: 'CRD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x82b0e50478eeafde392d45d1259ed1071b6fda81',
    currency_name: 'EncrypGen',
    currency_symbol: 'DNA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2c82c73d5b34aa015989462b2948cd616a37641f',
    currency_name: 'Spectre.ai Utility Token',
    currency_symbol: 'SXUT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3abdff32f76b42e7635bdb7e425f0231a5f3ab17',
    currency_name: 'ConnectJob',
    currency_symbol: 'CJT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2f5e2c9002c058c063d21a06b6cabb50950130c8',
    currency_name: '3X Short Ethereum Token',
    currency_symbol: 'ETHBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc8c424b91d8ce0137bab4b832b7f7d154156ba6c',
    currency_name: 'apM Coin',
    currency_symbol: 'APM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfb8bf095ebcdad57d2e37573a505e7d3bafdd3cc',
    currency_name: 'DIPNET',
    currency_symbol: 'DPN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7a5e6ca9d335e343d1ed12239f67248e056afe2f',
    currency_name: 'Ethash Miner',
    currency_symbol: 'HETM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x016ee7373248a80bde1fd6baa001311d233b3cfa',
    currency_name: '3X Short Bitcoin Token',
    currency_symbol: 'BEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000d5',
    currency_name: 'Spectrecoin',
    currency_symbol: 'XSPEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x96b0bf939d9460095c15251f71fda11e41dcbddb',
    currency_name: 'Sharpay',
    currency_symbol: 'S',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0d262e5dc4a06a0f1c90ce79c7a60c09dfc884e4',
    currency_name: 'JET8',
    currency_symbol: 'J8T',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x174afe7a032b5a33a3270a9f6c30746e25708532',
    currency_name: 'Humanscape',
    currency_symbol: 'HUM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfd107b473ab90e8fbd89872144a3dc92c40fa8c9',
    currency_name: 'LALA World',
    currency_symbol: 'LALA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x65Ac08c55F21d4A73CA1D429a17a6872b23bFd55',
    currency_name: 'ALL BEST ICO',
    currency_symbol: 'ALLBI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x67a9099f0008c35c61c00042cd9fb03684451097',
    currency_name: 'Game Stars',
    currency_symbol: 'GST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x166f1a7ecae00bd43876a25b10a63c575e05c0e7',
    currency_name: 'VERA',
    currency_symbol: 'VERA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xB67718b98d52318240c52E71A898335da4A28c42',
    currency_name: 'Innovative Bioresearch Coin',
    currency_symbol: 'INNBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80579bfc.G1emXxRGHxDSe5sduNmqULJdKngRjk3C6d7nPw19fK86',
    currency_name: 'Newton Coin Project',
    currency_symbol: 'NCP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0ea984e789302b7b612147e4e4144e64f21425eb',
    currency_name: 'Waletoken',
    currency_symbol: 'WTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001af',
    currency_name: 'Bitcoin 2',
    currency_symbol: 'BTC2',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x827d53c8170af52625f414bde00326fc8a085e86',
    currency_name: 'Beaxy',
    currency_symbol: 'BXY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x08711d3b02c8758f2fb3ab4e80228418a7f8e39c',
    currency_name: 'Edgeless',
    currency_symbol: 'EDG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfc858154c0b2c4a3323046fb505811f110ebda57',
    currency_name: 'NOIA Network',
    currency_symbol: 'NOIA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x79c5a1ae586322a07bfb60be36e1b31ce8c84a1e',
    currency_name: 'Freight Trust & Clearing Network',
    currency_symbol: 'EDI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x78a52e12c7b63d05c12f9608307587cf654ec3d0',
    currency_name: 'Crypto Village Accelerator',
    currency_symbol: 'CVA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3d3dd61b0F9A558759a21dA42166042B114E12D5',
    currency_name: '3x Short EOS Token',
    currency_symbol: 'EOSBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd947b0ceab2a8885866b9a04a06ae99de852a3d4',
    currency_name: 'Trade Token X',
    currency_symbol: 'TIOX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x420412e765bfa6d85aaac94b4f7b708c89be2e2b',
    currency_name: 'Brazilian Digital Token',
    currency_symbol: 'BRZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x536381a8628dbcc8c70ac9a30a7258442eab4c92',
    currency_name: 'Pantos',
    currency_symbol: 'PAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x514910771af9ca656af840dff83e8264ecf986ca',
    currency_name: 'Chainlink',
    currency_symbol: 'LINK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf85ef57fcdb36d628d063fa663e61e44d35ae661',
    currency_name: 'eToro Pound Sterling',
    currency_symbol: 'GBPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd2fa8f92ea72abb35dbd6deca57173d22db2ba49',
    currency_name: 'Origami',
    currency_symbol: 'ORI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8a1e3930fde1f151471c368fdbb39f3f63a65b55',
    currency_name: 'Bezop',
    currency_symbol: 'BEZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80002304',
    currency_name: 'NULS',
    currency_symbol: 'NULS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba8c0244fbdeb10f19f6738750daeedf7a5081eb',
    currency_name: 'suterusu',
    currency_symbol: 'SUTER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7533D63A2558965472398Ef473908e1320520AE2',
    currency_name: 'intexcoin',
    currency_symbol: 'INTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd321ca7cd7a233483b8cd5a11a89e9337e70df84',
    currency_name: 'Vid',
    currency_symbol: 'VI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1e797Ce986C3CFF4472F7D38d5C4aba55DfEFE40',
    currency_name: 'BlockCDN',
    currency_symbol: 'BCDN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3137619705b5fc22a3048989f983905e456b59ab',
    currency_name: 'Everus',
    currency_symbol: 'EVR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.COS-2E4',
    currency_name: 'Contentos',
    currency_symbol: 'COS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2d0e95bd4795d7ace0da3c0ff7b706a5970eb9d3',
    currency_name: 'All Sports',
    currency_symbol: 'SOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.NOW-E68',
    currency_name: 'NOW Token',
    currency_symbol: 'NOW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000143',
    currency_name: 'Zero',
    currency_symbol: 'ZER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000007c',
    currency_name: 'Unify',
    currency_symbol: 'UNIFY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce',
    currency_name: 'SHIBA INU',
    currency_symbol: 'SHIB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7d4b8cce0591c9044a22ee543533b72e976e36c3',
    currency_name: 'Change',
    currency_symbol: 'CAG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbe428c3867f05dea2a89fc76a102b544eac7f772',
    currency_name: 'CyberVein',
    currency_symbol: 'CVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000013f',
    currency_name: 'ECC',
    currency_symbol: 'ECC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xA6D5C720a9af5A405dFB6b9F44Fc44FaB5D4A58D',
    currency_name: 'EzyStayz',
    currency_symbol: 'EZY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x838d8e11B160deC88Fe62BF0f743FB7000941e13',
    currency_name: 'Krios',
    currency_symbol: 'GIG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6b9F1F092E0B10015a4391A80cD3E6B6cefD1728',
    currency_name: 'LuckySevenToken',
    currency_symbol: 'LST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbf59e6fe2bc4ee8d303e493390b4aacab16fcc91',
    currency_name: 'Clipper Coin',
    currency_symbol: 'CCC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000086',
    currency_name: 'Lisk',
    currency_symbol: 'LSK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe577e0B200d00eBdecbFc1cd3F7E8E04C70476BE',
    currency_name: 'xEURO',
    currency_symbol: 'XEUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2e2e0a28f6585e895dd646a363bae29b77b88a31',
    currency_name: 'Volume Network',
    currency_symbol: 'VOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x81e74a3ea4bab2277aa3b941e9d9f37b08ac5374',
    currency_name: 'Ifoods Chain',
    currency_symbol: 'IFOOD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000797e',
    currency_name: 'Ethersocial',
    currency_symbol: 'ESN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000016',
    currency_name: 'MonaCoin',
    currency_symbol: 'MONA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.thepeostoken',
    currency_name: 'pEOS',
    currency_symbol: 'PEOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b',
    currency_name: 'Crypto.com Coin',
    currency_symbol: 'CRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MEDB-87E',
    currency_name: 'MediBloc',
    currency_symbol: 'MED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5b322514ff727253292637d9054301600c2c81e8',
    currency_name: 'DAD',
    currency_symbol: 'DAD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.FSN-E14',
    currency_name: 'Fusion',
    currency_symbol: 'FSN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0e7f79e89ba8c4a13431129fb2db0d4f444b5b9a',
    currency_name: 'Xank',
    currency_symbol: 'XANK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80006476',
    currency_name: 'WeOwn',
    currency_symbol: 'CHX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.LYFE-6AB',
    currency_name: 'LYFE',
    currency_symbol: 'LYFE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x429D83Bb0DCB8cdd5311e34680ADC8B12070a07f',
    currency_name: 'PlatonCoin',
    currency_symbol: 'PLTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe6877ea9c28fbdec631ffbc087956d0023a76bf2',
    currency_name: 'UNI COIN',
    currency_symbol: 'UNI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.EOSBULL-F0D',
    currency_name: '3x Long EOS Token',
    currency_symbol: 'EOSBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8f179114235842978d8917e08721541072c46584',
    currency_name: 'PointPay',
    currency_symbol: 'PXP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd6a55c63865affd67e2fb9f284f87b7a9e5ff3bd',
    currency_name: 'Switch',
    currency_symbol: 'ESH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.horustokenio',
    currency_name: 'HorusPay',
    currency_symbol: 'HORUS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2fc246aa66f0da5bb1368f688548ecbbe9bdee5d',
    currency_name: 'TEMCO',
    currency_symbol: 'TEMCO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x94236591125e935f5ac128bb3d5062944c24958c',
    currency_name: 'VegaWallet Token',
    currency_symbol: 'VGW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000163',
    currency_name: 'Aeon',
    currency_symbol: 'AEON',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9a0242b7a33dacbe40edb927834f96eb39f8fbcb',
    currency_name: 'BABB',
    currency_symbol: 'BAX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd45247c07379d94904e0a87b4481f0a1ddfa0c64',
    currency_name: 'Buggyra Coin Zero',
    currency_symbol: 'BCZERO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbf2179859fc6d5bee9bf9158632dc51678a4100e',
    currency_name: 'aelf',
    currency_symbol: 'ELF',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfa3118b34522580c35ae27f6cf52da1dbb756288',
    currency_name: 'LinkEye',
    currency_symbol: 'LET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcaD49C39B72c37B32cEe8b14F33F316D3a8bC335',
    currency_name: 'Soda Coin',
    currency_symbol: 'SOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.HNST-3C9',
    currency_name: 'Honest',
    currency_symbol: 'HNST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x593114f03a0a575aece9ed675e52ed68d2172b8c',
    currency_name: 'BidiPass',
    currency_symbol: 'BDP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5c250ff9b993c6991cc4a3cc543716e53b478018',
    currency_name: 'STPAY',
    currency_symbol: 'STP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5AB55ec290BeacAE98f54c3eB70860460B167C3C',
    currency_name: 'Rivermount',
    currency_symbol: 'RM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000017e',
    currency_name: 'Ryo Currency',
    currency_symbol: 'RYO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbcc7026cde024c6a97d2df6e40613217d946279c',
    currency_name: 'ALA',
    currency_symbol: 'ALA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000f0',
    currency_name: 'Loki',
    currency_symbol: 'LOKI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.publytoken11',
    currency_name: 'PUBLYTO Token',
    currency_symbol: 'PUB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001a8',
    currency_name: 'Teloscoin',
    currency_symbol: 'TELOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x660e71483785f66133548B10f6926dC332b06e61',
    currency_name: 'Adelphoi',
    currency_symbol: 'ADL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbbbbca6a901c926f240b89eacb641d8aec7aeafd',
    currency_name: 'Loopring',
    currency_symbol: 'LRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x543ff227f64aa17ea132bf9886cab5db55dcaddf',
    currency_name: 'DAOstack',
    currency_symbol: 'GEN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000a9e',
    currency_name: 'Nebulas',
    currency_symbol: 'NAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000004',
    currency_name: 'ReddCoin',
    currency_symbol: 'RDD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x27c1bA4F85b8dC1c150157816623A6Ce80b7F187',
    currency_name: '3x Long XRP Token',
    currency_symbol: 'XRPBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x70a72833d6bf7f508c8224ce59ea1ef3d0ea3a38',
    currency_name: 'Utrust',
    currency_symbol: 'UTK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x58a3520d738b268c2353ecee518a1ad8e28e4ae5',
    currency_name: 'HEIDI',
    currency_symbol: 'HDI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x278a83b64c3e3e1139f8e8a52d96360ca3c69a3d',
    currency_name: 'Nexxo',
    currency_symbol: 'NEXXO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x19b58d95929586ad750893caad43e77aa6e8ce9e',
    currency_name: 'CelCoin',
    currency_symbol: 'CELC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000011c',
    currency_name: 'Bitcoin Zero',
    currency_symbol: 'BZX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x408e41876cccdc0f92210600ef50372656052a38',
    currency_name: 'Ren',
    currency_symbol: 'REN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000153',
    currency_name: 'DeVault',
    currency_symbol: 'DVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5b0751713b2527d7f002c0c4e2a37e1219610a6b',
    currency_name: 'Ethouse',
    currency_symbol: 'HORSE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbD4B60a138b3fce3584EA01f50c0908c18f9677A',
    currency_name: 'Fintab',
    currency_symbol: 'FNTB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000cf',
    currency_name: 'EveriToken',
    currency_symbol: 'EVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca. bnb1m5uzzfxs7x05sl28gg96zyecn9jwgtkpyeftyn',
    currency_name: 'Elrond',
    currency_symbol: 'ERD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x322f4f6a48329690957a3bcbd1301516c2b83c1f',
    currency_name: 'MesChain',
    currency_symbol: 'MES',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4375e7ad8a01b8ec3ed041399f62d9cd120e0063',
    currency_name: 'Bit-Z Token',
    currency_symbol: 'BZ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x865ec58b06bf6305b886793aa20a2da31d034e68',
    currency_name: 'Moss Coin',
    currency_symbol: 'MOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0d16450d347c12c086d6c94c76c5aaac35ea07e0',
    currency_name: 'Digital Gold',
    currency_symbol: 'GOLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000460',
    currency_name: 'Rise',
    currency_symbol: 'RISE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x80a7e048f37a50500351c204cb407766fa3bae7f',
    currency_name: 'Crypterium',
    currency_symbol: 'CRPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000002b.appsolutely',
    currency_name: 'LoyalCoin',
    currency_symbol: 'LYL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xEF19F4E48830093Ce5bC8b3Ff7f903A0AE3E9Fa1',
    currency_name: 'botXcoin',
    currency_symbol: 'BOTX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x96c30d5499ef6ea96a9c221bc18bc39d29c97f27',
    currency_name: 'Thar Token',
    currency_symbol: 'THAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe69a353b3152dd7b706ff7dd40fe1d18b7802d31',
    currency_name: 'AdHive',
    currency_symbol: 'ADH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa31108e5bab5494560db34c95492658af239357c',
    currency_name: 'DACSEE',
    currency_symbol: 'DACS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe66747a101bff2dba3697199dcce5b743b454759',
    currency_name: 'Gatechain Token',
    currency_symbol: 'GT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x60c87297a1feadc3c25993ffcadc54e99971e307',
    currency_name: 'Global X Change Token',
    currency_symbol: 'GXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.f2703e93f87b846a7aacec1247beaec1c583daa4',
    currency_name: 'Hyper Pay',
    currency_symbol: 'HPY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x72dd4b6bd852a3aa172be4d6c5a6dbec588cf131',
    currency_name: 'NAGA',
    currency_symbol: 'NGC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x809826cceab68c387726af962713b64cb5cb3cca',
    currency_name: 'Nucleus Vision',
    currency_symbol: 'NCASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf0ee6b27b759c9893ce4f094b49ad28fd15a23e4',
    currency_name: 'Enigma',
    currency_symbol: 'ENG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8eb24319393716668d768dcec29356ae9cffe285',
    currency_name: 'SingularityNET',
    currency_symbol: 'AGI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x814e0908b12a99fecf5bc101bb5d0b8b5cdf7d26',
    currency_name: 'Measurable Data Token',
    currency_symbol: 'MDT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbb1f24c0c1554b9990222f036b0aad6ee4caec29',
    currency_name: 'CryptoSoul',
    currency_symbol: 'SOUL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3b7f247f21bf3a07088c2d3423f64233d4b069f7',
    currency_name: 'Dynamite',
    currency_symbol: 'DYNMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x00fc270c9cc13e878ab5363d00354bebf6f05c15',
    currency_name: 'VNX Exchange',
    currency_symbol: 'VNXLU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MITH-C76',
    currency_name: 'Mithril',
    currency_symbol: 'MITH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c2.therealkarma',
    currency_name: 'KARMA',
    currency_symbol: 'KARMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1ed7ae1f0e2fa4276dd7ddc786334a3df81d50c0',
    currency_name: 'FSBT API Token',
    currency_symbol: 'FSBT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2fb12bccf6f5dd338b76be784a93ade072425690',
    currency_name: 'BEAT',
    currency_symbol: 'BEAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.f2033ede578e17fa6231047265010445bca8cf1c',
    currency_name: 'QCash',
    currency_symbol: 'QC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf453b5b9d4e0b5c62ffb256bb2378cc2bc8e8a89',
    currency_name: 'MARK.SPACE',
    currency_symbol: 'MRK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000039',
    currency_name: 'Syscoin',
    currency_symbol: 'SYS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x84fE25f3921f3426395c883707950d0c00367576',
    currency_name: 'Insight Protocol',
    currency_symbol: 'INX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd31695a1d35e489252ce57b129fd4b1b05e6acac',
    currency_name: 'TOKPIE',
    currency_symbol: 'TKP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd35833f9255fb28cc6b91acb8a66ba6429d6ef5a',
    currency_name: 'HYPNOXYS',
    currency_symbol: 'HYPX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6ba460ab75cd2c56343b3517ffeba60748654d26',
    currency_name: 'UpToken',
    currency_symbol: 'UP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x752FF65b884b9C260D212C804E0b7ACEea012473',
    currency_name: 'SnapCoin',
    currency_symbol: 'SNPC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6810e776880c02933d47db1b9fc05908e5386b96',
    currency_name: 'Gnosis',
    currency_symbol: 'GNO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xEc1a718D1A6F8F8d94eCEc6fe91465697bb2b88C',
    currency_name: 'ENTONE',
    currency_symbol: 'ENTONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x915044526758533dfB918ecEb6e44bc21632060D',
    currency_name: 'Chromia',
    currency_symbol: 'CHR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9556f8ee795d991ff371f547162d5efb2769425f',
    currency_name: 'DMme',
    currency_symbol: 'DMME',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x20f7a3ddf244dc9299975b4da1c39f8d5d75f05a',
    currency_name: 'Sapien',
    currency_symbol: 'SPN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x261638EC8ee8100484130EBD2fEBfDAdC0D8742a',
    currency_name: 'Level01',
    currency_symbol: 'LVX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x687174f8c49ceb7729d925c3a961507ea4ac7b28',
    currency_name: 'Global Awards Token',
    currency_symbol: 'GAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4212fea9fec90236ecc51e41e2096b16ceb84555',
    currency_name: 'eSDChain',
    currency_symbol: 'SDA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x115eC79F1de567eC68B7AE7eDA501b406626478e',
    currency_name: 'Carry',
    currency_symbol: 'CRE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x30680ac0a8a993088223925265fd7a76beb87e7f',
    currency_name: 'ARAW',
    currency_symbol: 'ARAW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x194524355f26af663468d4996f207a918c73e013',
    currency_name: 'DragonVein',
    currency_symbol: 'DVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x039b5649a59967e3e936d7471f9c3700100ee1ab',
    currency_name: 'KuCoin Shares',
    currency_symbol: 'KCS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6febdfc0a9d9502c45343fce0df08828def44795',
    currency_name: '3X Short BNB Token',
    currency_symbol: 'BNBBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x688478f003b8d0f10b8af2122bf20378555ef958',
    currency_name: 'Boltt Coin ',
    currency_symbol: 'BOLTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xacACa5b8805636608e14C64b0bFFfC2Deb2C6cEc',
    currency_name: 'ROMToken',
    currency_symbol: 'ROM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000041',
    currency_name: 'Bitcoin Plus',
    currency_symbol: 'XBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xffe510a92434a0df346c5e72a3494b043cf249eb',
    currency_name: 'Lux Bio Cell',
    currency_symbol: 'LBXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x75c5ee419331b6150879530d06f9ba054755f1da',
    currency_name: 'SalPay',
    currency_symbol: 'SAL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd4fa1460f537bb9085d22c7bccb5dd450ef28e3a',
    currency_name: 'Populous',
    currency_symbol: 'PPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000015b',
    currency_name: 'Ycash',
    currency_symbol: 'YEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf911a7ec46a2c6fa49193212fe4a2a9b95851c27',
    currency_name: 'Antiample',
    currency_symbol: 'XAMP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x57ab1e02fee23774580c119740129eac7081e9d3',
    currency_name: 'sUSD',
    currency_symbol: 'SUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc76fb75950536d98fa62ea968e1d6b45ffea2a55',
    currency_name: 'Unit Protocol',
    currency_symbol: 'COL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x83e2be8d114f9661221384b3a50d24b96a5653f5',
    currency_name: '0xcert',
    currency_symbol: 'ZXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2567c677473d110d75a8360c35309e63b1d52429',
    currency_name: 'ShareX',
    currency_symbol: 'SEXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x584b44853680ee34a0f337b712a8f66d816df151',
    currency_name: 'AI Doctor',
    currency_symbol: 'AIDOC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x61b2d3ea9f1c6b387c985c73d40e8fbfb284e5c7',
    currency_name: 'RoboCalls',
    currency_symbol: 'RC20',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xed494c9e2f8e34e53bdd0ea9b4d80305cb15c5c2',
    currency_name: 'CWV Chain',
    currency_symbol: 'CWV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6e605c269e0c92e70beeb85486f1fc550f9380bd',
    currency_name: 'Catex Token',
    currency_symbol: 'CATT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000901',
    currency_name: 'Elastos',
    currency_symbol: 'ELA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x186a33d4dbcd700086a26188dcb74e69be463665',
    currency_name: '7Eleven',
    currency_symbol: '7E',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc77b230f31b517f1ef362e59c173c2be6540b5e8',
    currency_name: 'VIDY',
    currency_symbol: 'VIDY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x461733c17b0755ca5649b6db08b3e213fcf22546',
    currency_name: 'ATN',
    currency_symbol: 'ATN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb62132e35a6c13ee1ee0f84dc5d40bad8d815206',
    currency_name: 'Nexo',
    currency_symbol: 'NEXO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa8892bfc33fa44053a9e402b1839966f4fec74a4',
    currency_name: 'Crypto User Base',
    currency_symbol: 'CUB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x81c9151de0c8bafcd325a57e3db5a5df1cebf79c',
    currency_name: 'Datum',
    currency_symbol: 'DAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.BEAR-14C',
    currency_name: '3X Short Bitcoin Token',
    currency_symbol: 'BEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xec67005c4e498ec7f55e092bd1d35cbc47c91892',
    currency_name: 'Melon',
    currency_symbol: 'MLN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c',
    currency_name: 'Ethereum',
    currency_symbol: 'ETH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000137',
    currency_name: 'Adshares',
    currency_symbol: 'ADS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x16f812be7fff02caf662b85d5d58a5da6572d4df',
    currency_name: 'United Traders Token',
    currency_symbol: 'UTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4df76a9dab9bb8310e4ad3dc4336a8e26ed24ebb',
    currency_name: 'SappChain',
    currency_symbol: 'SAPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GARFMAHQM4JDI55SK2FGEPLOZU7BTEODS3Y5QNT3VMQQIU3WV2HTBA46',
    currency_name: 'GlitzKoin',
    currency_symbol: 'GTN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x613fa2a6e6daa70c659060e86ba1443d2679c9d7',
    currency_name: 'ParkinGo',
    currency_symbol: 'GOT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2af5d2ad76741191d15dfe7bf6ac92d4bd912ca3',
    currency_name: 'UNUS SED LEO',
    currency_symbol: 'LEO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf970b8e36e23f7fc3fd752eea86f8be8d83375a6',
    currency_name: 'Ripio Credit Network',
    currency_symbol: 'RCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0bb217e40f8a5cb79adf04e1aab60e5abd0dfc1e',
    currency_name: 'SwftCoin',
    currency_symbol: 'SWFTC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf2f9a7e93f845b3ce154efbeb64fb9346fcce509',
    currency_name: 'UniPower',
    currency_symbol: 'POWER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7e9e431a0b8c4d532c745b1043c7fa29a48d4fba',
    currency_name: 'eosDAC',
    currency_symbol: 'EOSDAC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x73c9275c3a2dd84b5741fd59aebf102c91eb033f',
    currency_name: 'Bitball Treasure',
    currency_symbol: 'BTRS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x69beab403438253f13b6e92db91f7fb849258263',
    currency_name: 'Neurotoken',
    currency_symbol: 'NTK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x12683dc9eec95a5f742d40206e73319e6b9d8a91',
    currency_name: 'FABRK',
    currency_symbol: 'FAB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x9972a0f24194447e73a7e8b6cd26a52e02ddfad5',
    currency_name: 'Thore Cash',
    currency_symbol: 'TCH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc86d054809623432210c107af2e3f619dcfbf652',
    currency_name: 'Sentinel Protocol',
    currency_symbol: 'UPP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.WISH-2D5',
    currency_name: 'MyWish',
    currency_symbol: 'WISH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd2bfec3f80b7d966d7657a2ecc5982ee2f49336a',
    currency_name: 'Gosama',
    currency_symbol: 'GOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8716fc5da009d3a208f0178b637a50f4ef42400f',
    currency_name: 'UGAS',
    currency_symbol: 'UGAS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000052',
    currency_name: 'Quark',
    currency_symbol: 'QRK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf574c24545e5ffecb9a659c229253d4111d87e1',
    currency_name: 'HUSD',
    currency_symbol: 'HUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x869b1f57380ae501d387b19262efd3c0eb7501b0',
    currency_name: 'Oxycoin',
    currency_symbol: 'OXY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c8.3',
    currency_name: 'MaidSafeCoin',
    currency_symbol: 'MAID',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x340d2bde5eb28c1eed91b2f790723e3b160613b7',
    currency_name: 'BLOCKv',
    currency_symbol: 'VEE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x92A5B04D0ED5D94D7a193d1d334D3D16996f4E13',
    currency_name: 'Eristica',
    currency_symbol: 'ERT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001c3',
    currency_name: 'Klimatas',
    currency_symbol: 'KTS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x922105fad8153f516bcfb829f56dc097a0e1d705',
    currency_name: 'YEE',
    currency_symbol: 'YEE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xAbC430136A4dE71c9998242de8c1b4B97D2b9045',
    currency_name: 'Veros',
    currency_symbol: 'VRS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa984a92731c088f1ea4d53b71a2565a399f7d8d5',
    currency_name: 'InternationalCryptoX',
    currency_symbol: 'INCX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x722f2f3eac7e9597c73a593f7cf3de33fbfc3308',
    currency_name: 'CoinUs',
    currency_symbol: 'CNUS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5e040aC72140F0617bC24aB7134c0C6eCae0e965',
    currency_name: 'Folgory Coin',
    currency_symbol: 'FLG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbc46d9961a3932f7d6b64abfdec80c1816c4b835',
    currency_name: 'Litex',
    currency_symbol: 'LXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000457',
    currency_name: 'BigBang Core',
    currency_symbol: 'BBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1d462414fe14cf489c7a21cac78509f4bf8cd7c0',
    currency_name: 'CanYaCoin',
    currency_symbol: 'CAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1776e1f26f98b1a5df9cd347953a26dd3cb46671',
    currency_name: 'Numeraire',
    currency_symbol: 'NMR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0707681f344deb24184037fc0228856f2137b02e',
    currency_name: 'FNKOS',
    currency_symbol: 'FNKOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb62d18dea74045e822352ce4b3ee77319dc5ff2f',
    currency_name: 'EventChain',
    currency_symbol: 'EVC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1a95b271b0535d15fa49932daba31ba612b52946',
    currency_name: 'Minereum',
    currency_symbol: 'MNE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xae31b85bfe62747d0836b82608b4830361a3d37a',
    currency_name: 'Aergo',
    currency_symbol: 'AERGO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GA6HCMBLTZS5VYYBCATRBRZ3BZJMAFUDKYYF6AH6MVCMGWMRDNSWJPIH',
    currency_name: 'Mobius',
    currency_symbol: 'MOBI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5e3002dff591c5e75bb9dedae268049742e6b13a',
    currency_name: "Tutor's Diary",
    currency_symbol: 'TUDA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb8c6ad2586bb71d518c2aaf510efe91f82022f58',
    currency_name: 'Gomics',
    currency_symbol: 'GOM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000001f',
    currency_name: 'MonetaryUnit',
    currency_symbol: 'MUE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x85ca6710D0F1D511d130f6935eDDA88ACBD921bD',
    currency_name: 'Pledge Coin',
    currency_symbol: 'PLG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000037',
    currency_name: 'Advanced Internet Blocks',
    currency_symbol: 'AIB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x53884b61963351C283118a8E1Fc05BA464a11959',
    currency_name: 'MONNOS',
    currency_symbol: 'MNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf80d589b3dbe130c270a69f1a69d050f268786df',
    currency_name: 'Datamine',
    currency_symbol: 'DAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf8e386eda857484f5a12e4b5daa9984e06e73705',
    currency_name: 'Indorse Token',
    currency_symbol: 'IND',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2d39ec4da54329d28d230b4973f5aa27886c3aee',
    currency_name: 'NFX Coin',
    currency_symbol: 'NFXC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd2727e4259BBa31955FD582986334AEA4fBA8C52',
    currency_name: 'Blood',
    currency_symbol: 'BLOOD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000071',
    currency_name: 'HempCoin',
    currency_symbol: 'THC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x17aa18a4b64a55abed7fa543f2ba4e91f2dce482',
    currency_name: 'Insight Chain',
    currency_symbol: 'INB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf6abff616043c2da572573dcc583b656297b30e7',
    currency_name: 'Custody Token',
    currency_symbol: 'CUST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800007cd',
    currency_name: 'Polis',
    currency_symbol: 'POLIS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c5',
    currency_name: 'Hush',
    currency_symbol: 'HUSH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbdbb0ee6144544ec814d417b0ad41f16fc8b858e',
    currency_name: 'BitKAM',
    currency_symbol: 'KAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd996035Db82cae33Ba1f16fDF23B816E5E9fAaBb',
    currency_name: 'BNS Token',
    currency_symbol: 'BNS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x56ba2ee7890461f463f7be02aac3099f6d5811a8',
    currency_name: 'BlockCAT',
    currency_symbol: 'CAT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfc05987bd2be489ACCF0f509E44B0145d68240f7',
    currency_name: 'Essentia',
    currency_symbol: 'ESS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x197e6bca6bc2f488ec760a6ce46b1399cd2954b0',
    currency_name: 'Bitcloud Pro',
    currency_symbol: 'BPRO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1985365e9f78359a9b6ad760e32412f4a445e862',
    currency_name: 'Augur',
    currency_symbol: 'REP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x76960dccd5a1fe799f7c29be9f19ceb4627aeb2f',
    currency_name: 'RED',
    currency_symbol: 'RED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb056c38f6b7dc4064367403e26424cd2c60655e1',
    currency_name: 'CEEK VR',
    currency_symbol: 'CEEK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa9fC65Da36064cE545e87690e06f5de10C52C690',
    currency_name: '3x Short Bitcoin Cash Token',
    currency_symbol: 'BCHBEAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.XLPG-GDDRETFPCQIDWH3LNMIONXSSBWYLXZFSF3WY6UCCIF6NMTW2UKA3R4NX',
    currency_name: 'StellarPayGlobal',
    currency_symbol: 'XLPG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x65ccd72c0813ce6f2703593b633202a0f3ca6a0c',
    currency_name: 'Nestree',
    currency_symbol: 'EGG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x86e44543164d9b97b14ef7f6f3ab7ba670cab346',
    currency_name: 'QUINADS',
    currency_symbol: 'QUIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4824a7b64e3966b0133f4f4ffb1b9d6beb75fff7',
    currency_name: 'TokenClub',
    currency_symbol: 'TCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.NEW-09E',
    currency_name: 'Newton',
    currency_symbol: 'NEW',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x3f694635ab69b1b5e7f001de26892cc63ba94bad',
    currency_name: 'VARC',
    currency_symbol: 'VARC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.SHR-DB6',
    currency_name: 'ShareToken',
    currency_symbol: 'SHR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x42d6622dece394b54999fbd73d108123806f6a18',
    currency_name: 'SpankChain',
    currency_symbol: 'SPANK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf5238462e7235c7b62811567e63dd17d12c2eaa0',
    currency_name: 'CACHE Gold',
    currency_symbol: 'CGT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6aac8cb9861e42bf8259f5abdc6ae3ae89909e11',
    currency_name: 'Bitcoin Red',
    currency_symbol: 'BTCRED',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001fd',
    currency_name: 'Xaya',
    currency_symbol: 'CHI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x7c5a0ce9267ed19b22f8cae653f198e3e8daf098',
    currency_name: 'Santiment Network Token',
    currency_symbol: 'SAN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000011f',
    currency_name: 'DigitalNote',
    currency_symbol: 'XDN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc011a73ee8576fb46f5e1c5751ca3b9fe0af2a6f',
    currency_name: 'Synthetix Network Token',
    currency_symbol: 'SNX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5554e04e76533e1d14c52f05beef6c9d329e1e30',
    currency_name: 'Autonio',
    currency_symbol: 'NIO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4954db6391f4feb5468b6b943d4935353596aec9',
    currency_name: 'USDQ',
    currency_symbol: 'USDQ',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x26ce25148832c04f3d7f26f32478a9fe55197166',
    currency_name: 'DEXTools',
    currency_symbol: 'DEXT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fe',
    currency_name: 'Metaverse ETP',
    currency_symbol: 'ETP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.MCASH-869',
    currency_name: 'Mcashchain',
    currency_symbol: 'MCASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4c1c4957d22d8f373aed54d0853b090666f6f9de',
    currency_name: 'Silverway',
    currency_symbol: 'SLV',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4b7ad3a56810032782afce12d7d27122bdb96eff',
    currency_name: 'Sparkle',
    currency_symbol: 'SPRKL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdfbc9050f5b01df53512dcc39b4f2b2bbacd517a',
    currency_name: 'Jobchain',
    currency_symbol: 'JOB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.CBM-4B2',
    currency_name: 'CryptoBonusMiles',
    currency_symbol: 'CBM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf41e5fbc2f6aac200dd8619e121ce1f05d150077',
    currency_name: 'CryCash',
    currency_symbol: 'CRC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd1e10c37a27d95d95720291b1dc6f12f74c71443',
    currency_name: 'Cosmo Coin',
    currency_symbol: 'COSM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0Cc9FCCFF81252F4bd8C5c6b359B14ae2Ed851cf',
    currency_name: 'Innovative Bioresearch Classic',
    currency_symbol: 'INNBCL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd07d9fe2d2cc067015e2b4917d24933804f42cfa',
    currency_name: 'Tolar',
    currency_symbol: 'TOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.bnb1scrark2sv6fpngyqxrryw9hw7y05euwntz45ae',
    currency_name: 'TROY',
    currency_symbol: 'TROY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4f3afec4e5a3f2a6a1a411def7d7dfe50ee057bf',
    currency_name: 'Digix Gold Token',
    currency_symbol: 'DGX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb66a2131a6b840dd020151f80723caed603efb51',
    currency_name: 'NNB Token',
    currency_symbol: 'NNB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x430bd07726423A54f6d82Ab0F578CE62A3b8054D',
    currency_name: 'UOS Network',
    currency_symbol: 'UOS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xeF7A985E4FF9B5DcCD6eDdF58577486887288711',
    currency_name: 'HOMT',
    currency_symbol: 'HOMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000029',
    currency_name: 'Einsteinium',
    currency_symbol: 'EMC2',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x940a2db1b7008b6c776d4faaca729d6d4a4aa551',
    currency_name: 'Dusk Network',
    currency_symbol: 'DUSK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xccbf21ba6ef00802ab06637896b799f7101f54a2',
    currency_name: 'Budbo',
    currency_symbol: 'BUBO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.EVT-49B',
    currency_name: 'EveriToken',
    currency_symbol: 'EVT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5ab793e36070f0fac928ea15826b0c1bc5365119',
    currency_name: 'YUKI',
    currency_symbol: 'YUKI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x336f646f87d9f6bc6ed42dd46e8b3fd9dbd15c22',
    currency_name: 'Crystal Clear ',
    currency_symbol: 'CCT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xba9d4199fab4f26efe3551d490e3821486f135ba',
    currency_name: 'SwissBorg',
    currency_symbol: 'CHSB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5978708d6ccE1CC9640Eed47422D64c91BbD5171',
    currency_name: 'LOLTOKEN',
    currency_symbol: 'LOL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800002ca.TWT-8C2',
    currency_name: 'Trust Wallet Token',
    currency_symbol: 'TWT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc15a399c4ea7815fe36857c9e290ee452a5d6b21',
    currency_name: 'BoatPilot Token',
    currency_symbol: 'NAVY',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001cd',
    currency_name: 'Filecoin [Futures]',
    currency_symbol: 'FIL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xff19138b039d938db46bdda0067dc4ba132ec71c',
    currency_name: 'Snetwork',
    currency_symbol: 'SNET',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb98d4c97425d9908e66e53a6fdf673acca0be986',
    currency_name: 'Arcblock',
    currency_symbol: 'ABT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8e766f57f7d16ca50b4a0b90b88f6468a09b0439',
    currency_name: 'Maximine Coin',
    currency_symbol: 'MXM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf4b54874cd8a6c863e3a904c18fda964661ec363',
    currency_name: 'DWS',
    currency_symbol: 'DWS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf02dab52205aff6bb3d47cc7b21624a5064f9fba',
    currency_name: 'Pyrrhos Gold',
    currency_symbol: 'PGOLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4d13d624a87baa278733c068a174412afa9ca6c8',
    currency_name: 'Eterbase Coin',
    currency_symbol: 'XBASE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa105C740BC012A43a342Ab4A0Ef40143452C8E89',
    currency_name: 'Litbinex Coin',
    currency_symbol: 'LTB',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001e9',
    currency_name: 'Counos Coin',
    currency_symbol: 'CCA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0775c81a273b355e6a5b76e240bf708701f00279',
    currency_name: 'Bulleon',
    currency_symbol: 'BUL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd8950fdeaa10304b7a7fd03a2fc66bc39f3c711a',
    currency_name: 'wys Token',
    currency_symbol: 'WYS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x15bda08c3afbf5955d6e9b235fd55a1fd0dbc829',
    currency_name: 'Alpha Coin',
    currency_symbol: 'APC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xf08c68bd5f4194d994fd70726746bf529ee5a617',
    currency_name: 'ThoreNext',
    currency_symbol: 'THX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000029c',
    currency_name: 'SelfSell',
    currency_symbol: 'SSC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800001eb',
    currency_name: 'Minter Network',
    currency_symbol: 'BIP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id:
      'sygna:0x80000094.GCBAQPS2KAF2CHAAAWZSO6XWKMEMU6Q2MCJ47AOKJOMFTY5BSZ3D5GRK',
    currency_name: 'Dollar International',
    currency_symbol: 'DOLLAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x245ef47d4d0505ecf3ac463f4d81f41ade8f1fd1',
    currency_name: 'Nuggets',
    currency_symbol: 'NUG',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800008fd.8b9500e2b789e002c1d0e744bd0ac7aa60dbffcc',
    currency_name: 'CFun',
    currency_symbol: 'CFUN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x412d397ddca07d753e3e0c61e367fb1b474b3e7d',
    currency_name: '8X8 PROTOCOL',
    currency_symbol: 'EXE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x05984006707585f66465e8a6505341f46b64fa7a',
    currency_name: 'NAM COIN',
    currency_symbol: 'NAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x2494a68C1484376fEf880b4c24D91f049d29B02A',
    currency_name: 'The Transfer Token',
    currency_symbol: 'TTT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x34364BEe11607b1963d66BCA665FDE93fCA666a8',
    currency_name: 'YOU COIN',
    currency_symbol: 'YOU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x98b2de885e916b598f65ded2fdbb63187eaef184',
    currency_name: 'Defi',
    currency_symbol: 'DEFI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x17b26400621695c2d8c2d8869f6259e82d7544c4',
    currency_name: 'CustomContractNetwork',
    currency_symbol: 'CCN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000085',
    currency_name: 'Zcash',
    currency_symbol: 'ZEC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x13119e34e140097a507b07a5564bde1bc375d9e6',
    currency_name: 'Moneytoken',
    currency_symbol: 'IMT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x056Fd409E1d7A124BD7017459dFEa2F387b6d5Cd',
    currency_name: 'Gemini Dollar',
    currency_symbol: 'GUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xb8e2e2101ed11e9138803cd3e06e16dd19910647',
    currency_name: 'ArdCoin',
    currency_symbol: 'ARDX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xd6e1401a079922469e9b965cb090ea6ff64c6839',
    currency_name: 'HOLD',
    currency_symbol: 'HOLD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c',
    currency_name: 'Bancor',
    currency_symbol: 'BNT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xAa0bb10CEc1fa372eb3Abc17C933FC6ba863DD9E',
    currency_name: 'Hi Mutual Society',
    currency_symbol: 'HMC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1763ad73694d4d64fb71732b068e32ac72a345b1',
    currency_name: 'BeeEx',
    currency_symbol: 'BEE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000004a',
    currency_name: 'ICON',
    currency_symbol: 'ICX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc175E77b04F2341517334Ea3Ed0b198A01A97383',
    currency_name: '3X Long TRX Token',
    currency_symbol: 'TRXBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000055',
    currency_name: 'Auroracoin',
    currency_symbol: 'AUR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xc38f1fb49acdf2f1213caf3319f6eb3ea2cb7527',
    currency_name: 'Bitcoinus',
    currency_symbol: 'BITS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0c6144c16af288948c8fdb37fd8fec94bff3d1d9',
    currency_name: 'Neutral Dollar ',
    currency_symbol: 'NUSD',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdf1338fbafe7af1789151627b886781ba556ef9a',
    currency_name: 'Kuende',
    currency_symbol: 'KUE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000078',
    currency_name: 'Flash',
    currency_symbol: 'FLASH',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.316b64de7ac3de00e1d59f2c209a31c3bb131cbd',
    currency_name: 'AK12',
    currency_symbol: 'AK12',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4290563c2d7c255b5eec87f2d3bd10389f991d68',
    currency_name: 'UnlimitedIP',
    currency_symbol: 'UIP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x595832f8fc6bf59c85c527fec3740a1b7a361269',
    currency_name: 'Power Ledger',
    currency_symbol: 'POWR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xcec38306558a31cdbb2a9d6285947c5b44a24f3e',
    currency_name: 'Fantasy Sports',
    currency_symbol: 'DFS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1c79ab32c66acaa1e9e81952b8aaa581b43e54e7',
    currency_name: 'TEAM (TokenStars)',
    currency_symbol: 'TEAM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x0371a82e4a9d0a4312f3ee2ac9c6958512891372',
    currency_name: 'bitJob',
    currency_symbol: 'STU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000000a',
    currency_name: 'BlackCoin',
    currency_symbol: 'BLK',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x1830dD31220A7f76889EAfcc5758eE521ac382b2',
    currency_name: 'AMATEN',
    currency_symbol: 'AMA',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x05d412ce18f24040bb3fa45cf2c69e506586d8e8',
    currency_name: 'Mainstream For The Underground',
    currency_symbol: 'MFTU',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x46b9ad944d1059450da1163511069c718f699d31',
    currency_name: 'Credits',
    currency_symbol: 'CS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x013a06558f07d9e6f9a00c95a33f3a0e0255176b',
    currency_name: 'Bali Coin',
    currency_symbol: 'BALI',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000134.SILVER',
    currency_name: 'bitSilver',
    currency_symbol: 'BITSILVER',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa7fc5d2453e3f68af0cc1b78bcfee94a1b293650',
    currency_name: 'Spiking',
    currency_symbol: 'SPIKE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x80000378.0d821bd7b6d53f5c2b40e217c6defc8bbe896cf5',
    currency_name: 'QLC Chain',
    currency_symbol: 'QLC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8fB00FDeBb4E83f2C58b3bcD6732AC1B6A7b7221',
    currency_name: 'Orion Protocol',
    currency_symbol: 'ORN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x800000c3.TTcrrfqhaMs5Y3pE7jnSk8j8g3J3t52GVC',
    currency_name: 'CBCCOIN',
    currency_symbol: 'CBC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6e13A9e4AE3d0678E511Fb6d2ad531fcF0e247bf',
    currency_name: '3x Long Bitcoin SV Token',
    currency_symbol: 'BSVBULL',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x10c71515602429c19d53011ea7040b87a4894838',
    currency_name: 'Diamond Platform Token',
    currency_symbol: 'DPT',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4c327471c44b2dacd6e90525f9d629bd2e4f662c',
    currency_name: 'GHOST',
    currency_symbol: 'GHOST',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xbdec45952b5e234eddc2981b43eed360826d5087',
    currency_name: 'Mogu',
    currency_symbol: 'MOGX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x5C84bc60a796534bfeC3439Af0E6dB616A966335',
    currency_name: 'Bone',
    currency_symbol: 'BONE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000011b',
    currency_name: 'Algorand',
    currency_symbol: 'ALGO',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6020da0f7c1857dbe4431ec92a15cc318d933eaa',
    currency_name: 'En-Tan-Mo',
    currency_symbol: 'ETM',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6226caa1857afbc6dfb6ca66071eb241228031a1',
    currency_name: 'LinkArt',
    currency_symbol: 'LAR',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x8ce9137d39326ad0cd6491fb5cc0cba0e089b6a9',
    currency_name: 'Swipe',
    currency_symbol: 'SXP',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xdd974d5c2e2928dea5f71b9825b8b646686bd200',
    currency_name: 'Kyber Network',
    currency_symbol: 'KNC',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x6dd4e4aad29a40edd6a409b9c1625186c9855b4d',
    currency_name: 'Parkgene',
    currency_symbol: 'GENE',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x4a527d8fc13c5203ab24ba0944f4cb14658d1db6',
    currency_name: 'Morpheus Labs',
    currency_symbol: 'MITX',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xfd25676fc2c4421778b18ec7ab86e7c5701df187',
    currency_name: 'Alchemy',
    currency_symbol: 'ACOIN',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xe7e4279b80d319ede2889855135a22021baf0907',
    currency_name: 'ZeusNetwork',
    currency_symbol: 'ZEUS',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0x239B0Fa917d85c21cf6435464C2c6aa3D45f6720',
    currency_name: 'Amun Ether 3x Daily Long',
    currency_symbol: 'ETH3L',
    is_active: true,
    addr_extra_info: [],
  },
  {
    currency_id: 'sygna:0x8000003c.0xa66Daa57432024023DB65477BA87D4E7F5f95213',
    currency_name: 'Huobi Pool Token',
    currency_symbol: 'HPT',
    is_active: true,
    addr_extra_info: [],
  },
];
